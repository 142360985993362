import React, {useState, useEffect, useContext} from "react";
import Paper from '@mui/material/Paper';
import ProductPagesTabs from './ProductPagesTabs';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Tablejs from './Table';
import './ProductPage.css';
import AddFunnel from './AddFunnel';
import NavbarProductPage from './NavbarProductPage';
import {UserTypeContext} from "../Context";
import Button from "@mui/material/Button";
import {FunnelContext} from "../Context";




    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(0),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));


export default function AcccessibleTable() {
  const {userType} = useContext(UserTypeContext);

  const { selectedFunnel } = useContext(FunnelContext);


  
const [showDrawer, setShowDrawer] = useState(true)


  return (
      <div>

  <NavbarProductPage/>

  
  {userType == "Media Buyer" || userType == "Accounting" || userType == "Administrative" ? 

    <>
    <div className="productPageTabsDrawerHideBtnContainer">
     <Button className="productPageTabsDrawerHideBtn" variant="contained" onClick={() => {showDrawer == true ? setShowDrawer(false) : setShowDrawer(true)}}>{showDrawer == true ? "Hide Funnels" : "Show Funnels"}</Button>
    </div>

        <AddFunnel/>
        <table>
          <tbody>
            <tr>
              {showDrawer == true ? 
              <td className="productPageTableSpacingTabs"><Item className='TabsSection'><ProductPagesTabs/></Item></td>
              :<></>}
              {selectedFunnel != 'a' ? 
              <td className="productPageTableSpacingTable"><Item className="productPageTable"><Tablejs  style={{"overflow-y": "auto"}}/></Item></td>
              :<td className="productPageNoFunnelSelectedContainer"><h3 className="productPageNoFunnelSelectedText">Please Select a Funnel</h3></td>}
            </tr>
          </tbody>
        </table>
          
        


          

    </>
   : <><br/><center>Your account type is {userType} so you are not permitted to access this page. Please contact an administrator if you need administrative priviledges.</center></>}

    </div>
  );
}
