import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { doc, onSnapshot, collection, query, where, getDoc } from "firebase/firestore";
import {db} from './../FirebaseInit';
const axios = require('axios').default;



function SubsweetFunnel({subsweetFunnel, setSubsweetFunnel, existingSubsweetFunnel}) {

  //console.log( existingSubsweetFunnel)
  //Subsweet Funnel Dropdown
  const [value, setValue] = useState(existingSubsweetFunnel);
  const handleMediaBuyerChange = (event) => {
    setValue(event.target.value);
    setSubsweetFunnel(event.target.value);
    console.log(event.target.value)
    };



const [subsweetFunnelList, setSubsweetFunnelList] = useState([{}]);
 

useEffect(() => {
  getSubsweetFunnels();
  setSubsweetFunnel(existingSubsweetFunnel)
}, [])

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function(txt){
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

async function getSubsweetFunnels() {


  const subsweetFunnelsListArr = [];


      var key = {"creator": "6344233ed9c63e00139456a8"}

      var subsweetFunnels = await axios.post('https://platform.subsweet.com/api/v1/getfunnels', key)

      //console.log(subsweetFunnels.data.data.getfunnels[0].funnel_name)

      for (let i = 0; i < (subsweetFunnels.data.data.getfunnels).length; i++) {
        subsweetFunnelsListArr.push({subsweetFunnelName: toTitleCase((subsweetFunnels.data.data.getfunnels[i].funnel_name).replace(/-|_/g, " ")), subsweetFunnelID: subsweetFunnels.data.data.getfunnels[i]._id})
        setSubsweetFunnelList(subsweetFunnelsListArr)
      }
      //console.log(subsweetFunnelsListArr) 
    }


    return ( 
        <>
      <FormControl sx={{ minWidth: "100%" }} size="small">
      <InputLabel id="demo-select-small">Subsweet Funnel</InputLabel>
      <Select labelId="demo-select-small" id="demo-select-small" value={value} label="Subsweet Funnel" onChange={handleMediaBuyerChange}>
      <MenuItem value='noSelection'><b>No Selection</b></MenuItem>
        {subsweetFunnelList.map((x) => 
        <MenuItem key={x.subsweetFunnelID} value={x.subsweetFunnelID}>{x.subsweetFunnelName}</MenuItem>    
        )}
      </Select>
    </FormControl>
        </>
     )
}

export default SubsweetFunnel;