
import React, {useState, useEffect, useContext} from "react";
import Paper from '@mui/material/Paper';
import Navbar from './Navbar';
import { db } from './FirebaseInit';
import {doc, collection, getDocs, getDoc, query, updateDoc, orderBy, where, Timestamp, setDoc} from "firebase/firestore";
import './UserPermissions.css';
import LinearProgress from '@mui/material/LinearProgress';
import { Box } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {UserTypeContext} from "../Context";
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Switch from '@mui/material/Switch';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { getAuth, onAuthStateChanged, updateProfile, updateEmail, sendPasswordResetEmail, signOut } from "firebase/auth";
import { fontSize } from "@mui/system";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const axios = require('axios').default;


  
function UserPermissions() {
  const {userType} = useContext(UserTypeContext);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [editModalData, setEditModalData] = useState({});
  const [selectedEmployeeRole, setSelectedEmployeeRole] = useState('');

  const [onCommission, setOnCommission] = useState(false);
  const [commissionShareLosses, setCommissionShareLosses] = useState(false);
  const [commissionOnOrders, setCommissionOnOrders] = useState(0);
  const [commissionOnProfit, setCommissionOnProfit] = useState(0);

  const [users, setUsers] = useState([])


  async function getEmployees() {
    var usersArray = [];

    const q = query(collection(db, "employees"), where('status', '!=', 'removed'), orderBy("status"), orderBy("employeeRole"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      //console.log(doc.data())
      usersArray.push(doc.data())
    });

    setUsers(usersArray)
  }

    useEffect(() => {
      getEmployees();
    }, [])

    function signOutUser() {
      const auth = getAuth();
      signOut(auth).then(() => {
        // Sign-out successful.
      }).catch((error) => {
        // An error happened.
      });
    }

    async function saveUpdatedUserData() {
      if (selectedEmployeeRole !== 'Media Buyer' || onCommission == false) {
        setOnCommission(false);
        setCommissionShareLosses(false);
        setCommissionOnOrders(0);
        setCommissionOnProfit(0);
      }
      
      const employeeRef = doc(db, "employees", editModalData.id.toString());
      await updateDoc(employeeRef, {
        employeeRole: selectedEmployeeRole,
        onCommission: onCommission,
        commissionShareLosses: commissionShareLosses,
        commissionOnOrders: commissionOnOrders,
        commissionOnProfit: commissionOnProfit / 100,
      });

      setOpenEditModal(false) 
      getEmployees();
    }



    async function removeEmployee() {

      const employeeRef = doc(db, "employees", editModalData.id.toString());
      await updateDoc(employeeRef, {
        status: 'removed',
      });

      setOpenDeleteModal(false) 
      getEmployees();
    }



    const [addEmployeeName, setAddEmployeeName] = useState('');
    const [addEmployeeRole, setAddEmployeeRole] = useState('');

    async function submitNewEmployee () {
        const id = Date.now();

        const newEmployeeData = {
            employeeName: addEmployeeName,
            employeeRole: addEmployeeRole,
            createdAt: Timestamp.fromDate(new Date()),
            id: id,
            status: "notActivated",
            onCommission: false,
            commissionShareLosses: false,
            commissionOnOrders: 0,
            commissionOnProfit: 0,
            avatarColor: (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0'),
            viewSettings: {
              viewSettings: {
                AOVColumn: true,
                COGSColumn: true,
                CPAColumn: true,
                CPCColumn: true,
                CPMColumn: true,
                CTRColumn: true,
                CVRColumn: true,
                ROASColumn: true,
                adspendColumn: true,
                clicksColumn: true,
                dateColumn: true,
                feesColumn: true,
                impressionsColumn: true,
                orderColumn: true,
                profitColumn: true,
                revenueColumn: true,
                subscribersColumn: true,
              }
            }
        };
        setOpenNewEmployeeDialog(false)
  

        

        // Add a new document with a generated id.
        await setDoc(doc(db, "employees/" + id), newEmployeeData);
        setName('');
        getEmployees();

    };

        //NEW Employee Dialog
        const [openNewEmployeeDialog, setOpenNewEmployeeDialog] = useState(false);
      
        const handleCloseNewEmployee = (event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenNewEmployeeDialog(false);
            setName('');
          }
        };
    
    
        //NEW Employee Dropdown
        const [name, setName] = React.useState('');
      
        const handleChangeNewEmployeeRole = (event) => {
          setName(event.target.value);
          setAddEmployeeRole(event.target.value);
        };


  return (
    <>
    <div className="userSettingsMainContainer">

      <div className="dashboardDrawer">
        <div className="dashboardDrawerTitleContainer">
            <span className="dashboardDrawerTitle">Sendwell Stats</span>
        </div>

        <div>
            <div className="dashboardDrawerSectionTitle">
              <span>General</span>
            </div>
            <div className="dashboardDrawerSection">
              <a href="/Dashboard" className="dashboardDrawerLinkTitle">Dashboard</a>
            </div>
            <div className="dashboardDrawerSection">
              <a href="/ProductIdeas" className="dashboardDrawerLinkTitle">Product Ideas</a>
            </div>

            <div className="dashboardDrawerSectionTitle">
              <span>Finances</span>
            </div>
            <div className="dashboardDrawerSection">
              <a href="/Accounting" className="dashboardDrawerLinkTitle">Accounting</a>
            </div>
            <div className="dashboardDrawerSection">
              <a href="/AccountBalances" className="dashboardDrawerLinkTitle">Account Balances</a>
            </div>
            <div className="dashboardDrawerSection">
              <a href="/Analytics" className="dashboardDrawerLinkTitle">Analytics</a>
            </div>
            <div className="dashboardDrawerSection">
              <a href="/Commission" className="dashboardDrawerLinkTitle">Commission</a>
            </div>

            <div className="dashboardDrawerSectionTitle">
                <span>Management</span>
            </div>
            <div className="dashboardDrawerSection">
                <a href="/UserSettings" className="dashboardDrawerLinkTitle">User Settings</a>
            </div>
            <div className="activeDashboardDrawerSection">
                <a href="/UserPermissions" className="activeDashboardDrawerLinkTitle">User Permissions</a>
            </div>
            <div className="dashboardDrawerSection">
                <a href="/BusinessSettings" className="dashboardDrawerLinkTitle">Business Settings</a>
            </div>

            <div className="dashboardDrawerSectionTitle">
                <span></span>
            </div>
            <div className="dashboardDrawerSectionSignOut" onClick={() => signOutUser()}>
                <span className="dashboardDrawerLinkTitleSignOut">Sign Out</span>
            </div>
        </div>

      </div>




      <div className="dashboardBackground ">

        <div className="userPriveledgesCardContainer">

        {users.length != 0 ? <>

        {userType == "Administrative" ? <>

          <table className="tableWidth">
            <tbody>
              <tr>
                <td className="userPermissionsTableHeader"></td>
                <td className="userPermissionsTableHeader">Name</td>
                <td className="userPermissionsTableHeader">Employee Role</td>
                <td className="userPermissionsTableHeader">Commission On Orders</td>
                <td className="userPermissionsTableHeader">Commission On Profit</td>
                <td className="userPermissionsTableHeader">Share Losses</td>
                <td className="userPermissionsTableHeader"></td>
                <td className="userPermissionsTableHeader"></td>
              </tr>
              {users.map((a) => { 
                return (
                  <tr key={a.id}>
                    <td><Avatar sx={{ bgcolor: '#' + a.avatarColor, width: 40, height: 40, fontSize: 23}}>{a.employeeName[0]}</Avatar></td>
                    <td>{a.employeeName}</td>
                    <td>{a.employeeRole}</td>
                    <td>{a.commissionOnOrders != undefined ? new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.commissionOnOrders) : ""}</td>
                    <td>{a.commissionOnProfit != undefined ? new Intl.NumberFormat('en-US', {style: 'percent'}).format(a.commissionOnProfit) : ""}</td>
                    <td>{a.commissionShareLosses == true ? "Yes" : a.commissionShareLosses == false ? "No" : ""}</td>
                    <td>
                      <Button variant="outlined" onClick={() => {
                        setOpenEditModal(true); 
                        setEditModalData(a); 
                        setOnCommission(a.onCommission); 
                        setCommissionShareLosses(a.commissionShareLosses); 
                        setCommissionOnOrders(a.commissionOnOrders);
                        setCommissionOnProfit(a.commissionOnProfit * 100); 
                        setSelectedEmployeeRole(a.employeeRole)
                      }}>
                        Edit
                      </Button>
                      </td>
                    <td><Button variant="outlined" color="error" onClick={() => {setOpenDeleteModal(true); setEditModalData(a);}}>Remove</Button></td>
                  </tr>
                )
              })}

            </tbody>
          </table>
          </>:<>
              <center>You do not have permission to adjust User Permissions. Please contact an administrator.</center><br/>
          </>}

          </>:<>
            <div className="userPermissionsLoadingContainer">
              <CircularProgress/><br/><br/>
              <span>Loading Users</span>
            </div>
          </>}
      
        </div>
        <Fab onClick={() => setOpenNewEmployeeDialog(true)} sx={{position: 'fixed', bottom: 16, right: 16}} style={{backgroundColor: "#00ab55", color: "white"}} variant="extended">
          <AddIcon sx={{ mr: 1 }} />
          New Employee
        </Fab>
      </div>
    </div>



    <Dialog open={openEditModal} onClose={() => setOpenEditModal(false)} maxWidth='lg'>
      <DialogTitle>{editModalData.employeeName}</DialogTitle>
        <DialogContent>

          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small">Role</InputLabel>
          <Select value={selectedEmployeeRole} label="Role" onChange={(event) => setSelectedEmployeeRole(event.target.value)}>
            <MenuItem value='Administrative'>Administrative</MenuItem>
            <MenuItem value='Accounting'>Accounting</MenuItem>
            <MenuItem value='Media Buyer'>Media Buyer</MenuItem>
            <MenuItem value='Funnel Creation'>Funnel Creation</MenuItem>
            <MenuItem value='Customer Service'>Customer Service</MenuItem>
            <MenuItem value='Other'>Other</MenuItem>
          </Select>
          </FormControl>


          {selectedEmployeeRole == "Media Buyer" ?
          <table className="userSettingsTable">
            <tbody>
              <tr>
                <td>
                  <FormControlLabel control={ <Switch onChange={(event) => setOnCommission(event.target.checked)} checked={onCommission}/> } label="On Commission"/>
                </td>
                <td>
                  <FormControlLabel control={ <Switch onChange={(event) => setCommissionShareLosses(event.target.checked)} checked={commissionShareLosses}/> } label="Share Losses"/>
                </td>
                <td>
                  <TextField fullWidth onChange={(event) => setCommissionOnOrders(event.target.value)} value={commissionOnOrders} label="Commission Per Order" variant="outlined" InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}/><br/>
                </td>
                <td>
                  <TextField fullWidth onChange={(event) => setCommissionOnProfit(event.target.value)} value={commissionOnProfit} label="Commission On Total Profit" variant="outlined" InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}/><br/>
                </td>
              </tr>
            </tbody>
          </table>

          :<></>}

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditModal(false)}>Cancel</Button>
          <Button onClick={() => saveUpdatedUserData()}>Update Permissions</Button>
        </DialogActions>
      </Dialog>





      <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} maxWidth='lg'>
      <DialogTitle>{editModalData.employeeName}</DialogTitle>
        <DialogContent>
              Are you sure you want to remove {editModalData.employeeName} from Sendwell Stats?

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
          <Button color='error' onClick={() => removeEmployee()}>Remove Employee</Button>
        </DialogActions>
      </Dialog>




      <Dialog disableEscapeKeyDown open={openNewEmployeeDialog} onClose={handleCloseNewEmployee}>
        <DialogTitle>New Employee</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
            <TextField onChange={event => setAddEmployeeName(event.target.value)} id="outlined-basic" label="Employee Name" variant="outlined" />

            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-dialog-select-label">Role</InputLabel>
              <Select labelId="demo-dialog-select-label" id="demo-dialog-select" value={name} onChange={handleChangeNewEmployeeRole} input={<OutlinedInput label="Role" />}>
                <MenuItem value={"Customer Service"}>Customer Service</MenuItem>
                <MenuItem value={"Funnel Creator"}>Funnel Creation</MenuItem>
                <MenuItem value={"Media Buyer"}>Media Buying</MenuItem>
                <MenuItem value={"Accounting"}>Accounting</MenuItem>
                <MenuItem value={"Administrative"}>Administrative</MenuItem>
                <MenuItem value={"Other"}>Other</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewEmployee}>Discard</Button>
          <Button onClick={submitNewEmployee}>Save Employee</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}



export default UserPermissions;
