import React, {useState, setState, state, useEffect, useContext} from 'react';
import {doc, onSnapshot, collection, getDocs, query, getDoc, where} from "firebase/firestore";
import {db} from '../FirebaseInit';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import Chart from 'chart.js/auto';
import './ProfitChart.css';
import shoppingCart from '../../images/shopping-cart.png';
import { Link } from 'react-router-dom';
import {UserTypeContext} from "../../Context";


function ProfitChart(props) {
  const {userType} = useContext(UserTypeContext);
   
  const firestoreProfitDataArray = [];
  const firestoreDateDataArray = [];
  const [profit, setProfit] = useState();
  const [dates, setDates] = useState();

  const [totalProfit, setTotalProfit] = useState();

  useEffect(() => {
    if (userType == "Media Buyer") {
      getMediaBuyerDailyTotals();
    } else if ((userType == "Administrative" || userType == "Accounting") && props.mediaBuyer == 'all'){
      getDailyTotals(); 
    } else if ((userType == "Administrative" || userType == "Accounting") && props.mediaBuyer != 'all'){
      adminGetMediaBuyerDailyTotals(); 
    }
  }, [props])

  async function adminGetMediaBuyerDailyTotals () {
    const docRef = doc(db, "users/" + props.uidState);
    const docSnap = await getDoc(docRef);
    const DailyTotalRef = query(collection(db, "mediaBuyerDailyData/mediaBuyers/" + props.mediaBuyer), where("date", ">=", props.beginningDate), where("date", "<=", props.endingDate))
    const DailyTotals = await getDocs(DailyTotalRef);
    DailyTotals.forEach((docs) => {
      firestoreProfitDataArray.push(docs.data().total);
      firestoreDateDataArray.push(docs.id);
    })//END OF FOR EACH 
    setProfit(firestoreProfitDataArray); 
    setDates(firestoreDateDataArray);
    setTotalProfit(firestoreProfitDataArray.reduce((a, b) => a + b, 0).toFixed(2));
    props.setProfit(firestoreProfitDataArray.reduce((a, b) => a + b, 0).toFixed(2));
  }// END OF ASYNC GET DAILY TOTALS FUNCTION


  async function getDailyTotals () {
    const DailyTotalRef = query(collection(db, "totalDailyData/"), where("date", ">=", props.beginningDate), where("date", "<=", props.endingDate))
    const DailyTotals = await getDocs(DailyTotalRef);
    DailyTotals.forEach((docs) => {
      firestoreProfitDataArray.push(docs.data().total);
      firestoreDateDataArray.push(docs.id);
    })//END OF FOR EACH 
    setProfit(firestoreProfitDataArray); 
    setDates(firestoreDateDataArray);
    setTotalProfit(firestoreProfitDataArray.reduce((a, b) => a + b, 0).toFixed(2));
    props.setProfit(firestoreProfitDataArray.reduce((a, b) => a + b, 0).toFixed(2));
  }// END OF ASYNC GET DAILY TOTALS FUNCTION


  async function getMediaBuyerDailyTotals () {
    const docRef = doc(db, "users/" + props.uidState);
    const docSnap = await getDoc(docRef);
    const DailyTotalRef = query(collection(db, "mediaBuyerDailyData/mediaBuyers/" + docSnap.data().employeeID), where("date", ">=", props.beginningDate), where("date", "<=", props.endingDate))
    const DailyTotals = await getDocs(DailyTotalRef);
    DailyTotals.forEach((docs) => {
      firestoreProfitDataArray.push(docs.data().total);
      firestoreDateDataArray.push(docs.id);
    })//END OF FOR EACH 
    setProfit(firestoreProfitDataArray); 
    setDates(firestoreDateDataArray);
    setTotalProfit(firestoreProfitDataArray.reduce((a, b) => a + b, 0).toFixed(2));
    props.setProfit(firestoreProfitDataArray.reduce((a, b) => a + b, 0).toFixed(2));
  }// END OF ASYNC GET DAILY TOTALS FUNCTION

  return (
    <div className="profitCard"> <br/>


    <table>
      <tbody>
        <tr>
          <td>
            <span className='profitCardTitle'> PROFIT</span><br/>
            <span className='profitCardQuantity'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(totalProfit)}</span>
          </td>
          <td>
            <div className='chartContainer'>
            <Line width={"150px"} height={"70px"}
                  data={{
      labels: dates,
      datasets: [
        {
          data: profit,
          backgroundColor: '#ffffff',
          borderColor: '#ffffff',
        },
      ],
                  }}
                  options={{
      events: [],
      
      plugins: {
        legend: {
            display: false
        },
      },

      scales: {
        x: {
          display: false,
          grid: {
            display: false
          }
        },
        y: {
          beginAtZero: false,
          display: false,
          grid: {
            display: false
          }
        },
      },

      elements: {
        point:{
            radius: 0
        }
    }

                  }}
                />
            </div>
          </td>
        </tr>
      </tbody>
    </table>



    <br/>


  </div>

  )
}
export default ProfitChart;
