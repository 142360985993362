import React, {useState, useEffect} from 'react';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import './ProductPagesTabs.css';
import {db} from './FirebaseInit';
import {doc, collection} from "firebase/firestore";
import {addDoc, Timestamp, updateDoc } from "firebase/firestore"; 
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FunnelName from './AddFunnelComponents/FunnelName';
import FunnelDomain from './AddFunnelComponents/FunnelDomain'
import FunnelTrafficSource from './AddFunnelComponents/FunnelTrafficSource';
import FunnelFaceBookBM from './AddFunnelComponents/FunnelFaceBookBM'
import FunnelFaceBookAdAccount from './AddFunnelComponents/FunnelFaceBookAdAccount';
import FunnelStatusSlider from './AddFunnelComponents/FunnelStatusSlider';
import FunnelMediaBuyer from './AddFunnelComponents/FunnelMediaBuyer';
import FunnelFees from './AddFunnelComponents/FunnelFees';
import FunnelCampaignSelector from './AddFunnelComponents/FunnelCampaignSelector';
import SubsweetFunnel from './AddFunnelComponents/SubsweetFunnel';
import SubsweetSubID from './AddFunnelComponents/SubsweetSubID';
import FunnelTikTokBM from "./AddFunnelComponents/FunnelTikTokBM";
import FunnelTikTokAdAccount from "./AddFunnelComponents/FunnelTikTokAdAccount";
import FunnelTikTokCampaignSelector from "./AddFunnelComponents/FunnelTikTokCampaignSelector";


function AddFunnel() {
    const [addFunnelName, setAddFunnelName] = useState('');
    const [addFunnelDomain, setAddFunnelDomain] = useState('');
    const [addStatus, setAddStatus] = useState('');
    const [addTrafficSource, setAddTrafficSource] = useState('');
    const [addFaceBookBusinessManager, setAddFaceBookBusinessManager] = useState('');
    const [linkFacebookAdAccount, setLinkFacebookAdAccount] = useState('');
    const [linkFacebookCampaign, setLinkFacebookCampaign] = useState([]);
    const [addFaceBookPage, setAddFaceBookPage] = useState('');
    const [addMediaBuyer, setAddMediaBuyer] = useState('');
    const [addCashback, setAddCashback] = useState('');
    const [addMerchantFees, setAddMerchantFees] = useState('');
    const [addMerchantFeesPerOrder, setAddMerchantFeesPerOrder] = useState('');
    const [subsweetFunnel, setSubsweetFunnel] = useState('noSelection');
    const [subsweetSubID, setSubsweetSubID] = useState('');
    const [addTikTokBusinessManager, setAddTikTokBusinessManager] = useState('');
    const [linkTikTokAdAccount, setLinkTikTokAdAccount] = useState('');
    const [linkTikTokCampaign, setLinkTikTokCampaign] = useState([]);


  //declaring state for New Funnel popup 
  const [open, setOpen] = React.useState(false);
 
  //closes modal if all important fields are filled in 
   const handleCloseAndSave = () => {
    if(addFunnelName != undefined && addFunnelDomain != undefined && addTrafficSource != undefined && addMediaBuyer != undefined) {
      if (addStatus == undefined) {setAddStatus(51)}
      if (linkFacebookAdAccount == undefined) {setLinkFacebookAdAccount('')}
      if (addFaceBookBusinessManager == undefined) {setAddFaceBookBusinessManager('')}
      if (addFaceBookPage == undefined) {setAddFaceBookPage('')}
      if (subsweetSubID == undefined) {setSubsweetSubID('')}
      if (linkFacebookCampaign == undefined) {setLinkFacebookCampaign([{id: "", name: ""}])}
      submitFunnelData();
      setOpen(false);
      setAddFunnelName('');
      setAddFunnelDomain('');
      setAddTrafficSource('');
      setAddMediaBuyer('');
    }
  };
   
  //creates object and uploads it to database
  async function submitFunnelData () {

    const funnelData = {
        funnelName: addFunnelName,
        domain: addFunnelDomain,
        status: addStatus,
        trafficSource: addTrafficSource,
        adAccount: linkFacebookAdAccount == undefined ? '' : linkFacebookAdAccount,
        businessManager: addFaceBookBusinessManager == undefined ? '' : addFaceBookBusinessManager,
        facebookPage: addFaceBookPage,
        linkedFacebookCampaign: linkFacebookCampaign,
        mediaBuyer: addMediaBuyer.toString(),
        createdAt: Timestamp.fromDate(new Date()),
        lastSaved: Timestamp.fromDate(new Date()),
        active: true,
        funnelID: "",
        subsweetFunnel: subsweetFunnel,
        subsweetSubID: subsweetSubID,
        cashbackOnAds: addCashback,
        merchantFees: addMerchantFees,
        merchantFeesPerOrder: parseFloat(addMerchantFeesPerOrder),
        funnelNotes: "",
        addTikTokBusinessManager: addTikTokBusinessManager,
        linkTikTokAdAccount: linkTikTokAdAccount, 
        linkTikTokCampaign: linkTikTokCampaign,
    };

    const docRef = await addDoc(collection(db, "funnels"), funnelData);
    console.log("Document written with ID: ", docRef.id);

    await updateDoc(doc(db, "funnels", docRef.id, ), {funnelID: docRef.id}); 
}


  return (
   <>
    <Fab onClick={() => {setOpen(true)}} variant="extended" style={{backgroundColor: "#00ab55", color: "white"}} sx={{position: 'fixed', bottom: 16, right: 16}} aria-label="add">
      <AddIcon sx={{ mr: 1 }}  />
      New Funnel      
    </Fab>


    <div>
      <Dialog open={open} onClose={() => {setOpen(false)}}>
        <DialogTitle>New Funnel</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a new funnel please add all of the information and click Add Funnel.
          </DialogContentText>
          
          <FunnelName setAddFunnelName={setAddFunnelName}/>
          
          <FunnelDomain setAddFunnelDomain={setAddFunnelDomain}/><br/><br/>

          <FunnelStatusSlider setAddStatus={setAddStatus}/>

          <FunnelTrafficSource addTrafficSource={addTrafficSource} setAddTrafficSource={setAddTrafficSource}/><br/><br/>

          <FunnelMediaBuyer addMediaBuyer={addMediaBuyer} setAddMediaBuyer={setAddMediaBuyer}/><br/><br/>

          <SubsweetFunnel subsweetFunnel={subsweetFunnel} setSubsweetFunnel={setSubsweetFunnel}/><br/><br/>

          <SubsweetSubID setSubsweetSubID={setSubsweetSubID} subsweetSubID={subsweetSubID} existingSubsweetSubID={''}/><br/><br/>


          
         

          {addTrafficSource == 1655921921514 ? <>
            <FunnelFaceBookBM addFaceBookBusinessManager={addFaceBookBusinessManager} setAddFaceBookBusinessManager={setAddFaceBookBusinessManager}/><br/><br/>

            {addFaceBookBusinessManager == undefined ? <></> : <><FunnelFaceBookAdAccount linkFacebookAdAccount={linkFacebookAdAccount} setLinkFacebookAdAccount={setLinkFacebookAdAccount} addFaceBookBusinessManager={addFaceBookBusinessManager}/><br/><br/></>}

            {linkFacebookAdAccount == undefined ? <></> : <><FunnelCampaignSelector linkFacebookCampaign={linkFacebookCampaign} setLinkFacebookCampaign={setLinkFacebookCampaign} addFaceBookBusinessManager={addFaceBookBusinessManager} linkFacebookAdAccount={linkFacebookAdAccount}/><br/><br/></>}
         </>
         : addTrafficSource == 1658346558337 ? <>
         <FunnelTikTokBM  addTikTokBusinessManager={addTikTokBusinessManager} setAddTikTokBusinessManager={setAddTikTokBusinessManager}/><br/><br/>
         
         <FunnelTikTokAdAccount linkTikTokAdAccount={linkTikTokAdAccount} setLinkTikTokAdAccount={setLinkTikTokAdAccount} addTikTokBusinessManager={addTikTokBusinessManager} /><br/><br/>

         <FunnelTikTokCampaignSelector linkTikTokCampaign={linkTikTokCampaign} setLinkTikTokCampaign={setLinkTikTokCampaign} addTikTokBusinessManager={addTikTokBusinessManager} linkTikTokAdAccount={linkTikTokAdAccount} />

         </>
         :<></>
        }




          <FunnelFees addCashback={addCashback} setAddCashback={setAddCashback} addMerchantFees={addMerchantFees} setAddMerchantFees={setAddMerchantFees}  existingCashbackOnAds={-.5} existingMerchantFees={-4} addMerchantFeesPerOrder={addMerchantFeesPerOrder} setAddMerchantFeesPerOrder={setAddMerchantFeesPerOrder} existingMerchantFeesPerOrder={0}/>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setOpen(false)}}>Cancel</Button>
          <Button onClick={handleCloseAndSave}>Add Funnel</Button>
        </DialogActions>
      </Dialog>
    </div>
   </>  
  );
}

export default AddFunnel;