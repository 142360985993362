import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { doc, onSnapshot, collection, query, where, getDoc } from "firebase/firestore";
import {db} from './../FirebaseInit';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import { fontWeight } from '@mui/system';
import { Button } from '@mui/material';
import './FunnelCampaignSelector.css'
const axios = require('axios').default;


function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function FunnelTikTokCampaignSelector({linkTikTokCampaign, setLinkTikTokCampaign, addTikTokBusinessManager, linkTikTokAdAccount, existingTikTokCampaign}) {
  const theme = useTheme();

  
  
  var AccessKeyData;

  useEffect(() => {
    if(existingTikTokCampaign != undefined) {
      setLinkTikTokCampaign(existingTikTokCampaign);
    }
  }, [])
  


const [tiktokCampaignList, setTikTokCampaignList] = useState([]);





useEffect(() => {
  getAccessKey();
}, [linkTikTokAdAccount])



async function getAccessKey() {
  const AccessKeyRef = doc(db, "TikTokBMs/" + addTikTokBusinessManager);
  AccessKeyData = await getDoc(AccessKeyRef);
  //var accessKey = AccessKeyData.data().TikTokBMAccessKey; 
  


  const campaignListArr = [];


  const config = {
    method: 'get',
    url: 'https://us-central1-sendwell-profit-tracker.cloudfunctions.net/getTikTokCampaigns?adAccountID=' + linkTikTokAdAccount,
  };

  const response = await axios(config);


console.log(response)

   for(let i = 0; i < await response.data.length; i++) {
    console.log(await response.data[i].campaign_name)

    campaignListArr.push({name: response.data[i].campaign_name, id: response.data[i].campaign_id})
    setTikTokCampaignList(campaignListArr)
  }

  //console.log(campaignListArr)
}



var selectedCampaignsArr = [];
        
        const handleChange = (event) => {
          const {
            target: { value },
          } = event;
          
            setLinkTikTokCampaign(
              // On autofill we get a stringified value.
              typeof value === 'string' ? value.split(',') : value,
            );
            selectedCampaignsArr.push(typeof value === 'string' ? value.split(',') : value)
            console.log(selectedCampaignsArr)
        };
        

    return ( 
        <>

        <table className='funnelCampaignSelectorTable'>
          <tbody className='funnelCampaignSelectorTable'>
            <tr className='funnelCampaignSelectorTable'>
              <td className='funnelCampaignSelectorCampaignDropdown'>
      <div className='funnelCampaignSelectorCampaignDropdown'>
    <FormControl sx={{minWidth: "100%"}}>
        <InputLabel id="demo-multiple-chip-label">Campaigns on Selected Ad Account</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={linkTikTokCampaign}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Campaigns on Selected Ad Account" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              
              {selected.map((value) => (<>
                <Chip key={value.name} label={value.name} />
                </>
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          
          {tiktokCampaignList.map((x) => (
            <MenuItem
              key={x.id}
              value={{id: x.id, name: x.name}}
              style={{backgroundColor: (JSON.stringify(linkTikTokCampaign)).includes(x.id) ? "#f2f2f2" : "white", fontWeight: (JSON.stringify(linkTikTokCampaign)).includes(x.id) ? "600" : "400"}}
            >
              {x.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </div>

            </td><td className='funnelCampaignSelectorSpacer'></td>
              <td>
                
      <Button onClick={() => setLinkTikTokCampaign([])} variant="outlined" size="large">Clear</Button>
              </td>
            </tr>
          </tbody>
        </table>
        </>
     )
}

export default FunnelTikTokCampaignSelector;