
import React, {useState, useEffect, useContext} from "react";
import Paper from '@mui/material/Paper';
import Navbar from './Navbar';
import { db } from './FirebaseInit';
import {doc, collection, getDocs, getDoc, query, updateDoc} from "firebase/firestore";
import './UserSettings.css';
import LinearProgress from '@mui/material/LinearProgress';
import { Box } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {UserTypeContext} from "../Context";
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Switch from '@mui/material/Switch';
import { getAuth, onAuthStateChanged, updateProfile, updateEmail, sendPasswordResetEmail, signOut } from "firebase/auth";
import { fontSize } from "@mui/system";

const axios = require('axios').default;


  
function UserSettings() {
    const [employeeRole, setEmployeeRole] = useState('');
    const [employeeID, setEmployeeID] = useState('');
    const [fullName, setFullName] = useState('');
    const [userStatus, setUserStatus] = useState('');
    const [avatarColor, setAvatarColor] = useState('');
    const [commissionOnOrders, setCommissionOnOrders] = useState('');
    const [commissionOnProfit, setCommissionOnProfit] = useState('');
    const [commissionShareLosses, setCommissionShareLosses] = useState('');
    const [onCommission, setOnCommission] = useState('');
    const [employeeCreatedAt, setEmployeeCreatedAt] = useState('');
    const [userCreatedAt, setUserCreatedAt] = useState('');
    const [emailAddress, setEmailAddress] = useState('')



    const [uidState, setUidState] = useState('');
    const [userData, setUserData] = useState([]);


    function getUserData () {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUidState(user.uid);
                setEmailAddress(user.email)
                findUser(user.uid);
            } 
        });
    }
    
    async function findUser(uid) {
      const usersSnap = await getDoc(doc(db, "users", uid));

      console.log(usersSnap.data())

      setEmployeeRole(usersSnap.data().employeeRole)
      setEmployeeID(usersSnap.data().employeeID)
      setEmployeeCreatedAt(usersSnap.data().createdAt)
      setFullName(usersSnap.data().employeeName)
      setUserStatus(usersSnap.data().status)


      getEmployeeData(usersSnap.data().employeeID)
      setUserData(usersSnap.data())
    }

    async function getEmployeeData(employeeID) {
        console.log(employeeID)
        const employeeSnap = await getDoc(doc(db, "employees", employeeID.toString()));
        console.log('employeeSnap.data()')

        setAvatarColor(employeeSnap.data().avatarColor)
        setCommissionOnOrders(employeeSnap.data().commissionOnOrders)
        setCommissionOnProfit(employeeSnap.data().commissionOnProfit)
        setCommissionShareLosses(employeeSnap.data().commissionShareLosses.toString())
        setOnCommission((employeeSnap.data().onCommission).toString())
        //setUserCreatedAt(employeeSnap.data().createdAt)

    }


    function updateProfile() {
        const auth = getAuth();
        updateEmail(auth.currentUser, emailAddress).then(() => {
          console.log('Email updated!')
          // ...
        }).catch((error) => {
          // An error occurred
          // ...
        });
    }


    function sendResetPasswordEmail() {
        const auth = getAuth();
        sendPasswordResetEmail(auth, emailAddress)
        .then(() => {
            console.log('Password reset email sent!')
            // ..
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ..
        });
    }


    async function updateDatabaseData() {
        const usersRef = doc(db, "users", uidState);
        await updateDoc(usersRef, {
            fullName: fullName
        });

        const employeeRef = doc(db, "employees", employeeID);
        await updateDoc(employeeRef, {
            avatarColor: avatarColor
        });
    }

    console.log(avatarColor)


    useEffect(() => {
        getUserData()
    }, [])


    function signOutUser() {
        const auth = getAuth();
        signOut(auth).then(() => {
          // Sign-out successful.
        }).catch((error) => {
          // An error happened.
        });
      }

  return (
    <>
    <div className="userSettingsMainContainer">

      <div className="dashboardDrawer">
        <div className="dashboardDrawerTitleContainer">
            <span className="dashboardDrawerTitle">Sendwell Stats</span>
        </div>

        <div>
            <div className="dashboardDrawerSectionTitle">
                <span>General</span>
            </div>
            <div className="dashboardDrawerSection">
                <a href="/Dashboard" className="dashboardDrawerLinkTitle">Dashboard</a>
            </div>
            <div className="dashboardDrawerSection">
                <a href="/ProductIdeas" className="dashboardDrawerLinkTitle">Product Ideas</a>
            </div>

            <div className="dashboardDrawerSectionTitle">
                <span>Finances</span>
            </div>
            <div className="dashboardDrawerSection">
                <a href="/Accounting" className="dashboardDrawerLinkTitle">Accounting</a>
            </div>
            <div className="dashboardDrawerSection">
                <a href="/AccountBalances" className="dashboardDrawerLinkTitle">Account Balances</a>
            </div>
            <div className="dashboardDrawerSection">
                <a href="/Analytics" className="dashboardDrawerLinkTitle">Analytics</a>
            </div>
            <div className="dashboardDrawerSection">
                <a href="/Commission" className="dashboardDrawerLinkTitle">Commission</a>
            </div>

            <div className="dashboardDrawerSectionTitle">
                <span>Management</span>
            </div>
            <div className="activeDashboardDrawerSection">
                <a href="/UserSettings" className="activeDashboardDrawerLinkTitle">User Settings</a>
            </div>
            <div className="dashboardDrawerSection">
                <a href="/UserPermissions" className="dashboardDrawerLinkTitle">User Permissions</a>
            </div>
            <div className="dashboardDrawerSection">
                <a href="/BusinessSettings" className="dashboardDrawerLinkTitle">Business Settings</a>
            </div>

            <div className="dashboardDrawerSectionTitle">
                <span></span>
            </div>
            <div className="dashboardDrawerSectionSignOut" onClick={() => signOutUser()}>
                <span className="dashboardDrawerLinkTitleSignOut">Sign Out</span>
            </div>
        </div>

      </div>




      <div className="dashboardBackground ">

        <div className="userSettingsCardContainer">

            <div className="userSettingsAvatarContainer">
                {avatarColor != '' ? 
                    <Avatar sx={{ bgcolor: '#' + avatarColor, width: 150, height: 150, fontSize: 100}}>{fullName[0]}</Avatar>
                :
                    <Avatar sx={{width: 150, height: 150, fontSize: 100}}>{fullName[0]}</Avatar>
                }
            </div>

            <span className="userSettingsFullName">{fullName}</span>
            <span className="userSettingsEmployeeRole">{employeeRole}</span>
            <div className="userSettingsPasswordResetEmailButtonContainer">
                <Button onClick={() => sendResetPasswordEmail()} variant="contained">Send Reset Password Email</Button><br/>
            </div>

            <table className="userSettingsTable">
                <tbody>
                    <tr>
                        <td>
                            <TextField fullWidth value={fullName} onChange={(event) => setFullName(event.target.value)} label="Name" variant="outlined" /><br/>
                        </td>
                        <td>
                            <TextField fullWidth value={emailAddress} onChange={(event) => setEmailAddress(event.target.value)} label="Email Address" variant="outlined" /><br/>
                        </td>
                        <td>
                            <TextField fullWidth value={avatarColor} onChange={(event) => setAvatarColor(event.target.value)} label="Avatar Color (Hex)" variant="outlined" /><br/>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className="userSettingsSaveButtonContainer">
                <Button variant="contained" size="large" onClick={() => {updateProfile(); updateDatabaseData()}}>Save Updated Info</Button>
            </div>


            {userStatus == 'activated' ? <>
                <div className="userSettingsActiveDotContainer"><span className="userSettingsActiveDot"></span><span> Activated</span></div>
            </>:<>
            
            </>}


            {onCommission == 'true' ? <>
                <table className="userSettingsTable">
                    <tbody>
                        <tr>
                            <td>
                                <FormControlLabel control={ <Switch disabled checked={onCommission == 'true' ? true : false}/> } label="On Commission"/>
                            </td>
                            <td>
                                <FormControlLabel control={ <Switch disabled checked={commissionShareLosses == 'true' ? true : false}/> } label="Share Losses"/>
                            </td>
                            <td>
                                <TextField fullWidth disabled value={commissionOnOrders} label="Commission Per Order" variant="outlined" InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}/><br/>
                            </td>
                            <td>
                                <TextField fullWidth disabled value={commissionOnProfit} label="Commission On Total Profit" variant="outlined" InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}/><br/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </>: <></>}




            <div className="userSettingsIDContainer">
                <span>Employee ID: <b>{employeeID}</b></span><br/>
                <span>User ID: <b>{uidState}</b></span><br/>
            </div>

        </div>
      </div>
    </div>
    </>
  );
}



export default UserSettings;
