import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import {db} from '../FirebaseInit';
import {doc, onSnapshot, collection} from "firebase/firestore";
import {addDoc, Timestamp, setDoc, deleteDoc } from "firebase/firestore"; 


function BusinessFBProfiles () {

    const [addProfileName, setAddProfileName] = useState('');

    async function submitNewProfile () {
        const id = Date.now();

        const newProfileData = {
            ProfileName: addProfileName,
            createdAt: Timestamp.fromDate(new Date()),
            id: id,
        };
        setOpenNewProfileDialog(false)


        

        // Add a new document with a generated id.
        const docRef = await setDoc(doc(db, "FaceBookProfiles/" + id), newProfileData);
        setName('');
    };

    

    //NEW Profile Dialog
    const [openNewProfileDialog, setOpenNewProfileDialog] = React.useState(false);

    const handleClickOpenNewProfile = () => {
        setOpenNewProfileDialog(true);
    };
  
    const handleCloseNewProfile = (event, reason) => {
      if (reason !== 'backdropClick') {
        setOpenNewProfileDialog(false);
        setName('');
      }
    };


    //NEW Profile Dropdown
    const [name, setName] = React.useState('');
  
    const handleChangeNewProfileRole = (event) => {
      setName(event.target.value);

    };
  



    //DELETE Profile
    const [openDeleteProfile, setOpenDeleteProfile] = useState(false);
    const [deleteProfileID, setDeleteProfileID] = useState('');
 
  
    const handleClickOpenDeleteProfile = () => {
      setOpenDeleteProfile(true);
    };
  
    const handleCloseDeleteProfile = (event, reason) => {
      if (reason !== 'backdropClick') {
        setOpenDeleteProfile(false);
      }
    };

    async function deleteProfile () {
        setOpenDeleteProfile(false);

        await deleteDoc(doc(db, "FaceBookProfiles/" + deleteProfileID));
    }



    
//GETTING ALL ProfileS
const [getProfiles, setGetProfiles] = useState([]);

useEffect(() => {
        //this code fires when the app loads
        onSnapshot(collection(db, "FaceBookProfiles"), (snapshot) => {
          setGetProfiles(snapshot.docs.map(doc => doc.data()));
        }
        )}, [])
        
       
return (
<>
<Card>
        <CardContent>
            <table width="100%">
                <tbody><tr>
                    <td><strong>Profiles</strong></td>
                    <td><p align="right"><Button  onClick={handleClickOpenNewProfile} variant="outlined">New Profile</Button></p></td>
                </tr></tbody>
            </table>


            <table>
                <tbody>
                {getProfiles.map((Profile) =>
                    <tr key={Profile.id}><td>{Profile.ProfileName}</td></tr>
                )}
                </tbody>
            </table>
            <br/>




      <Dialog disableEscapeKeyDown open={openNewProfileDialog} onClose={handleCloseNewProfile}>
        <DialogTitle>New Profile</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
            <TextField onChange={event => setAddProfileName(event.target.value)} id="outlined-basic" label="Profile Name" variant="outlined" />

            </FormControl>

          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewProfile}>Discard</Button>
          <Button onClick={submitNewProfile}>Save Profile</Button>
        </DialogActions>
      </Dialog>


      <Button onClick={handleClickOpenDeleteProfile} variant="outlined" color="error">Remove Profile</Button>


      <Dialog disableEscapeKeyDown open={openDeleteProfile} onClose={handleCloseDeleteProfile}>
        <DialogTitle>Remove Profile</DialogTitle>
        <DialogContent>

            <FormControl onChange={event => setDeleteProfileID(event.target.value)}>
                <FormLabel id="demo-radio-buttons-group-label">Profiles</FormLabel>
                <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                {getProfiles.map((Profile) =>
                    <FormControlLabel key={Profile.id} value={Profile.id} control={<Radio />} label={Profile.ProfileName} />
                )}
                </RadioGroup>
            </FormControl>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteProfile}>Discard</Button>
          <Button onClick={deleteProfile}>Remove Profile</Button>
        </DialogActions>
      </Dialog>

        </CardContent>
    </Card>
</>
)
}

export default BusinessFBProfiles;