import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { doc, onSnapshot, collection, query, where, getDoc } from "firebase/firestore";
import {db} from './../FirebaseInit';
const axios = require('axios').default;



function FunnelTikTokAdAccount({linkTikTokAdAccount, setLinkTikTokAdAccount, addTikTokBusinessManager, existingTikTokAdAccount}) {
  var AccessKeyData;

  useEffect(() => {
    setLinkTikTokAdAccount(existingTikTokAdAccount)
  }, [])
  
  //TikTok pixel dropdown
  const [value, setValue] = React.useState(existingTikTokAdAccount);
  const handleMediaBuyerChange = (event) => {
    setValue(event.target.value);
    setLinkTikTokAdAccount(event.target.value);
    console.log(event.target.value)
    };


//GETTING ALL BUSINESS MANAGERS
const [getTikTokBMAccessKey, setGetTikTokBMAccessKey] = useState([]);
const [adAccountList, setAdAccountList] = useState([{}]);


useEffect(() => {
  
  getAccessKey();


}, [addTikTokBusinessManager])


async function getAccessKey() {
  const AccessKeyRef = doc(db, "TikTokBMs/" + addTikTokBusinessManager);
  AccessKeyData = await getDoc(AccessKeyRef);
  //var accessKey = AccessKeyData.data().TikTokBMAccessKey; 
  


  const adAccountListArr = [];


  const config = {
    method: 'get',
    url: 'https://us-central1-sendwell-profit-tracker.cloudfunctions.net/getTikTokAdAccounts',
  };

  const response = await axios(config);

  console.log(response.data)

   for(let i = 0; i < await response.data.length; i++) {
    console.log(i)
    //console.log(await response.data.data.list[i].advertiser_name)

    adAccountListArr.push({name: response.data[i].advertiser_name, id: response.data[i].advertiser_id})
    setAdAccountList(adAccountListArr)
  }

  //console.log(adAccountList)
}







    return ( 
        <>
      <FormControl sx={{ minWidth: "100%" }} size="small">
      <InputLabel id="demo-select-small">TikTok Ad Account Name</InputLabel>
      <Select labelId="demo-select-small" id="demo-select-small" value={value} label="TikTok Ad Account Name" onChange={handleMediaBuyerChange}>
        {adAccountList.map((x) => 
        <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>    
        )}
      </Select>
    </FormControl>
        </>
     )
}

export default FunnelTikTokAdAccount;