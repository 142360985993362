import React, {useState, useEffect} from 'react';
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from '../FirebaseInit';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import './CPCChartAnalytics.css';
import { Paper } from '@mui/material';


function CPCChartAnalytics({selectedFunnels, beginningDate, endingDate}) {
  const [adSpendObjState, setAdSpendObjState] = useState({})
  const [totalAdSpend, setTotalAdSpend] = useState([]);
  const [ordersObjState, setOrdersObjState] = useState({})
  const [averageCPC, setAverageCPC] = useState();
  const [CPCObjState, setCPCObjState] = useState([]);
  var ordersObj = [];
  var adSpendObj = [];
  var CPCObj = [];
  var dates = [];



async function getAdSpend(selectedFunnel) {
  const q = query(collection(db, "funnels", selectedFunnel, "funnelData"), where("date", ">=", beginningDate), where("date", "<=", endingDate));
  const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      if (doc.id in adSpendObj) {
        adSpendObj[doc.id] =  adSpendObj[doc.id] + doc.data().adSpend;
      } else {
        adSpendObj[doc.id] = doc.data().adSpend;
        dates.push(doc.id);
      }
  });
  setTimeout(function() {

    //sorts only the dates of the 
    var sortedDates = Object.keys(CPCObj).sort();
    var sortedCPCObj = [];
  
    for (let i = 0; i < Object.keys(CPCObj).length; i++) {
      sortedCPCObj[sortedDates[i]] = CPCObj[sortedDates[i]]

    } 
    setCPCObjState(sortedCPCObj)
  
  }, 1000);
  //console.log(adSpendObj)
  } 

  async function getOrders(selectedFunnel) {
    const q = query(collection(db, "funnels", selectedFunnel, "funnelData"), where("date", ">=", beginningDate), where("date", "<=", endingDate));
    const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        if (doc.id in ordersObj) {
          ordersObj[doc.id] =  ordersObj[doc.id] + doc.data().orders;
        } else {
          ordersObj[doc.id] = doc.data().orders;
        }
    });
    setTimeout(function() {setOrdersObjState(ordersObj)}, 1000);
    } 


  function calculateCPC () {
    for (let i = 0; i < dates.length; i++) {
      CPCObj[dates[i]] = parseFloat(adSpendObj[dates[i]] / ordersObj[dates[i]])
    }
    
  }


  useEffect(() => {
    adSpendObj = [];
    for (let i = 0; i < selectedFunnels.length; i++) { 
      getAdSpend(selectedFunnels[i]);
      getOrders(selectedFunnels[i])
    }
    setTimeout(function() {calculateCPC()}, 500)
    
    setTimeout(function() {setAverageCPC((Object.values(adSpendObj).reduce((a, b) => a + b, 0)) / (Object.values(ordersObj).reduce((a, b) => a + b, 0)))}, 1000);
    setTimeout(function() {setCPCObjState(CPCObj)}, 1000);
    
  }, [selectedFunnels])




  
  var formatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

  return (
  <>
  <Paper elevation={3} className='profitChartAnalyticsDiv'>
    <h5 className='profitChartAnalyticsTitle'>Average Cost Per Conversion</h5>
    <p className='profitChartAnalyticsOrderTotal'>{formatter.format(averageCPC)}</p>
    <div className='profitChartAnalyticsChart'>
      <Line width={"200px"} height={"50px"}
        data={{
          labels: Object.keys(CPCObjState),
          datasets: [
            {
              data: Object.values(CPCObjState),
              backgroundColor: '#08a4a4',
              borderColor: '#08a4a4',
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              display: false
            },
          },
          scales: {
            x: {
              display: true,
              grid: {
                display: true
              },
            },
            y: {
              beginAtZero: true,
              display: true,
              grid: {
                display: true
              }
            },
          },
            elements: {
              point:{
                radius: 2
              }
            }
        }}
      />
    </div>
  </Paper>
  </>
  )
}
export default CPCChartAnalytics;
