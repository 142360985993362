import React, {useState, setState, state, useEffect, useContext} from 'react';
import Divider from '@mui/material/Divider';
import './ProductPagesTabs.css';
import {db} from './FirebaseInit';
import {doc, onSnapshot, collection, updateDoc, addDoc, query, orderBy, where, getDoc, getDocs, limit} from "firebase/firestore";
import Switch from '@mui/material/Switch';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import {FunnelContext} from '../Context';
import { padding, style } from '@mui/system';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {UserTypeContext} from "../Context";
import Tooltip from '@mui/material/Tooltip';




const ProductPagesTabs = (props) => {
  const {userType} = useContext(UserTypeContext);

//set the ID of the funnel to the context api state 
const {setSelectedFunnel} = useContext(FunnelContext);
//getting the ID of the slected funnel 
const {selectedFunnel} = useContext(FunnelContext);


  const [funnels, setFunnels] = useState([]);

  var funnelsArr = [];
 
  async function getEmployeeID () {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (userType == "Media Buyer") {
          const docRef = doc(db, "users/" + user.uid);
          const docSnap = await getDoc(docRef);
  
          onSnapshot(query(collection(db, "funnels"), where("mediaBuyer", "==", docSnap.data().employeeID.toString()), where("status", ">=", 50)), (snapshot) => {
            setFunnels(snapshot.docs.map(doc => doc.data()));
          })
        } else {
          onSnapshot(query(collection(db, "funnels"), where("status", ">=", 50), orderBy("status")), (snapshot) => {
            setFunnels(snapshot.docs.map(doc => doc.data())); 
          })
        }
      }
    })
  }
  useEffect(() => {
    getEmployeeID()
  }, [])

 






    async function setActiveTrue (funnelID) {
      const funnelStatusRef = doc(db, "funnels", funnelID);
        await updateDoc(funnelStatusRef, {active: true});
    }

    async function setActiveFalse (funnelID) {
      const funnelStatusRef = doc(db, "funnels", funnelID);
        await updateDoc(funnelStatusRef, {active: false});
    }

   

 


  return (
    <div className='productPagesTabsContainer'> 


<div className='descriptionContainer'><span className='description'>Last 7 Days</span></div>


<List>
{funnels.map((funnel) =>  
<div key={funnel.funnelID}>

<Divider/>

<div style={{backgroundColor: selectedFunnel == funnel.funnelID ? "#Dfe2e1" : "white", paddingRight: "10px"}} onClick={() => setSelectedFunnel(funnel.funnelID)} >

  <ListItem disablePadding>
  <ListItemButton disableGutters>
      
    <table className='productPagesTabsTable'>
    <tbody>
      <tr className='tabRows'>
      <td><Tooltip placement="top" title="To adjust switch, go to Funnel Settings and move status from Ready, Testing, or Winner."><Switch checked={funnel.status == 51 || funnel.status == 68 || funnel.status == 85 ? true : false}/></Tooltip></td>
      
      <td className='tabLeftCol'>
          <span className='productName' style={{color: selectedFunnel == funnel.funnelID ? "rgba(0, 0, 0, 0.8)" : ""}}>{funnel.funnelName}</span><br/>
      </td>
      <td className='tabRightCol'>
          {funnel.sevenDayCpa > 0 ?
            (<span className="detailsText" style={{color:"green"}}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(funnel.sevenDayCpa)} CPA</span>):
            funnel.sevenDayCpa == undefined || funnel.sevenDayCpa == -Infinity? <></>:
              (<span className="detailsText" style={{color:"red"}}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(funnel.sevenDayCpa)} CPA</span>)
          }<br/>
          {funnel.sevenDayOrders == undefined || funnel.sevenDayOrders == 0 ? <></>:
            (<span className='detailsText' >{new Intl.NumberFormat().format(funnel.sevenDayOrders)} Orders</span>)}
      </td>
      </tr>
    </tbody>
    </table>

  </ListItemButton>
  </ListItem>

</div>


</div>
)}
<Divider/>
</List>




      </div>
  );
}

export default ProductPagesTabs;
