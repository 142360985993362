import {React, useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { doc, getDoc, collection, onSnapshot } from "firebase/firestore";
import { db } from '../FirebaseInit';


function FunnelTrafficSource({addTrafficSource, setAddTrafficSource, existingTrafficSource}) {



useEffect(() => {
    setAddTrafficSource(existingTrafficSource)
}, [])

      //GETTING ALL TRAFFIC SOURCES
const [getTrafficSource, setGetTrafficSource] = useState([]);

useEffect(() => {
    //this code fires when the app loads
    onSnapshot(collection(db, "trafficSources"), (snapshot) => {
    setGetTrafficSource(snapshot.docs.map(doc => doc.data()));
    }
)}, [])

    return ( 
        <div style={{width: "60%", margin: "auto"}}>
        <FormControl style={{minWidth: "100%"}} onChange={event => setAddTrafficSource(event.target.value)}>
        <FormLabel id="demo-row-radio-buttons-group-label">Traffic Source</FormLabel>
          <RadioGroup value={addTrafficSource} row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">

          {getTrafficSource.map((TrafficSource) =>
                    <FormControlLabel key={TrafficSource.id} value={TrafficSource.id} control={<Radio/>} label={TrafficSource.trafficSource} />
                )}
          </RadioGroup>
        </FormControl>
        </div>
     );
}

export default FunnelTrafficSource;