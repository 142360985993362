
import React, {useState, useEffect, useContext} from "react";
import Navbar from './Navbar';
import './Analytics.css'
import { TextField } from '@mui/material';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import AnalyticsFunnelList from "./AnalyticsFunnelList";
import OrdersChartAnalytics from "./AnalyticsComponents/OrdersChartAnalytics";
import RevenueChartAnalytics from "./AnalyticsComponents/RevenueChartAnalytics";
import AdSpendChartAnalytics from "./AnalyticsComponents/AdSpendChartAnalytics";
import ProductCostChartAnalytics from "./AnalyticsComponents/ProductCostChartAnalytics";
import FeesChartAnalytics from "./AnalyticsComponents/FeesChartAnalytics";
import ProfitChartAnalytics from "./AnalyticsComponents/ProfitChartAnalytics";
import SubscriberChartAnalytics from "./AnalyticsComponents/SubscriberChartAnalytics";
import CPAChartAnalytics from "./AnalyticsComponents/CPAChartAnalytics";
import CPCChartAnalytics from "./AnalyticsComponents/CPCChartAnalytics";
import CircularProgress from '@mui/material/CircularProgress';
import {UserTypeContext} from "../Context";
const axios = require("axios");
var moment = require('moment'); 



const selectedFunnelsList = []

function Analytics() {
  const [graphs, setGraphs] = useState(true);

  //get user type EX: Customer Service, Funnel Creator, Media Buyer, Accounting, Administrative, Other
  const {userType} = useContext(UserTypeContext);

  const today = () => {
    var m = moment();    
    var s = m.format();
    return s.split('T')[0];
  };
  
  const yesterday = () => {
    var m = moment().subtract(1, 'days');    
    var s = m.format();
    return s.split('T')[0];
  };
  
  const sevenDaysAgo = () => {
    var m = moment().subtract(8, 'days');    
    var s = m.format();
    return s.split('T')[0];
  }; 

  //BEGINNING DATE PICKER
  const [beginningDate, setBeginningDate] = useState(sevenDaysAgo());

  //ENDING DATE PICKER
  const [endingDate, setEndingDate] = useState(yesterday());

  const [menu, setMenu] = useState('funnels')


  //console.log(beginningDate)
  //console.log(endingDate)

  const [allData, setAllData] = useState(
    [
      {orders:
        {
          total:'', 
          data:[],
        },
        revenue:
        {
          total:'', 
          data:[],
        },
        adSpend:
        {
          total:'', 
          data:[],
        },
        profit:
        {
          total:'', 
          data:[],
        },
        subscribers:
        {
          total:'', 
          data:[],
        },
        cpa:
        {
          total:'', 
          data:[],
        },
      },
    ]
  );


  
  const [selectedFunnels, setSelectedFunnels] = useState([]);

  useEffect(() => {
    console.log('refreshing charts');
    runInOrder();
    setAllData(
      [
        {orders:
          {
            total:'', 
            data:[],
          },
          revenue:
          {
            total:'', 
            data:[],
          },
          adSpend:
          {
            total:'', 
            data:[],
          },
          profit:
          {
            total:'', 
            data:[],
          },
          subscribers:
          {
            total:'', 
            data:[],
          },
          cpa:
          {
            total:'', 
            data:[],
          },
        },
      ]
    );
    setAllFunnels([])
  }, [selectedFunnels + beginningDate + endingDate])

  //console.log(selectedFunnels)

  async function runInOrder() {
    getGraphData(await getFunnels());
  }


  async function getGraphData(selectedFunnelsParam) {
    var dataArray = [];

    //console.log(selectedFunnelsParam)

    var funnelTabsUnselected = document.getElementsByClassName("analyticsFunnelTabContainer")
    var funnelTabsSelected = document.getElementsByClassName("analyticsFunnelTabContainerSelected")

    //console.log(funnelTabsUnselected)
    console.log(funnelTabsSelected)

    for (let i = 0; i < funnelTabsUnselected.length; i++) {
      if (selectedFunnelsParam.includes(funnelTabsUnselected[i].id)) {
        document.getElementById(funnelTabsUnselected[i].id).className = "analyticsFunnelTabContainerSelected";
        //console.log(funnelTabsUnselected[i].id + " Not Selected")
      } else {
        document.getElementById(funnelTabsUnselected[i].id).className = "analyticsFunnelTabContainer";
        //console.log(funnelTabsUnselected[i].id + " Selected")
      }
    }

    for (let i = 0; i < funnelTabsSelected.length; i++) {
      if (selectedFunnelsParam.includes(funnelTabsSelected[i].id)) {
        document.getElementById(funnelTabsSelected[i].id).className = "analyticsFunnelTabContainerSelected";
        //console.log(funnelTabsSelected[i].id + " Selected")
      } else {
        document.getElementById(funnelTabsSelected[i].id).className = "analyticsFunnelTabContainer";
        //console.log(funnelTabsSelected[i].id + " Not Selected")
      }
    }
    console.log(" ")



    var config = {
      method: 'post',
      url: 'https://us-central1-sendwell-profit-tracker.cloudfunctions.net/analyticsMain?beginningDate=' + beginningDate + '&endingDate=' + endingDate,
      data: {
        "selectedFunnels": selectedFunnelsParam
      },
      headers: {
        "Content-Type": "application/json"
      }

    };

    dataArray = await axios(config);
    //console.log(dataArray)
    dataArray = dataArray.data;
    setAllData(dataArray);
  }


  const [allFunnels, setAllFunnels] = useState([]);
  async function getFunnels() {
    var funnelsArray = [];

    var config = {
      method: 'get',
      url: 'https://us-central1-sendwell-profit-tracker.cloudfunctions.net/analyticsFunnelList?beginningDate=' + beginningDate + '&endingDate=' + endingDate,
    };

    funnelsArray = await axios(config);
    funnelsArray = funnelsArray.data;
    setAllFunnels(funnelsArray);

    var selectedFunnelsArray = [];
    for (let i = 0; i < funnelsArray.length; i++) {
      selectedFunnelsArray.push(funnelsArray[i].funnelID)
    }
    setSelectedFunnels(selectedFunnelsArray)
    return selectedFunnelsArray
  }

  //console.log(selectedFunnels)

  useEffect(() => {
    getFunnels()
  }, [beginningDate + endingDate])



 
 const [displayedMediaBuyerDataForAdmin, setDisplayedMediaBuyerDataForAdmin] = useState(0)


  const [selection, setSelection] = useState([])
  const selectedFunnelList = []

  /*
  async function selectFunnel (funnelID) {
    var indexOfExistence = 0;
    var exists = false;
    for (let i = 0; i < selectedFunnelList.length; i++) {
      if (selectedFunnelList[i] == funnelID) {
        indexOfExistence = i;
        exists = true
      }
    }
    if (exists == false) {
      selectedFunnelList.push(funnelID)
    } else {
      selectedFunnelList.splice(indexOfExistence, 1) 
    }
    //setSelection(selectedFunnelList)
    console.log(selectedFunnelList)
  }
  */

  async function addFunnelToSelection (funnelID) {
    if (selectedFunnelsList.includes(funnelID)) {
      var index = selectedFunnelsList.indexOf(funnelID);
      if (index > -1) {
        selectedFunnelsList.splice(index, 1);
      }
    } else {
      selectedFunnelsList.push(funnelID)
    }
    //console.log(selectedFunnelsList)
    getGraphData(selectedFunnelsList)
  }

  function signOutUser() {
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }
 
  
  return (
    <div className="analyticsPageDiv">
   

      {userType == "Media Buyer" || userType == "Accounting" || userType == "Administrative" ? <>

      <div className="analyticsNav">
      <div className="dashboardDrawerTitleContainer">
        <div className="analyticsDateRangeSelector">
            <br/>
            <TextField label="Beginning Date" color="success" defaultValue={beginningDate} onChange={(event) => {setBeginningDate(event.target.value)}} type="date" InputLabelProps={{ shrink: true,}} size='small'/>
            <TextField label="Ending Date" color="success" defaultValue={endingDate} onChange={(event) => {setEndingDate(event.target.value)}} type="date" InputLabelProps={{ shrink: true,}} size='small'/>
        </div>
        <span className="dashboardDrawerTitle">Sendwell Stats</span>
      </div>

      <div className='productPageNavigationTabsContainer'>
        <div className={menu == 'nav' ? 'activeProductPageNavigationTabs' : 'productPageNavigationTabs' } onClick={() => {setMenu('nav')}}>
          <span className='productPageNavigationTabsText'>Navigation</span>
        </div>
        <div className={menu == 'funnels' ? 'activeProductPageNavigationTabs' : 'productPageNavigationTabs' } onClick={() => {setMenu('funnels')}}>
          <span className='productPageNavigationTabsText'>Funnels</span>
        </div>
      </div>

      {menu == 'nav' ? <>


        <div>
          <div className="dashboardDrawerSectionTitle">
            <span>General</span>
          </div>
          <div className="dashboardDrawerSection">
            <a href="/Dashboard" className="dashboardDrawerLinkTitle">Dashboard</a>
          </div>
          <div className="dashboardDrawerSection">
            <a href="/ProductIdeas" className="dashboardDrawerLinkTitle">Product Ideas</a>
          </div>

          <div className="dashboardDrawerSectionTitle">
            <span>Finances</span>
          </div>
          <div className="dashboardDrawerSection">
            <a href="/Accounting" className="dashboardDrawerLinkTitle">Accounting</a>
          </div>
          <div className="dashboardDrawerSection">
            <a href="/AccountBalances" className="dashboardDrawerLinkTitle">Account Balances</a>
          </div>
          <div className="dashboardDrawerSection activeDashboardDrawerSection">
            <a href="/Analytics" className="activeDashboardDrawerLinkTitle">Analytics</a>
          </div>
          <div className="dashboardDrawerSection">
            <a href="/Commission" className="dashboardDrawerLinkTitle">Commission</a>
          </div>

          <div className="dashboardDrawerSectionTitle">
            <span>Management</span>
          </div>
          <div className="dashboardDrawerSection">
            <a href="/UserSettings" className="dashboardDrawerLinkTitle">User Settings</a>
          </div>
          <div className="dashboardDrawerSection">
            <a href="/UserPermissions" className="dashboardDrawerLinkTitle">User Permissions</a>
          </div>
          <div className="dashboardDrawerSection">
            <a href="/BusinessSettings" className="dashboardDrawerLinkTitle">Business Settings</a>
          </div>

          <div className="dashboardDrawerSectionTitle">
            <span></span>
          </div>
          <div className="dashboardDrawerSectionSignOut" onClick={() => signOutUser()}>
            <span className="dashboardDrawerLinkTitleSignOut">Sign Out</span>
          </div>
        </div>

        </>:<>
          {allFunnels.length == 0 ? 
            <div className="analyticsFunnelTabLoadingTextContainer">
              <CircularProgress color='success'/><br/><br/>
              <span className="analyticsFunnelTabLoadingText">Loading active funnels within selected date range.</span>
            </div>
          
          :<>
          
          {allFunnels.map((a) => { return (<div key={a.funnelID}>
            <div id={a.funnelID} className="analyticsFunnelTabContainerSelected" onClick={() => {addFunnelToSelection(a.funnelID)}}>
              <div className="analyticsFunnelTabContainerTitle">
                <span className="analyticsFunnelTabFunnelNameText">{a.funnelName}</span>
                <span className="analyticsFunnelTabFunnelOrdersText">{a.orders + " Orders"}</span>
                
              </div>
            </div>
          </div>
          )}
          )}

          </>}
        
        
        </>}

      </div>

      <div className="analyticsPageChartsTableContainer">

      <div className="analyticsTableContainer">
        <table className="analyticsTable">
          <tbody>
            <tr className="analyticsTableHeaders">
              <td>Funnel Name</td>
              <td>Orders</td>
              <td>Revenue</td>
              <td>COGS</td>
              <td>Ads</td>
              <td>Fees</td>
              <td>Profit</td>
              <td>Subs</td>
              {/*<td>Clicks</td>*/}
              {/*<td>Imp.</td>*/}
              <td>CVR</td>
              {/*<td>CPS</td>*/}
              {/*<td>CPC</td>*/}
              <td>ROAS</td>
              <td>AOV</td>
              <td>CPA</td>
              {/*<td>CTR</td>*/}
              {/*<td>CPM</td>*/}
              <td>Sub. Rate</td>


            </tr>
      {allFunnels.map((a) => { return (
                  <tr key={a.funnelID}>
                    <td>{a.funnelName}</td>
                    <td>{new Intl.NumberFormat().format(a.orders)}</td>
                    <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.revenue)}</td>
                    <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.cogs)}</td>
                    <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.adSpend)}</td>
                    <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.fees)}</td>
                    <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.total)}</td>
                    <td>{new Intl.NumberFormat().format(a.subscribers)}</td>
                    {/*<td>{new Intl.NumberFormat().format(a.clicks)}</td>*/}
                    {/*<td>{new Intl.NumberFormat().format(a.impressions)}</td>*/}
                    <td>{new Intl.NumberFormat('en-US', {style: 'percent'}).format(a.cvr)}</td>
                    {/*<td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.cps)}</td>*/}
                    {/*<td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.cpc)}</td>*/}
                    <td>{new Intl.NumberFormat().format(a.roas)}</td>
                    <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.aov)}</td>
                    <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.cpa)}</td>
                    {/*<td>{new Intl.NumberFormat('en-US', {style: 'percent'}).format(a.ctr)}</td>*/}
                    {/*<td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.cpm)}</td>*/}
                    <td>{new Intl.NumberFormat('en-US', {style: 'percent'}).format(a.subscriptionRate)}</td>
                  </tr>
                )}
              )}
            </tbody>
          </table>
      </div>



        <table className="analyticsPageChartsContainer">
          <tbody>
            <tr>
              <td>
                <OrdersChartAnalytics className="analyticsOrdersChart" ordersData={allData[0].orders} />
              </td>
              <td>
                <RevenueChartAnalytics className="analyticsRevenueChart" revenueData={allData[0].revenue}/>
              </td>
            </tr>
            <tr>
              <td>
                <AdSpendChartAnalytics className="analyticsAdSpendChart" adSpendData={allData[0].adSpend}/>
              </td>
              <td>
                <ProfitChartAnalytics className="analyticsProfitChart" profitData={allData[0].profit}/>
              </td>
            </tr>
            <tr>
              <td>
                <SubscriberChartAnalytics className="subscriberAdSpendChart" subscribersData={allData[0].subscribers}/>
              </td>
              <td>
                <CPAChartAnalytics className="analyticsCPAChart" cpaData={allData[0].cpa}/>
              </td>
            </tr>
          </tbody>
        </table>

      </div>

      </> : <><br/><center>
            <div className="analyticsFunnelTabLoadingTextContainer">
              <CircularProgress color='success'/><br/><br/>
            </div></center></>}

 



    </div>

     
    
 
  );
}





export default Analytics;
