import React, {useState, useEffect, useContext} from 'react';
import {db} from './FirebaseInit';
import {doc, onSnapshot, collection, getDocs, query, where, getDoc} from "firebase/firestore";
import './DashboardMediaBuyers.css';
import {FunnelContext} from '../Context';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import {UserTypeContext} from "../Context";
import CircularProgress from '@mui/material/CircularProgress';



function MediaBuyersDoughnut(props) {


  return (<>

   
  
  

  </>)
}
export default MediaBuyersDoughnut;