
import React, {useState, useEffect, useContext} from "react";
import Paper from '@mui/material/Paper';
import Navbar from './Navbar';
import { db } from './FirebaseInit';
import {doc, collection, getDocs, getDoc, query, updateDoc} from "firebase/firestore";
import LinearProgress from '@mui/material/LinearProgress';
import { Box } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {UserTypeContext} from "../Context";
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Switch from '@mui/material/Switch';
import { getAuth, onAuthStateChanged, updateProfile, updateEmail, sendPasswordResetEmail, signOut } from "firebase/auth";
import { fontSize } from "@mui/system";
import BusinessTrafficSources from "./BusinessComponents/BusinessTrafficSources";
import BusinessFaceBookBMs from "./BusinessComponents/BusinessFacebookBM";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import './BusinessSettings.css';
const moment = require('moment-timezone');

const axios = require('axios').default;


  
function BusinessSettings() {

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);


  const [year, setYear] = useState("" + moment().year());
  const [month, setMonth] = useState(moment().month() + 1 < 10 ? "0" + (moment().month() + 1) : "" + moment().month() + 1 );
  const [day, setDay] = useState("" + moment().date() < 10 ? "0" + moment().date() : "" + moment().date());


  const [dataUpdateLoading, setDataUpdateLoading] = useState(false);
  async function updateStats() {
    //console.log(year.length)
    //console.log(month.length)
    //console.log(day.length)

    if (year.length != 4) {
      alert("Please enter a four digit year.")
      return
    }
    if (month.length != 2) {
      alert("Please enter a two digit month.")
      return
    }
    if (day.length != 2) {
      alert("Please enter a two digit day.")
      return
    }

    setDataUpdateLoading(true);

    var date = year + "-" + month + "-" + day;

    const config = {
      method: 'get',
      url: 'https://us-central1-sendwell-profit-tracker.cloudfunctions.net/updateStatsImmediately?date=' + date,
    };

    const response = await axios(config);

    if (response.data == 'stats update complete') {
      setDataUpdateLoading(false);
      //setTimeout((window.location.reload(false)), 1000)
      setOpenSnackbar(true)
    }

  }


    useEffect(() => {

    }, [])


    function signOutUser() {
        const auth = getAuth();
        signOut(auth).then(() => {
          // Sign-out successful.
        }).catch((error) => {
          // An error happened.
        });
      }

  return (
    <>
    <div className="userSettingsMainContainer">

      <div className="dashboardDrawer">
        <div className="dashboardDrawerTitleContainer">
            <span className="dashboardDrawerTitle">Sendwell Stats</span>
        </div>

        <div>
            <div className="dashboardDrawerSectionTitle">
                <span>General</span>
            </div>
            <div className="dashboardDrawerSection">
                <a href="/Dashboard" className="dashboardDrawerLinkTitle">Dashboard</a>
            </div>
            <div className="dashboardDrawerSection">
                <a href="/ProductIdeas" className="dashboardDrawerLinkTitle">Product Ideas</a>
            </div>

            <div className="dashboardDrawerSectionTitle">
                <span>Finances</span>
            </div>
            <div className="dashboardDrawerSection">
                <a href="/Accounting" className="dashboardDrawerLinkTitle">Accounting</a>
            </div>
            <div className="dashboardDrawerSection">
                <a href="/AccountBalances" className="dashboardDrawerLinkTitle">Account Balances</a>
            </div>
            <div className="dashboardDrawerSection">
                <a href="/Analytics" className="dashboardDrawerLinkTitle">Analytics</a>
            </div>
            <div className="dashboardDrawerSection">
                <a href="/Commission" className="dashboardDrawerLinkTitle">Commission</a>
            </div>

            <div className="dashboardDrawerSectionTitle">
                <span>Management</span>
            </div>
            <div className="dashboardDrawerSection">
                <a href="/UserSettings" className="dashboardDrawerLinkTitle">User Settings</a>
            </div>
            <div className="dashboardDrawerSection">
                <a href="/UserPermissions" className="dashboardDrawerLinkTitle">User Permissions</a>
            </div>
            <div className="activeDashboardDrawerSection">
                <a href="/BusinessSettings" className="activeDashboardDrawerLinkTitle">Business Settings</a>
            </div>

            <div className="dashboardDrawerSectionTitle">
                <span></span>
            </div>
            <div className="dashboardDrawerSectionSignOut" onClick={() => signOutUser()}>
                <span className="dashboardDrawerLinkTitleSignOut">Sign Out</span>
            </div>
        </div>

      </div>




      <div className="dashboardBackground ">


      <div className="businessSettingsMainContainer">
        <div className="businessSettingsTrafficSourcesContainer">
          <BusinessTrafficSources/>
        </div>
        <div className="businessSettingsFacebookBMsContainer">
          <BusinessFaceBookBMs/>
        </div>
        <div className="businessSettingsCustomDateRefetch">
          <span>Data Refresh</span><br/><br/>
          <TextField value={year} onChange={event => setYear(event.target.value)} label="Year Ex: 2023" variant="outlined" />
          <TextField value={month} onChange={event => setMonth(event.target.value)} label="Month Ex: 03" variant="outlined" />
          <TextField value={day} onChange={event => setDay(event.target.value)} label="Day Ex: 01" variant="outlined" />
          <br/><br/>
          {dataUpdateLoading == false ? 
            <Button onClick={() => updateStats()} variant="contained">Update Stats</Button>
          :
            <Button disabled variant="contained">Updating</Button>
          }

        </div>
      </div>


      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          Data has been successfully updated!
        </Alert>
      </Snackbar>

      </div>
    </div>
    </>
  );
}



export default BusinessSettings;
