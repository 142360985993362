
import React, {useState, useEffect, useContext} from "react";
import ReactDOM from 'react-dom';
import { Outlet, Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import OrdersChart from './Charts/OrdersChart'
import ProfitChart from './Charts/ProfitChart';
import CpaChart from "./Charts/CpaChart";
import SubscribersChart from "./Charts/SubscribersChart";
import PopularProducts from './PopularProducts';
import Navbar from './Navbar';
import { db } from './FirebaseInit';
import {doc, onSnapshot, collection, deleteDoc, addDoc, updateDoc, getDoc, where, query, getDocs, Timestamp, orderBy} from "firebase/firestore";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Slider } from '@mui/material';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import EditIcon from '@mui/icons-material/Edit';



  
const fabStyle = {
  position: 'fixed',
  bottom: 16,
  right: 16,
};

const marks = [
  { value: 0, label: 'Inactive',},
  { value: 17, label: 'Product Idea',},
  { value: 34, label: 'In Development',},
  { value: 51, label: 'Ready',},
  { value: 68, label: 'Testing',},
  { value: 85, label: 'Winner',},
  { value: 100, label: "Loser",}
];

function ProductIdeas() {
  const [funnelsState, setFunnelsState] = useState([])
  var funnels = [];

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState(false);


  const [generalProductName, setGeneralProductName] = useState('');
  const [status, setStatus] = useState(0);
  const [supplierLink, setSupplierLink] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [referrer, setReferrer] = useState('');
  const [funnelDomain, setFunnelDomain] = useState('');
  const [funnelName, setFunnelName] = useState('');
  const [productName, setProductName] = useState('');
  const [notes, setNotes] = useState('');
  const [funnelCreator, setFunnelCreator] = useState('');

  const [funnelIDState, setFunnelIDState] = useState('');


  const [openCreateFunnel, setOpenCreateFunnel] = React.useState(false);

  const handleClickOpenCreateFunnel = () => {
    setOpenCreateFunnel(true);
    setStatus(0)
  };

  const handleCloseCreateFunnel = () => {
    setOpenCreateFunnel(false);
  };

  const [openEditFunnel, setOpenEditFunnel] = React.useState(false);

const handleClickOpenEditFunnel = () => {
  setOpenEditFunnel(true);
};

const handleCloseEditFunnel = () => {
  setOpenEditFunnel(false);
};



  useEffect(() => {
    getFunnels();
  }, [openEditFunnel + openCreateFunnel + openDeleteConfirmation])

  async function getFunnels () {
    const funnelsRef = query(collection(db, "funnels"), where("status", "<=", 53), orderBy("status", "desc"))
    const funnelsIDs = await getDocs(funnelsRef);
    funnelsIDs.forEach((doc) => {
      funnels.push({funnelID: doc.id, funnelName: doc.data().funnelName, funnelDomain:doc.data().domain, priority: doc.data().priority,
                    ideaCreated: new Date(((doc.data().createdAt).seconds) * 1000).toDateString(), mediaBuyer: doc.data().mediaBuyer, ideaNotes: doc.data().productIdeaNotes,
                    referredBy: doc.data().referrer, productName: doc.data().productName, supplierLink: doc.data().supplierLink, lastSaved: new Date(((doc.data().lastSaved).seconds) * 1000).toDateString(),
                    videoLink: doc.data().videoLink, productDescription: doc.data().generalProductName, funnelCreator: doc.data().funnelCreator, status: doc.data().status});
      setFunnelsState(funnels);
    })//END OF FOR EACH 
  }// END OF ASYNC GET FUNNELS FUNCTION




async function createFunnel() {
  const auth = getAuth();
onAuthStateChanged(auth, async (user) => {
  if (user) {
    const getMediaBuyerIDRef = doc(db, "users", user.uid);
    const getMediaBuyerIDSnap = await getDoc(getMediaBuyerIDRef);
    console.log(getMediaBuyerIDSnap.data().employeeID);



  const funnelData = {
    generalProductName: generalProductName,
    supplierLink: supplierLink,
    videoLink: videoLink,
    referrer: referrer,
    funnelDomain: funnelDomain,
    funnelName: funnelName,
    productName: productName,
    productIdeaNotes: notes,
    priority: "",
    status: status,
    funnelCreator: "",
    mediaBuyer: getMediaBuyerIDSnap.data().employeeID,
    createdAt: Timestamp.fromDate(new Date()),
    lastSaved: Timestamp.fromDate(new Date()),
    linkedFacebookCampaign: [{id: "", name: ""}],
    active: false,
    ideaApproved: "",
    funnelID: "",
    funnelNotes: "",
    subsweetFunnel: "noSelection"
};

  const docRef = await addDoc(collection(db, "funnels"), funnelData);
  const addFunnelID = await updateDoc(doc(db, "funnels", docRef.id, ), {funnelID: docRef.id}); 
  handleCloseCreateFunnel();
}
})
}



async function editProductIdeaMenu(funnelID) {
  setFunnelIDState(funnelID);
  const funnelRef = doc(db, "funnels", funnelID);
  const funnelSnap = await getDoc(funnelRef);
  console.log(funnelSnap.data())
  setGeneralProductName(funnelSnap.data().generalProductName);
  setSupplierLink(funnelSnap.data().supplierLink);
  setVideoLink(funnelSnap.data().videoLink);
  setReferrer(funnelSnap.data().referrer);
  setFunnelDomain(funnelSnap.data().funnelDomain);
  setFunnelName(funnelSnap.data().funnelName);
  setProductName(funnelSnap.data().productName);
  setNotes(funnelSnap.data().productIdeaNotes);
  setFunnelCreator(funnelSnap.data().funnelCreator);
  setStatus(funnelSnap.data().status);

  handleClickOpenEditFunnel();

}


async function submitProductIdeaEdit() {
  const funnelData = {
    generalProductName: generalProductName,
    status: status,
    supplierLink: supplierLink,
    videoLink: videoLink,
    referrer: referrer,
    funnelDomain: funnelDomain,
    funnelName: funnelName,
    productName: productName,
    productIdeaNotes: notes,
    funnelCreator: funnelCreator,
    lastSaved: Timestamp.fromDate(new Date()),
};

  const docRef = await updateDoc(doc(db, "funnels", funnelIDState), funnelData);

  handleCloseEditFunnel();
}



const handleCloseDeleteConfirmation = () => {
  setOpenDeleteConfirmation(false);
};

async function deleteProductIdea (funnelID) {
  await deleteDoc(doc(db, "funnels", funnelID));
  setOpenDeleteConfirmation(false);
  setOpenEditFunnel(false);

}



  return (
    <div className="dashboardBackground">
   
      <Navbar pageTitle="PRODUCT IDEAS" date={false}/>

      <br/>

      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Created</TableCell>
            <TableCell>Last Saved</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>General Product Name</TableCell>
            <TableCell>AliExpress Link</TableCell>
            <TableCell>Video Link</TableCell>
            <TableCell>Funnel Name</TableCell>
            <TableCell>Product Name</TableCell>
            <TableCell>Referrer Name</TableCell>
            <TableCell>Notes</TableCell>
            <TableCell>Funnel Creator</TableCell>
            <TableCell>Edit Idea</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {funnelsState.map((a) => (
            <TableRow key={a.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>{a.ideaCreated}</TableCell>
              <TableCell>{a.lastSaved}</TableCell>
              <TableCell>{a.status == 0 ? "Inactive" : <></>}{a.status == 17 ? "Product Idea" : <></>}{a.status == 34 ? "In Development" : <></>}{a.status == 51 ? "Ready" : <></>}</TableCell>
              <TableCell>{a.productDescription}</TableCell>
              <TableCell>{a.supplierLink}</TableCell>
              <TableCell>{a.videoLink}</TableCell>
              <TableCell>{a.funnelName}</TableCell>
              <TableCell>{a.productName}</TableCell>
              <TableCell>{a.referredBy}</TableCell>
              <TableCell>{a.ideaNotes}</TableCell>
              <TableCell>{a.funnelCreator}</TableCell>
              <TableCell><Button onClick={() => editProductIdeaMenu(a.funnelID)}><EditIcon/></Button></TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    


    <Fab onClick={handleClickOpenCreateFunnel} variant="extended" color="primary" aria-label="add" sx={fabStyle}>
      <LightbulbIcon sx={{ mr: 1 }} />
      Add Product Idea
    </Fab>



    <Dialog open={openCreateFunnel} onClose={handleCloseCreateFunnel}>
        <DialogTitle>New Product Idea</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the new product idea information below.
          </DialogContentText>
          <TextField autoFocus margin="dense" label="General Product Name" type="text" fullWidth variant="standard" onChange={event => setGeneralProductName(event.target.value)}/><br/><br/> 
          <TextField autoFocus margin="dense" label="Funnel Creator" type="text" fullWidth variant="standard" onChange={event => setFunnelCreator(event.target.value)}/><br/><br/> 
          <Slider value={status} step={null} marks={marks} onChange={event => setStatus(event.target.value)}/>
          <TextField autoFocus margin="dense" label="AliExpress Link" type="text" fullWidth variant="standard" onChange={event => setSupplierLink(event.target.value)}/>
          <TextField autoFocus margin="dense" label="Video Link" type="text" fullWidth variant="standard" onChange={event => setVideoLink(event.target.value)}/>
          <TextField autoFocus margin="dense" label="Referrer" type="text" fullWidth variant="standard" onChange={event => setReferrer(event.target.value)}/>
          <TextField autoFocus margin="dense" label="Funnel Domain" type="text" fullWidth variant="standard" onChange={event => setFunnelDomain(event.target.value)}/>
          <TextField autoFocus margin="dense" label="Funnel Name" type="text" fullWidth variant="standard" onChange={event => setFunnelName(event.target.value)}/>
          <TextField autoFocus margin="dense" label="Product Name" type="text" fullWidth variant="standard" onChange={event => setProductName(event.target.value)}/><br/><br/>
          <TextField id="outlined-textarea" label="Notes" placeholder="Notes" fullWidth multiline onChange={event => setNotes(event.target.value)}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateFunnel}>Cancel</Button>
          <Button onClick={createFunnel}>Add Product Idea</Button>
        </DialogActions>
      </Dialog>




      <Dialog open={openEditFunnel} onClose={handleCloseEditFunnel}>
        <DialogTitle>{generalProductName + " Details"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the updated product idea information below.
          </DialogContentText>
          <TextField value={generalProductName} autoFocus margin="dense" label="General Product Name" type="text" fullWidth variant="standard" onChange={event => setGeneralProductName(event.target.value)}/>
          <TextField value={funnelCreator} autoFocus margin="dense" label="Funnel Creator" type="text" fullWidth variant="standard" onChange={event => setFunnelCreator(event.target.value)}/><br/><br/> 
          <Slider value={status} step={null} marks={marks} onChange={event => setStatus(event.target.value)}/>
          <TextField value={supplierLink} autoFocus margin="dense" label="AliExpress Link" type="text" fullWidth variant="standard" onChange={event => setSupplierLink(event.target.value)}/>
          <TextField value={videoLink} autoFocus margin="dense" label="Video Link" type="text" fullWidth variant="standard" onChange={event => setVideoLink(event.target.value)}/>
          <TextField value={referrer} autoFocus margin="dense" label="Referrer" type="text" fullWidth variant="standard" onChange={event => setReferrer(event.target.value)}/>
          <TextField value={funnelDomain} autoFocus margin="dense" label="Funnel Domain" type="text" fullWidth variant="standard" onChange={event => setFunnelDomain(event.target.value)}/>
          <TextField value={funnelName} autoFocus margin="dense" label="Funnel Name" type="text" fullWidth variant="standard" onChange={event => setFunnelName(event.target.value)}/>
          <TextField value={productName} autoFocus margin="dense" label="Product Name" type="text" fullWidth variant="standard" onChange={event => setProductName(event.target.value)}/><br/><br/>
          <TextField value={notes} id="outlined-textarea" label="Notes" placeholder="Notes" fullWidth multiline onChange={event => setNotes(event.target.value)}/>
          <p><Button variant="contained" size="large" color="error" onClick={() => setOpenDeleteConfirmation(true)}>Delete Product Idea</Button></p>
          <p>Funnel ID: <b>{funnelIDState}</b></p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditFunnel}>Cancel</Button>
          <Button onClick={submitProductIdeaEdit}>Add Product Idea</Button>
        </DialogActions>
      </Dialog>



      <Dialog open={openDeleteConfirmation} onClose={handleCloseDeleteConfirmation}>
        <DialogTitle>{generalProductName + " Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you wish to delete {generalProductName}?
          </DialogContentText>
          <p>Funnel ID: <b>{funnelIDState}</b></p>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="large" color="primary" onClick={() => deleteProductIdea(funnelIDState)}>Do Not Delete</Button>
          <Button variant="contained" size="large" color="error" onClick={() => deleteProductIdea(funnelIDState)}>Delete Product Idea</Button>
        </DialogActions>
      </Dialog>



      </div>

     
    
 
  );
}





export default ProductIdeas;
