
import React, {useState, useEffect, useContext} from "react";
import ReactDOM from 'react-dom';
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './LoginStyle.css';
import { Outlet, Link } from "react-router-dom";
import {db} from './FirebaseInit';
import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, Timestamp } from "firebase/firestore"; 





const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);


const provider = new GoogleAuthProvider();




function signInWithGoogle () {
  const auth = getAuth();
signInWithPopup(auth, provider)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    //console.log(user);

    
    const userData = {
      fullName: user.displayName,
      uid: user.uid,
      photoURL: user.photoURL,
      phoneNumber: user.phoneNumber,
      createdAt: Timestamp.fromDate(new Date()),
     
  };

  setDoc(doc(db, "users", user.uid), userData);
    
   

    // ...
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    // ...
  });




}




function BasicCard() {

  const [emailText, setEmailText] = useState('');
  const [passwordText, setPasswordText] = useState('');

  const auth = getAuth();

  const [errorText, setErrorText] = useState('');



function signIn() {
  signInWithEmailAndPassword(auth, emailText, passwordText)
  .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    setTimeout((window.location.reload(true)), 3000)
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode)
    if (errorCode === "auth/invalid-email") {
      setErrorText("An invalid email address was entered.")
    } else if (errorCode == "auth/wrong-password") {
      setErrorText("Wrong Password")
    } else if (errorCode == "auth/too-many-requests") {
      setErrorText("Too many incorrect entries. Please try again in a few minutes.")
    } else if (errorCode == "auth/user-not-found") {
      setErrorText("Email has not been registered.")
    }
  });
}

  





function signUp() {
  createUserWithEmailAndPassword(auth, emailText, passwordText)
  .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
      if (errorMessage == "auth/wrong-password") {
        console.log("Wrong Password")
      }
        
  });
}
  

  const handleEmailTextChange = (event) => {
    setEmailText(event.target.value)
}

const handlePasswordTextChange = (event) => {
  setPasswordText(event.target.value)
}

  
  return (
    <>
           

    <div className="background">
    <center className="loginCardStyle">
    <div className="loginCardContainer">
  
        <h3 className="loginPageWebsiteTitle">Sendwell Statistics</h3>
        
        <br/><br/>

        <div className="emailTextBoxDiv">
        <TextField className="emailTextBox" onChange={handleEmailTextChange} label="Email" variant="outlined" type="email" />
        </div>

        <div className="passwordTextBoxDiv">
        <TextField  className="passwordTextBox" onChange={handlePasswordTextChange} label="Password" variant="outlined" type="password" />
        </div>

        <p>{errorText}</p>

        <table className="loginButtonTable">
          <tbody>
            <tr>
              <td>
                <div><Button className="signUpButton" onClick={signUp} variant="contained">Sign Up</Button></div>
              </td>
              <td></td>
              <td>
                <div><Button className="signInButton" onClick={signIn} variant="contained">Sign In</Button></div>
              </td>
            </tr>
          </tbody>
        </table>
        
        

       


    </div>
    </center>
    </div>
    </>
  );
}



const Login = () => {
  return(
  <>

  <BasicCard/>


  </>
)
}

export default Login;
