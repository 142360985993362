import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { db } from '../FirebaseInit';
import { doc, getDoc, collection, onSnapshot } from "firebase/firestore";
import { MenuItem } from '@mui/material';

function FunnelFaceBookBM({addFaceBookBusinessManager, setAddFaceBookBusinessManager, existingFacebookBM}) {


useEffect(() => {
  setAddFaceBookBusinessManager(existingFacebookBM);
}, [])


    function AddBusinessManagerText () {
        if (addFaceBookBusinessManager === "addNew"){
          return <TextField autoFocus margin="dense" id="name" label="Add New Business Manager" type="text" fullWidth variant="standard"/>
        }
      }

      //GETTING ALL BUSINESS MANAGERS
const [getFaceBookBM, setGetFaceBookBM] = useState([]);

useEffect(() => {
  //this code fires when the app loads
  onSnapshot(collection(db, "FaceBookBMs"), (snapshot) => {
    setGetFaceBookBM(snapshot.docs.map(doc => doc.data()));
  }
)}, [])



const [value, setValue] = React.useState(existingFacebookBM);

   


//facebook page dropdown

const handleFaceBookBMChange = (event) => {
setValue(event.target.value);
setAddFaceBookBusinessManager(event.target.value);
};

    return ( 
      
      <>
      <FormControl sx={{ minWidth: "100%" }} size="small">
      <InputLabel id="demo-select-small">FaceBook BM</InputLabel>
      <Select labelId="demo-select-small" id="demo-select-small" value={value} label="FaceBook BMs" onChange={handleFaceBookBMChange} >
      {getFaceBookBM.map((FaceBookBM) =>
        <MenuItem key={FaceBookBM.id} value={FaceBookBM.id}>{FaceBookBM.FaceBookBM}</MenuItem>
      )}
      </Select>
      </FormControl>
      </>
     )
}

export default FunnelFaceBookBM;