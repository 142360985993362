import React, {useState, setState, state, useEffect, useContext} from 'react';
import {doc, onSnapshot, collection, getDocs, query, getDoc, where} from "firebase/firestore";
import {db} from '../FirebaseInit';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import './SubscribersChart.css';
import shoppingCart from '../../images/shopping-cart.png';
import { Link } from 'react-router-dom';
import {UserTypeContext} from "../../Context";


function SubscribersChart(props) {
  const {userType} = useContext(UserTypeContext);

  const firestoreSubscribersDataArray = [];
  const firestoreDateDataArray = [];
  const [subscribers, setSubscribers] = useState();
  const [dates, setDates] = useState();

  const [totalSubscribers, setTotalSubscribers] = useState();

  useEffect(() => {
    if (userType == "Media Buyer") {
      getMediaBuyerDailyTotals();
    } else if ((userType == "Administrative" || userType == "Accounting") && props.mediaBuyer == 'all'){
      getDailyTotals(); 
    } else if ((userType == "Administrative" || userType == "Accounting") && props.mediaBuyer != 'all'){
      adminGetMediaBuyerDailyTotals(); 
    }
  }, [props])


  async function adminGetMediaBuyerDailyTotals () {
    const docRef = doc(db, "users/" + props.uidState);
    const docSnap = await getDoc(docRef);

    const DailyTotalRef = query(collection(db, "mediaBuyerDailyData/mediaBuyers/" + props.mediaBuyer), where("date", ">=", props.beginningDate), where("date", "<=", props.endingDate))
    const DailyTotals = await getDocs(DailyTotalRef);
    DailyTotals.forEach((docs) => {
      firestoreSubscribersDataArray.push(docs.data().subscribers);
      firestoreDateDataArray.push(docs.id);
    })//END OF FOR EACH 
    setSubscribers(firestoreSubscribersDataArray); 
    setDates(firestoreDateDataArray);
    setTotalSubscribers(firestoreSubscribersDataArray.reduce((a, b) => a + b, 0));
    props.setSubscribers(firestoreSubscribersDataArray.reduce((a, b) => a + b, 0));
  }// END OF ASYNC GET DAILY TOTALS FUNCTION


  async function getDailyTotals () {
    const DailyTotalRef = query(collection(db, "totalDailyData/"), where("date", ">=", props.beginningDate), where("date", "<=", props.endingDate))
    const DailyTotals = await getDocs(DailyTotalRef);
    DailyTotals.forEach((docs) => {
      firestoreSubscribersDataArray.push(docs.data().subscribers);
      firestoreDateDataArray.push(docs.id);
    })//END OF FOR EACH 
    setSubscribers(firestoreSubscribersDataArray); 
    setDates(firestoreDateDataArray);
    setTotalSubscribers(firestoreSubscribersDataArray.reduce((a, b) => a + b, 0));
    props.setSubscribers(firestoreSubscribersDataArray.reduce((a, b) => a + b, 0));
  }// END OF ASYNC GET DAILY TOTALS FUNCTION


  async function getMediaBuyerDailyTotals () {
    const docRef = doc(db, "users/" + props.uidState);
    const docSnap = await getDoc(docRef);

    const DailyTotalRef = query(collection(db, "mediaBuyerDailyData/mediaBuyers/" + docSnap.data().employeeID), where("date", ">=", props.beginningDate), where("date", "<=", props.endingDate))
    const DailyTotals = await getDocs(DailyTotalRef);
    DailyTotals.forEach((docs) => {
      firestoreSubscribersDataArray.push(docs.data().subscribers);
      firestoreDateDataArray.push(docs.id);
    })//END OF FOR EACH 
    setSubscribers(firestoreSubscribersDataArray); 
    setDates(firestoreDateDataArray);
    setTotalSubscribers(firestoreSubscribersDataArray.reduce((a, b) => a + b, 0));
    props.setSubscribers(firestoreSubscribersDataArray.reduce((a, b) => a + b, 0));
  }// END OF ASYNC GET DAILY TOTALS FUNCTION

  return (
    <div className="subscribersCard"> <br/>

    <table>
      <tbody>
        <tr>
          <td>
            <span className='subscribersCardTitle'>SUBSCRIBERS</span><br/>
            <span className='subscribersCardQuantity'>{new Intl.NumberFormat().format(totalSubscribers)}</span>
          </td>
          <td>
            <div className='chartContainer'>
              <Line width={"150px"} height={"70px"}
              data={{
    labels: dates,
    datasets: [
      {
        data: subscribers,
        backgroundColor: '#ffffff',
        borderColor: '#ffffff',
      },
    ],
              }}
              options={{
    events: [],
    
    plugins: {
      legend: {
          display: false
      },
    },

    scales: {
      x: {
        display: false,
        grid: {
          display: false
        }
      },
      y: {
        beginAtZero: false,
        display: false,
        grid: {
          display: false
        }
      },
    },

    elements: {
      point:{
          radius: 0
      }
  }

              }}
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>



      <br/>

     

    </div>

  )
}
export default SubscribersChart;
