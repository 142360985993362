import React, {useState, useEffect, useContext} from "react";
import Paper from '@mui/material/Paper';
import ProductPagesTabs from './ProductPagesTabs';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Tablejs from './Table';
import './AccountingDetails.css';
import AddFunnel from './AddFunnel';
import NavbarProductPage from './NavbarProductPage';
import {UserTypeContext} from "../Context";
import Button from "@mui/material/Button";
import {FunnelContext} from "../Context";
import AccountingTabs from "./AccountingTabs";
import ProductPageOptions from "./ProductPageOptions";
import AccountingDetailsTable from "./AccountingDetailsTable";
import CircularProgress from '@mui/material/CircularProgress';



export default function AccountingDetails() {
  const {userType} = useContext(UserTypeContext);

  const { selectedFunnel } = useContext(FunnelContext);

  const [showManualInputTable, setShowManualInputTable] = useState(false);
  
  const [showDrawer, setShowDrawer] = useState(true)


  return (
      <div className="dashboardBackground">

  {userType == "Media Buyer" || userType == "Accounting" || userType == "Administrative" ? 

    <>
    <div className="productPageTabsDrawerHideBtnContainer">
     <Button className="productPageTabsDrawerHideBtn" variant="contained" onClick={() => {showDrawer == true ? setShowDrawer(false) : setShowDrawer(true)}}>{showDrawer == true ? "Hide Funnels" : "Show Funnels"}</Button>
    </div>

      {selectedFunnel != 'a' ? 
        <ProductPageOptions setShowManualInputTable={setShowManualInputTable} showManualInputTable={showManualInputTable}/>
      :<></>}


        <AddFunnel/>
        <table>
          <tbody>
            <tr>
              {showDrawer == true ? 
              <td className="productPageTableSpacingTabs"><div className='AccountingDetailsTabsSection'><AccountingTabs/></div></td>
              :<></>}
              {selectedFunnel != 'a' ? 
              <td className="accountingDetailsTable"><div className="accountingDetailsTable"><AccountingDetailsTable showManualInputTable={showManualInputTable} setShowManualInputTable={setShowManualInputTable}/></div></td>
              :<td className="productPageNoFunnelSelectedContainer"><h3 className="productPageNoFunnelSelectedText">Please Select a Funnel</h3></td>}
            </tr>
          </tbody>
        </table>     

    </>
   : <><br/><div className="AccountingPageLoader"><center><CircularProgress color="success"/></center></div></>}

    </div>
  );
}
