import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import {db} from '../FirebaseInit';
import {doc, onSnapshot, collection} from "firebase/firestore";
import {addDoc, Timestamp, setDoc, deleteDoc } from "firebase/firestore"; 
import './BusinessFacebookBM.css';

function BusinessFaceBookBMs () {

    //traffic source name
    const [addFaceBookBM, setAddFaceBookBM] = useState('');
    const [addFaceBookBMID, setAddFaceBookBMID] = useState('');
    const [addFaceBookBMAccessKey, setAddFaceBookBMAccessKey]= useState('');

    async function submitNewFaceBookBM () {
   

        const newFaceBookBM = {
            FaceBookBM: addFaceBookBM,
            createdAt: Timestamp.fromDate(new Date()),
            id: addFaceBookBMID,
            FaceBookBMAccessKey: addFaceBookBMAccessKey,
        };
        setOpenNewFaceBookBMDialog(false)
        console.log(newFaceBookBM)
        
        // Add a new document with a user inputted id.
        const docRef = await setDoc(doc(db, "FaceBookBMs/" + addFaceBookBMID), newFaceBookBM);
    };

    

    //NEW Traffic Source Dialog
    const [openNewFaceBookBMDialog, setOpenNewFaceBookBMDialog] = React.useState(false);

    const handleClickOpenNewFaceBookBM = () => {
        setOpenNewFaceBookBMDialog(true);
    };
  
    const handleCloseNewFaceBookBM = (event, reason) => {
      if (reason !== 'backdropClick') {
        setOpenNewFaceBookBMDialog(false);
      }
    };


    //DELETE Employee
    const [openDeleteFaceBookBM, setOpenDeleteFaceBookBM] = useState(false);
    const [deleteFaceBookBMID, setDeleteFaceBookBMID] = useState('');
  
    const handleClickOpenDeleteFaceBookBM = () => {
      setOpenDeleteFaceBookBM(true);
    };
  
    const handleCloseDeleteFaceBookBM = (event, reason) => {
      if (reason !== 'backdropClick') {
        setOpenDeleteFaceBookBM(false);
      }
    };

    async function deleteFaceBookBM () {
        setOpenDeleteFaceBookBM(false);
        await deleteDoc(doc(db, "FaceBookBMs/" + deleteFaceBookBMID));
    }

    
//GETTING ALL BUSINESS MANAGERS
const [getFaceBookBM, setGetFaceBookBM] = useState([]);

useEffect(() => {
    //this code fires when the app loads
    onSnapshot(collection(db, "FaceBookBMs"), (snapshot) => {
    setGetFaceBookBM(snapshot.docs.map(doc => doc.data()));
    }
)}, [])
        
        
return (
<>
<div className="businessSettingsFacebookBMs">

    <table width="100%"><tbody><tr>
        <td><strong>Facebook Business Managers</strong></td>
        <td><p align="right"><Button  onClick={handleClickOpenNewFaceBookBM} variant="outlined">New Facebook BM</Button></p></td>
    </tr></tbody></table>


    <table><tbody>
    {getFaceBookBM.map((FaceBookBM) =>
        <tr key={FaceBookBM.id}><td>{FaceBookBM.FaceBookBM}</td></tr>
    )}
    </tbody></table><br/>


      <Dialog disableEscapeKeyDown open={openNewFaceBookBMDialog} onClose={handleCloseNewFaceBookBM}>
        <DialogTitle>New Facebook Business Manager</DialogTitle>
        <DialogContent>
          
            <TextField onChange={event => setAddFaceBookBM(event.target.value)} id="outlined-basic" label="BM Name" variant="outlined" />
            <TextField onChange={event => setAddFaceBookBMID(event.target.value)} id="outlined-basic" label="BM ID" variant="outlined" />
            <br/>
          
          <TextField onChange={event => setAddFaceBookBMAccessKey(event.target.value)} id="outlined-basic" label="BM Access Key" variant="outlined" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewFaceBookBM}>Discard</Button>
          <Button onClick={submitNewFaceBookBM}>Save Business Manager</Button>
        </DialogActions>
      </Dialog>


      <Button onClick={handleClickOpenDeleteFaceBookBM} variant="outlined" color="error">Remove Business Manager</Button>


      <Dialog disableEscapeKeyDown open={openDeleteFaceBookBM} onClose={handleClickOpenDeleteFaceBookBM}>
        <DialogTitle>Remove Business Manager</DialogTitle>
        <DialogContent>
            <FormControl onChange={event => setDeleteFaceBookBMID(event.target.value)}>
                <FormLabel id="demo-radio-buttons-group-label">Facebook Business Manager</FormLabel>
                <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                {getFaceBookBM.map((FaceBookBM) =>
                    <FormControlLabel key={FaceBookBM.id} value={FaceBookBM.id} control={<Radio />} label={FaceBookBM.FaceBookBM} />
                )}
                </RadioGroup>
            </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteFaceBookBM}>Discard</Button>
          <Button onClick={deleteFaceBookBM}>Remove Business Manager</Button>
        </DialogActions>
      </Dialog>

</div>
</>
)
}

export default BusinessFaceBookBMs;