import React, { useEffect } from 'react';

import { Slider } from '@mui/material';
import './FunnelStatusSlider.css'

function FunnelStatusSlider({setAddStatus, addStatus, existingFunnelStatus}) {

useEffect(() => {
  setAddStatus(existingFunnelStatus);
}, [])

//logic for status slider
const marks = [
    { value: 0, label: 'Inactive',},
    { value: 17, label: 'Idea',},
    { value: 34, label: 'Creating',},
    { value: 51, label: 'Ready',},
    { value: 68, label: 'Testing',},
    { value: 85, label: 'Winner',},
    { value: 100, label: "Loser",}
  ];

 

    return ( 
        <div className='FunnelStatusSliderContainer'>
            <Slider value={addStatus} step={null} marks={marks} onChange={event => setAddStatus(event.target.value)}/>
            <i>Note: Setting Status below "Ready" will move funnel to "Product Ideas"</i>
            <br/><br/> 
        </div>
     )
}

export default FunnelStatusSlider;