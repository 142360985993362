import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { doc, onSnapshot, collection, query, where, getDoc } from "firebase/firestore";
import {db} from './../FirebaseInit';


function FunnelFaceBookAdAccount({linkFacebookAdAccount, setLinkFacebookAdAccount, addFaceBookBusinessManager, existingFacebookAdAccount}) {
  var AccessKeyData;

  useEffect(() => {
    setLinkFacebookAdAccount(existingFacebookAdAccount)
  }, [])
  
  //facebook pixel dropdown
  const [value, setValue] = React.useState(existingFacebookAdAccount);
  const handleMediaBuyerChange = (event) => {
    setValue(event.target.value);
    setLinkFacebookAdAccount(event.target.value);
    console.log(event.target.value)
    };


//GETTING ALL BUSINESS MANAGERS
const [getFaceBookBMAccessKey, setGetFaceBookBMAccessKey] = useState([]);
const [adAccountList, setAdAccountList] = useState([{}]);


useEffect(() => {
  
  getAccessKey();


}, [addFaceBookBusinessManager])


async function getAccessKey() {
  const AccessKeyRef = doc(db, "FaceBookBMs/" + addFaceBookBusinessManager);
  AccessKeyData = await getDoc(AccessKeyRef);
  var accessKey = AccessKeyData.data().FaceBookBMAccessKey; 
  


  const adAccountListArr = [];

  var apiLink = "https://graph.facebook.com/v17.0/me/adaccounts?fields=name,account_id,account_status,id&limit=100&access_token=" + accessKey;
  
    fetch(apiLink)
      .then((response) => {
        return response.json(); 
      })
      .then((data) => {
  
        for(let i = 0; i < data.data.length; i++) {
  
          adAccountListArr.push({name: data.data[i].name, id: data.data[i].account_id})
          setAdAccountList(adAccountListArr)
        }

     
      })
}







    return ( 
        <>
      <FormControl sx={{ minWidth: "100%" }} size="small">
      <InputLabel id="demo-select-small">Facebook Ad Account Name</InputLabel>
      <Select labelId="demo-select-small" id="demo-select-small" value={value} label="Facebook Ad Account Name" onChange={handleMediaBuyerChange}>
        {adAccountList.map((x) => 
        <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>    
        )}
      </Select>
    </FormControl>
        </>
     )
}

export default FunnelFaceBookAdAccount;