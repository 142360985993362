import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { doc, onSnapshot, collection, query, where, getDoc } from "firebase/firestore";
import {db} from './../FirebaseInit';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import { fontWeight } from '@mui/system';
import { Button } from '@mui/material';
import './FunnelCampaignSelector.css'

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function FunnelCampaignSelector({linkFacebookCampaign, setLinkFacebookCampaign, addFaceBookBusinessManager, linkFacebookAdAccount, existingFacebookCampaign}) {
  const theme = useTheme();

  
  
  var AccessKeyData;

  useEffect(() => {
    if(existingFacebookCampaign != undefined) {
      setLinkFacebookCampaign(existingFacebookCampaign);
    }
  }, [])
  


const [campaignList, setCampaignList] = useState([]);





useEffect(() => {
  getAccessKey();
}, [linkFacebookAdAccount])


async function getAccessKey() {
  const AccessKeyRef = doc(db, "FaceBookBMs/" + addFaceBookBusinessManager);
  AccessKeyData = await getDoc(AccessKeyRef);
  var accessKey = AccessKeyData.data().FaceBookBMAccessKey;
  

const campaignListArr = [];
var apiLink = "https://graph.facebook.com/v17.0/act_" + linkFacebookAdAccount + "?fields=campaigns%7Bstatus%2Cname%2Cid%7D&access_token=" + accessKey;

  fetch(apiLink)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      let campaigns = data;
     
      //console.log(data)

      for(let i = 0; i < campaigns.campaigns.data.length; i++) {
        //console.log(campaigns.campaigns.data[i].id + " => " + campaigns.campaigns.data[i].name)
        campaignListArr.push({name: campaigns.campaigns.data[i].name, id: campaigns.campaigns.data[i].id})
      }
 
      setCampaignList(campaignListArr)
    })

  
        }
        
        const handleChange = (event) => {
          const {
            target: { value },
          } = event;
            setLinkFacebookCampaign(
              // On autofill we get a stringified value.
              typeof value === 'string' ? value.split(',') : value,
            );
        };
        

    return ( 
        <>

        <table className='funnelCampaignSelectorTable'>
          <tbody className='funnelCampaignSelectorTable'>
            <tr className='funnelCampaignSelectorTable'>
              <td className='funnelCampaignSelectorCampaignDropdown'>
      <div className='funnelCampaignSelectorCampaignDropdown'>
    <FormControl sx={{minWidth: "100%"}}>
        <InputLabel id="demo-multiple-chip-label">Campaigns on Selected Ad Account</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={linkFacebookCampaign}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Campaigns on Selected Ad Account" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              
              {selected.map((value) => (<>
                <Chip key={value.name} label={value.name} />
                </>
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          
          {campaignList.map((x) => (
            <MenuItem
              key={x.id}
              value={{id: x.id, name: x.name}}
              style={{backgroundColor: (JSON.stringify(linkFacebookCampaign)).includes(x.id) ? "#f2f2f2" : "white", fontWeight: (JSON.stringify(linkFacebookCampaign)).includes(x.id) ? "600" : "400"}}
            >
              {x.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </div>

            </td><td className='funnelCampaignSelectorSpacer'></td>
              <td>
                
      <Button onClick={() => setLinkFacebookCampaign([])} variant="outlined" size="large">Clear</Button>
              </td>
            </tr>
          </tbody>
        </table>
        </>
     )
}

export default FunnelCampaignSelector;