import React, {useState, useEffect} from 'react';
import {collection, getDocs, orderBy, query, where} from "firebase/firestore";
import {db} from '../FirebaseInit';
import { Line, Bar } from 'react-chartjs-2';
import CircularProgress from '@mui/material/CircularProgress';
import 'chart.js/auto';
import './AdSpendChartAnalytics.css';
import { Paper } from '@mui/material';


function AdSpendChartAnalytics({adSpendData}) {
  const [sortedAdSpendObjState, setSortedAdSpendObjState] = useState({})
  const [totalAdSpend, setTotalAdSpend] = useState([]);

  var dateArray = [];
  var dataArray = [];

  for (let i = 0; i < adSpendData.data.length; i++) {
    dateArray.push(adSpendData.data[i].date);
    dataArray.push(adSpendData.data[i].data);

  }


  useEffect(() => {

  }, [])
  
 

  return (
  <>

  <div className='adSpendChartAnalyticsDiv'>
    {adSpendData.data.length != 0 ? <>
      <div className='adSpendChartAnalyticsOrderTotalContainer'>
        <span className='adSpendChartAnalyticsOrderTotal'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(adSpendData.total) + ' Advertising'}</span>
      </div>
      <div className='adSpendChartAnalyticsChart'>
      <Bar
                    data={{
          labels: dateArray,
          datasets: [
            {
              data: dataArray,
              backgroundColor: 'rgb(0, 171, 85)',
              borderColor: 'rgb(0, 171, 85)',
            },
          ],
                    }}
                    options={{
          responsive: true,
          maintainAspectRatio: true,
          events: [], 
          
          plugins: {
            legend: {
                display: false,
            },
          },

          scales: {
            x: {
              display: true,
              grid: {
                display: false
              },
              ticks: {
                autoSkip: true,
                maxTicksLimit: 8,
                maxRotation: 0,
                minRotation: 0,
                font: {
                  family: 'Varela Round'
                }
              },
              
            },
            y: {
              beginAtZero: true,
              display: true,
              grid: {
                display: false
              },
              ticks: {
                autoSkip: true,
                maxTicksLimit: 5,
                maxRotation: 0,
                minRotation: 0,
                font: {
                  family: 'Varela Round'
                }
              }
            },
          },

          elements: {
            point:{
                radius: 0
            }
        }

                    }}
                  />
      </div>
      </>:<>
      <div className='adSpendChartAnalyticsLoadingContainer'>
        <CircularProgress color='success'/><br/><br/>
        <span className='adSpendChartAnalyticsLoadingText'>Loading Advertising Data</span>
      </div>
    </>}
  </div>


  

  </>
  )
}
export default AdSpendChartAnalytics;
