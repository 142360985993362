import React, { useEffect, useState, useContext } from 'react';
import { styled, alpha, useTheme} from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import { TextField } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SavingsIcon from '@mui/icons-material/Savings';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import LogoutIcon from '@mui/icons-material/Logout';
import NavbarSearch from './NavbarSearch';
import { getAuth, signOut } from "firebase/auth";
import {UserTypeContext} from "../Context";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { collection, getDocs, query, where, onSnapshot } from "firebase/firestore";
import {db} from './FirebaseInit';
import LoadingButton from '@mui/lab/LoadingButton';
import { Opacity } from '@mui/icons-material';
const axios = require("axios");




const drawerWidth = 240;

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));




export default function PrimarySearchAppBar(props) {
  const {userType} = useContext(UserTypeContext);


const [beginningDate, setBeginningDate] = useState(props.beginningDate);
const [endingDate, setEndingDate] = useState(props.endingDate);
const [datePreset, setDatePreset] = useState('custom')




const [todayLoading, setTodayLoading] = useState(false);
async function handleClick() {
  setTodayLoading(true);
  const config = {
    method: 'get',
    url: 'https://us-central1-sendwell-profit-tracker.cloudfunctions.net/updateStatsImmediately',
  };

  const response = await axios(config);

  if (response.data == 'stats update complete') {
    //setLoading(false);
    setTimeout((window.location.reload(false)), 10000)
  }
}

const [yesterdayLoading, setYesterdayLoading] = useState(false);
async function handleYesterdayRefreshClick() {
  setYesterdayLoading(true);
  const config = {
    method: 'get',
    url: 'https://us-central1-sendwell-profit-tracker.cloudfunctions.net/updateYesterdayStatsImmediately',
  };

  const response = await axios(config);

  if (response.data == 'stats update complete') {
    setYesterdayLoading(false);
  }
}




function handleBeginningDateChange(event) {
  // Here, we invoke the callback with the new value
  props.onBeginningDateChange(event.target.value);
}

function handleEndingDateChange(event) {
  // Here, we invoke the callback with the new value
  props.onEndingDateChange(event.target.value);
}

function handlePresetDateChange(event) {
  setDatePreset(event.target.value);
  console.log(event.target.value);
  if (event.target.value == 'today') {
    setBeginningDate("2022-11-28");
    setEndingDate("2022-11-29");

  } else if (event.target.value == 'yesterday') {

  } else if (event.target.value == 'last7') {

  } else if (event.target.value == 'last30') {

  } else if (event.target.value == 'thisMonth') {

  }
}


const theme = useTheme();
const [open, setOpen] = React.useState(false);

const handleDrawerOpen = () => {
  setOpen(true);
};

const handleDrawerClose = () => {
  setOpen(false);
};


function signOutFnc() {
  const auth = getAuth();
  signOut(auth).then(() => {
    // Sign-out successful.
  }).catch((error) => {
    // An error happened.
  });
  }



  const [medaiBuyerDropdownValue, setMediaBuyerDropdownValue] = useState(0)
//GETTING ALL EMPLOYEES
const [mediaBuyerList, setMediaBuyerList] = useState([]);

useEffect(() => {
        //this code fires when the app loads
        onSnapshot(query(collection(db, "employees"), where("employeeRole", "==", "Media Buyer")), (snapshot) => {
          setMediaBuyerList(snapshot.docs.map(doc => doc.data()));
        }
        )}, [])


  const switchMediaBuyers = (event) => {
    setMediaBuyerDropdownValue(event.target.value)
    props.setDisplayedMediaBuyerDataForAdmin(event.target.value)
    };


  return (
    <Box sx={{ flexGrow: 1, opacity: '100%' }}>
      <AppBar style={{ backgroundColor: 'white', opacity: '100%'}} position="relative">
        <Toolbar  style={{color: "#1976d2"}} >
          <IconButton size="large" edge="start" color="inherit" aria-label="open drawer" sx={{ mr: 2 }}  onClick={handleDrawerOpen}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ display: { xs: 'none', sm: 'block' } }}>{props.pageTitle}</Typography>
          <Search >
           
            
          {userType == "Media Buyer" || userType == "Accounting" || userType == "Administrative" ? <>
              <NavbarSearch/>
          </> : <></>}
            
            
          </Search>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

          {(userType == "Accounting" || userType == "Administrative") && props.pageTitle == "ANALYTICS" ? <>
          <Box sx={{ minWidth: 240, paddingRight: "20px" }}>
            <FormControl size='small' fullWidth>
              <InputLabel id="demo-simple-select-label">Media Buyer</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={medaiBuyerDropdownValue}
                label="Media Buyer"
                onChange={switchMediaBuyers}
              >
                <MenuItem value={0}><i>All Media Buyers</i></MenuItem>
                {mediaBuyerList.map((x) => {
                  return <MenuItem key={x.id} value={x.userID}>{x.employeeName}</MenuItem>
                })}

              </Select>
            </FormControl>
          </Box>
          </> : <></>}


          
          {1 == 2 ? <>

            <Box sx={{ minWidth: 240, paddingRight: "20px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Date Preset</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={datePreset}
                label="Media Buyer"
                onChange={handlePresetDateChange}
              >
                <MenuItem value='custom'><i>Custom</i></MenuItem>
                <MenuItem value='today'>Today</MenuItem>
                <MenuItem value='yesterday'>Yesterday</MenuItem>
                <MenuItem value='last7'>Last 7 Days</MenuItem>
                <MenuItem value='last30'>Last 30 Days</MenuItem>
                <MenuItem value='thisMonth'>This Month</MenuItem>
              </Select>
            </FormControl>
          </Box>

          </> : <></>}
          


          {props.date == true ? <>

            <TextField label="Beginning Date" defaultValue={beginningDate} onChange={handleBeginningDateChange} type="date" InputLabelProps={{ shrink: true,}} size='small'/>
            <TextField label="Ending Date" defaultValue={endingDate} onChange={handleEndingDateChange} type="date" InputLabelProps={{ shrink: true,}} size='small'/>
            </> : <></> 
          }



          {props.todayRefresh == true ? <>
            <LoadingButton style={{marginLeft: "30px"}} onClick={handleClick} size="small" loading={todayLoading} variant="outlined" >
              Refresh Today's Data
            </LoadingButton>
          </> : <></>}

          {props.yesterdayRefresh == true ? <>
            <LoadingButton style={{marginLeft: "30px"}} onClick={handleYesterdayRefreshClick} size="small" loading={yesterdayLoading} variant="outlined" >
              Refresh Yesterday's Data
            </LoadingButton>
          </> : <></>}

          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton size="large" aria-label="show more" aria-haspopup="true" color="inherit">
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer sx={{width: drawerWidth, flexShrink: 0, '& .MuiDrawer-paper': {width: drawerWidth, boxSizing: 'border-box',},}} variant="persistent" anchor="left" open={open}>


        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
      {userType == "Media Buyer" || userType == "Accounting" || userType == "Administrative" ? <>
        <Link to='/Dashboard'>
          <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><DashboardIcon/></ListItemIcon>
                <ListItemText primary="Dashboard"/>
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to='/ProductPage'>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><ManageHistoryIcon/></ListItemIcon>
                <ListItemText primary="Accounting Details"/>
              </ListItemButton>
            </ListItem>
            </Link>
            <Link to='/Analytics'>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><TrendingUpIcon/></ListItemIcon>
                <ListItemText primary="Analytics"/>
              </ListItemButton>
            </ListItem>
            </Link>
            <Link to='/ProductIdeas'>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><LightbulbIcon/></ListItemIcon>
                <ListItemText primary="Product Ideas" />
              </ListItemButton>
            </ListItem>
            </Link>
            <Link to='/AccountBalances'>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><AttachMoneyIcon/></ListItemIcon>
                <ListItemText primary="Account Balances" />
              </ListItemButton>
            </ListItem>
            </Link>
            <Link to='/Commission'>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><SavingsIcon/></ListItemIcon>
                <ListItemText primary="Commission" />
              </ListItemButton>
            </ListItem>
            </Link>
            <Link to='/Business'>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><SettingsIcon/></ListItemIcon>
                <ListItemText primary="Business Settings" />
              </ListItemButton>
            </ListItem>
            </Link>

            </> : <>
            <Link to='/ProductIdeas'>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><LightbulbIcon/></ListItemIcon>
                <ListItemText primary="Product Ideas" />
              </ListItemButton>
            </ListItem>
            </Link>
            </>}

            <Divider/>

            <ListItem disablePadding onClick={signOutFnc}>
              <ListItemButton>
                <ListItemIcon><LogoutIcon/></ListItemIcon>
                <ListItemText primary="Sign Out" />
              </ListItemButton>
            </ListItem>
          
        </List>




      </Drawer>



    </Box>
  );
}
