import React, {useState, setState, state, useEffect, useContext} from 'react';
import {db} from './FirebaseInit';
import {collection, getDocs, query, where, doc, getDoc} from "firebase/firestore";
import './TopFunnels.css';
import {FunnelContext} from '../Context';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import {UserTypeContext} from "../Context";



function TopFunnels(props) {
  const {userType} = useContext(UserTypeContext);
   
  const {setSelectedFunnel} = useContext(FunnelContext);
  const {selectedFunnel} = useContext(FunnelContext);
  
  const [allData, setAllData] = useState([]);
  var allDataArr = [];

  const [numberOfResults, setNumberOfResults] = useState(10);
  const handleNumberOfResultsChange = (event) => {
    setNumberOfResults(event.target.value);
  };

  const [loadingFromFilter, setLoadingFromFilter] = useState(true)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(false);

  const [sortBy, setSortBy] = useState('orders');
  const handleSortByChange = (event) => {
    setSortBy(event.target.value);
  };

  useEffect(() => {
    setAllData([])
    //console.log(props.uidState)
    if (userType == "Media Buyer") {
      getMediaBuyerFunnels();
    } else if ((userType == "Administrative" || userType == "Accounting") && props.mediaBuyer == 'all'){
      getFunnels(); 
    } else if ((userType == "Administrative" || userType == "Accounting") && props.mediaBuyer != 'all'){
      adminGetMediaBuyerFunnels(); 
    }
  }, [props.beginningDate + props.endingDate + sortBy + numberOfResults + props.mediaBuyer])

  async function adminGetMediaBuyerFunnels () {
    const funnelsRef = query(collection(db, "funnels"), where("mediaBuyer", "==", (props.mediaBuyer).toString()))
    const funnelsIDs = await getDocs(funnelsRef);
    funnelsIDs.forEach((doc) => {
      funnelData(doc.id, doc.data().status, doc.data().funnelName);
    })//END OF FOR EACH 
  }// END OF ASYNC GET FUNNELS FUNCTION

  async function getFunnels () {
    const funnelsRef = (collection(db, "funnels"))
    const funnelsIDs = await getDocs(funnelsRef);
    funnelsIDs.forEach((doc) => {
      funnelData(doc.id, doc.data().status, doc.data().funnelName);
    })//END OF FOR EACH 
  }// END OF ASYNC GET FUNNELS FUNCTION

  async function getMediaBuyerFunnels () {
    const docRef = doc(db, "users/" + props.uidState);
    const docSnap = await getDoc(docRef);

    const funnelsRef = query(collection(db, "funnels"), where("mediaBuyer", "==", docSnap.data().employeeID.toString()))
    const funnelsIDs = await getDocs(funnelsRef);
    funnelsIDs.forEach((doc) => {
      funnelData(doc.id, doc.data().status, doc.data().funnelName);
      //console.log(doc.data())
    })//END OF FOR EACH 
  }// END OF ASYNC GET FUNNELS FUNCTION
       
  async function funnelData(funnelID, funnelStatus, funnelName) {
    const funnelsDataRef = (query(collection(db, "funnels/" + funnelID + "/funnelData"), where("date", ">=", props.beginningDate), where("date", "<=", props.endingDate)));
    const funnelsData = await getDocs(funnelsDataRef);
    var firestoreOrderDataArray = [];
    var firestoreCOGSDataArray = [];
    var firestoreFeesDataArray = [];
    var firestoreRevenueDataArray = [];
    var firestoreAdspendDataArray = [];
    var firestoreTotalDataArray = [];
    var firestoreSubscribersDataArray = [];

    funnelsData.forEach((docs) => {
      firestoreOrderDataArray.push(docs.data().orders);
      firestoreCOGSDataArray.push(docs.data().productCost);
      firestoreFeesDataArray.push(docs.data().fees);
      firestoreRevenueDataArray.push(docs.data().revenue);
      firestoreAdspendDataArray.push(docs.data().adSpend);
      firestoreTotalDataArray.push(docs.data().total);
      firestoreSubscribersDataArray.push(docs.data().subscribers);
    })

    if(firestoreOrderDataArray.reduce((a, b) => a + b, 0) != 0) {
      allDataArr.push({
        orders: firestoreOrderDataArray.reduce((a, b) => a + b, 0), 
        cogs: firestoreCOGSDataArray.reduce((a, b) => a + b, 0), 
        fees: firestoreFeesDataArray.reduce((a, b) => a + b, 0), 
        funnelName: funnelName,
        funnelID: funnelID,
        status: funnelStatus,
        revenue: firestoreRevenueDataArray.reduce((a, b) => a + b, 0),
        adspend: firestoreAdspendDataArray.reduce((a, b) => a + b, 0), 
        cpc: ((firestoreAdspendDataArray.reduce((a, b) => a + b, 0)) / (firestoreOrderDataArray.reduce((a, b) => a + b, 0))).toFixed(2),
        total: firestoreTotalDataArray.reduce((a, b) => a + b, 0).toFixed(2),
        subscribers: firestoreSubscribersDataArray.reduce((a, b) => a + b, 0), 
        cpa: ((firestoreTotalDataArray.reduce((a, b) => a + b, 0)) / (firestoreOrderDataArray.reduce((a, b) => a + b, 0))).toFixed(2),
      })

    }


    switch (sortBy) {
      case "orders":
        setLoading(false)
        allDataArr = allDataArr.sort((a,b) => b.orders - a.orders)
        setTimeout(function() {setAllData(allDataArr.slice(0, (numberOfResults))) }, 500);
        if (allDataArr.length == 0) {
          setData(false)
        } else {
          setData(true)
        }
        break;
      case "status":
        allDataArr = allDataArr.sort((a,b) => b.status - a.status)
        setTimeout(function() {setAllData(allDataArr.slice(0, (numberOfResults))) }, 500);
        if (allDataArr.length == 0) {
          setData(false)
        } else {
          setData(true)
        }
        break;
      case "cpa":
        allDataArr = allDataArr.sort((a,b) => b.cpa - a.cpa)
        setTimeout(function() {setAllData(allDataArr.slice(0, (numberOfResults))) }, 500);
        if (allDataArr.length == 0) {
          setData(false)
        } else {
          setData(true)
        }
        break;
    }



  } //END OF ASYNC FUNNEL DATA FUNCTION
       
  //console.log(numberOfResults)

  return (
    <div className='topFunnelsContainer'>
      {loading == true ? <div><span className="topFunnelsNoSalesText"><CircularProgress color="success" /><br/><br/>Loading Funnels</span></div> : <>
        {data == false ? <div><span className="topFunnelsNoSalesText"><br/><br/>No Sales In Selected Date Range</span></div> : <>
        <table className="topFunnelsTable">
          <thead>
            <tr>
              <th className='topFunnelsHeader'><span className="topFunnelsTableHeaders">Rank</span></th>
              <th className='topFunnelsHeader'><span className="topFunnelsTableHeaders">Funnel Name</span></th>
              <th className='topFunnelsHeader'><span className="topFunnelsTableHeaders">Status</span></th>
              <th className='topFunnelsHeader'><span className="topFunnelsTableHeaders">Orders</span></th>
              <th className='topFunnelsHeader'><span className="topFunnelsTableHeaders">Profit</span></th>
              <th className='topFunnelsHeader'><span className="topFunnelsTableHeaders">CPA</span></th>
              <th className='topFunnelsHeader'><span className="topFunnelsTableHeaders">COGS</span></th>
              <th className='topFunnelsHeader'><span className="topFunnelsTableHeaders">Fees</span></th>
              <th className='topFunnelsHeader'><span className="topFunnelsTableHeaders">Subs</span></th>
            </tr>
          </thead>
                    
        {allData.map((x, index) => { return(
          <tbody key={x.funnelID}>
            <tr className="topFunnelsRows">  
              <td className='topFunnelsColumn'><span className='topFunnelsDataText'>{index + 1}</span></td>
              <td onClick={() => setSelectedFunnel(x.funnelID)} className='topFunnelsColumn'><Link to='/Accounting'><span className='topFunnelsDataText'>{x.funnelName}</span></Link></td>
              <td className='topFunnelsStatusText'>{x.status == 68 ? 
                  <span className='topFunnelsDataText'>Testing</span>
                  : <> {x.status == 85 ?
                    <span className='topFunnelsDataText'>Winner</span>
                    : <span className='topFunnelsDataText'>Loser</span>}</>
                }
              </td>
              <td className='topFunnelsColumn'><span className='topFunnelsDataText'>{new Intl.NumberFormat().format(x.orders)}</span></td>
              <td className='topFunnelsColumn'><span className='topFunnelsDataText'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(x.total)}</span></td>
              <td className='topFunnelsColumn'><span className='topFunnelsDataText'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(x.cpa)}</span></td>
              <td className='topFunnelsColumn'><span className='topFunnelsDataText'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(x.cogs)}</span></td>
              <td className='topFunnelsColumn'><span className='topFunnelsDataText'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(x.fees)}</span></td>
              <td className='topFunnelsColumn'><span className='topFunnelsDataText'>{new Intl.NumberFormat().format(x.subscribers)}</span></td>
            </tr>
            <tr className="topFunnelsSpacerRows"><td></td></tr>
          </tbody>
        )}
        )}

        </table><br/>
        
        <div className='topFunnelsBottomResultsFilterSectionContainer'>
          <div className='topFunnelsBottomResultsFilterSection'>
            <div className='topFunnelsResultsFiltersContainer'>
              <div className={numberOfResults == 5 ? 'topFunnelsActiveFilterContainer' : 'topFunnelsFilterContainer'} onClick={() => setNumberOfResults(5)}>
                <span className='topFunnelsActiveFiltertext'>5 Results</span>
              </div>

              <div className={numberOfResults == 10 ? 'topFunnelsActiveFilterContainer' : 'topFunnelsFilterContainer'} onClick={() => setNumberOfResults(10)}>
                <span className='topFunnelsFiltertext'>10 Results</span>
              </div>

              <div className={numberOfResults == 25 ? 'topFunnelsActiveFilterContainer' : 'topFunnelsFilterContainer'} onClick={() => setNumberOfResults(25)}>
                <span className='topFunnelsFiltertext'>25 Results</span>
              </div>
            </div>


            <div className='topFunnelsResultsSortByContainer'>
              <div className={sortBy == 'orders' ? 'topFunnelsActiveSortByContainer' : 'topFunnelsSortByContainer'} onClick={() => setSortBy('orders')}>
                <span className='topFunnelsActiveSortBytext'>Sort By Orders</span>
              </div>

              <div className={sortBy == 'status' ? 'topFunnelsActiveSortByContainer' : 'topFunnelsSortByContainer'} onClick={() => setSortBy('status')}>
                <span className='topFunnelsSortBytext'>Sort By Status</span>
              </div>

              <div className={sortBy == 'cpa' ? 'topFunnelsActiveSortByContainer' : 'topFunnelsSortByContainer'} onClick={() => setSortBy('cpa')}>
                <span className='topFunnelsSortBytext'>Sort By CPA</span>
              </div>
            </div>
          </div>
        </div>



      </>}
      </>}
    </div>
  )
} 
export default TopFunnels;