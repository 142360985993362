import React, { useEffect, useState, useContext, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';
import { db } from './FirebaseInit';
import { where, query, collection, getDocs, getDoc, doc } from 'firebase/firestore';
import Autocomplete from '@mui/material/Autocomplete';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import './NavbarSearch.css';
import {Link} from "react-router-dom";
import {FunnelContext} from '../Context';
import InputAdornment from '@mui/material/InputAdornment';
import {UserTypeContext} from "../Context";
import { getAuth, onAuthStateChanged } from "firebase/auth";







export default function NavbarSearch() {
const {userType} = useContext(UserTypeContext);


//set the ID of the funnel to the context api state 
const {setSelectedFunnel} = useContext(FunnelContext);
//getting the ID of the slected funnel 
const {selectedFunnel} = useContext(FunnelContext);

const [anchorEl, setAnchorEl] = React.useState(null);
const open = Boolean(anchorEl);
const handleClick = (event) => {setAnchorEl(event.currentTarget)};
const handleClose = () => {setAnchorEl(null)};





  const [searchValue, setSearchValue] = useState('')
  const [matchingFunnelsState, setMatchingFunnelsState] = useState([])
  var matchingFunnels = [];
  const [funnelListState, setFunnelListState] = useState([]);



  var funnelList = [];

  async function getFunnels () {
    const funnelsRef = query(collection(db, "funnels"))
    const funnelsIDs = await getDocs(funnelsRef);
    funnelsIDs.forEach((doc) => {
      funnelList.push({funnelName: doc.data().funnelName, lowercaseFunnelName: ((doc.data().funnelName).toLowerCase()), funnelID: doc.data().funnelID});
      setFunnelListState(funnelList);
    })//END OF FOR EACH 
  }// END OF ASYNC GET FUNNELS FUNCTION


  async function getMediaBuyerFunnels (uid) {
    const docRef = doc(db, "users/" + uid);
    const docSnap = await getDoc(docRef);

    const funnelsRef = query(collection(db, "funnels"), where("mediaBuyer", "==", docSnap.data().employeeID.toString()))
    const funnelsIDs = await getDocs(funnelsRef);
    funnelsIDs.forEach((doc) => {
      funnelList.push({funnelName: doc.data().funnelName, lowercaseFunnelName: ((doc.data().funnelName).toLowerCase()), funnelID: doc.data().funnelID});
      setFunnelListState(funnelList);
    })//END OF FOR EACH 
  }// END OF ASYNC GET FUNNELS FUNCTION

useEffect(() => {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      //console.log(userType)
      if (userType == "Media Buyer") {
        getMediaBuyerFunnels(user.uid);
      } else {
        getFunnels();
      }
    } 
  });



  //removes suggestions from screen when clicked away from
  document.addEventListener("click", handleClickOutside, true)
}, [])




useEffect(() => {
  //matchingFunnels = []
  for (let i = 0; i < funnelListState.length; i++ ) {
    if (funnelListState[i].lowercaseFunnelName.includes((searchValue.toLowerCase()))) {
      matchingFunnels.push({funnelName: funnelListState[i].funnelName, lowercaseFunnelName: (funnelListState[i].funnelName).toLowerCase(), funnelID: funnelListState[i].funnelID})
      
    }
  }

  
  setMatchingFunnelsState(matchingFunnels)
}, [searchValue])


function updateSearchValue (event) {
  setSearchValue(event.target.value);
  handleClick(event)
}


function search(b) {
  setSelectedFunnel(b.funnelID)
}


useEffect(() => {
  document.addEventListener("click", handleClickOutside, true)
}, [])

const refOne = useRef(null)

const handleClickOutside = (e) => {
  if(!refOne.current.contains(e.target)) {
    setMatchingFunnelsState([])  }
}

  return (
    <>
      <OutlinedInput color="success" placeholder='Search Funnels' value={searchValue}  size='small' onChange={event => updateSearchValue(event)} onClick={handleClick} endAdornment={<InputAdornment position="end"><SearchIcon/></InputAdornment>}></OutlinedInput> 
        

      <div ref={refOne} className='navbarSearchDropdown'>
      {matchingFunnelsState.map((a) => {
        return (
          <div key={a.funnelID} className='navbarSearchDropdownElementContainer'>
          <Link to="/Accounting">
            <div className='navbarSearchDropdownElement' onClick={() => search(a)}>
              {a.funnelName} 
            </div>
          </Link>
          </div>
        )
      })
      }
      </div>
    </>

  );
}
