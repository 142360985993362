
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import React, {useState, setState, state, useEffect, useContext} from 'react';
import {db} from './FirebaseInit';
import {collection, getDocs, query, where, doc, getDoc} from "firebase/firestore";
import './TopFunnels.css';
import {FunnelContext} from '../Context';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';
import { Checkbox } from '@mui/material';
import Button from '@mui/material/Button';
import {UserTypeContext} from "../Context";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Tooltip from '@mui/material/Tooltip';
const axios = require("axios");



function AnalyticsFunnelList(props) {
   
  const [allData, setAllData] = useState([]);

  async function getData() {
    var dataArray = [];

    var config = {
      method: 'get',
      url: 'https://us-central1-sendwell-profit-tracker.cloudfunctions.net/analyticsFunnelList?beginningDate=' + props.beginningDate + '&endingDate=' + props.endingDate,
    };

    dataArray = await axios(config);
    dataArray = dataArray.data;
    console.log(dataArray)
    setAllData(dataArray);

    var selectedFunnelsArray = [];
    for (let i = 0; i < dataArray.length; i++) {
      selectedFunnelsArray.push(dataArray[i].funnelID)
    }
    props.setSelectedFunnels(selectedFunnelsArray)
  }

  console.log(props.selectedFunnels)

  useEffect(() => {
    getData()
  }, [props.beginningDate + props.endingDate])


  return (
    <>
    <TableContainer component={Paper}>
      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {/*<TableCell><Checkbox checked={selectAll} onChange={() => selectAllFunnelsCheckbox()}/></TableCell>*/}
            <TableCell>Funnel Name</TableCell>
            <TableCell>Orders</TableCell>
            <TableCell>Revenue</TableCell>
            <TableCell>Advertising</TableCell>
            <TableCell>Product Cost</TableCell>
            <TableCell>Fees</TableCell>
            <TableCell>CPC</TableCell>
            <TableCell>ROAS</TableCell>
            <TableCell>Profit/Loss</TableCell>
            <TableCell>Subscribers</TableCell>
            <TableCell>CPA</TableCell>
            <TableCell>Subscription Rate</TableCell>
            <TableCell>CPS</TableCell>
            <TableCell>AOV</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allData.map((a) => (
            <TableRow key={a.funnelID}> 
              {/*<TableCell><Checkbox checked={props.selectedFunnels.includes(a.funnelID) ? true : false} onChange={() => addFunnelIdToList(a.funnelID)}/></TableCell>*/}
              <TableCell>{a.funnelName}</TableCell>
              <TableCell>{new Intl.NumberFormat().format(a.orders)}</TableCell>
              <TableCell>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.revenue)}</TableCell>
              <TableCell>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.adSpend)}</TableCell>
              <TableCell>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.cogs)}</TableCell>
              <TableCell>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.fees)}</TableCell>
              <TableCell>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.cpc)}</TableCell>
              <TableCell>{new Intl.NumberFormat().format(a.roas)}</TableCell>
              <TableCell>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.total)}</TableCell>
              <TableCell>{new Intl.NumberFormat().format(a.subscribers)}</TableCell>
              <TableCell>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.cpa)}</TableCell>
              <TableCell>{new Intl.NumberFormat('en-US', {style: 'percent'}).format(a.subscriptionRate)}</TableCell>
              <TableCell>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.cps)}</TableCell>
              <TableCell>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.aov)}</TableCell>
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}
export default AnalyticsFunnelList; 