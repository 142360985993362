import React, {useState, setState, state, useEffect, useContext} from 'react';
import Divider from '@mui/material/Divider';
import './AccountingTabs.css';
import {db} from './FirebaseInit';
import {doc, onSnapshot, collection, updateDoc, addDoc, query, orderBy, where, getDoc, getDocs, limit} from "firebase/firestore";
import Switch from '@mui/material/Switch';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import {FunnelContext} from '../Context';
import { padding, style } from '@mui/system';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import {UserTypeContext} from "../Context";
import Tooltip from '@mui/material/Tooltip';




const AccountingTabs = (props) => {
  const {userType} = useContext(UserTypeContext);

  const [menu, setMenu] = useState('funnels');

//set the ID of the funnel to the context api state 
const {setSelectedFunnel} = useContext(FunnelContext);
//getting the ID of the slected funnel 
const {selectedFunnel} = useContext(FunnelContext);

  const [readyFunnels, setReadyFunnels] = useState([]);
  const [testingFunnels, setTestingFunnels] = useState([]);
  const [winningFunnels, setWinningFunnels] = useState([]);
  const [losingFunnels, setLosingFunnels] = useState([]);

  var readyFunnelsArr = [];
  var losingFunnelsArr = [];

 
  async function getEmployeeID () {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (userType == "Media Buyer") {
          const docRef = doc(db, "users/" + user.uid);
          const docSnap = await getDoc(docRef);

          //Winning Funnels
          onSnapshot(query(collection(db, "funnels"), where("mediaBuyer", "==", docSnap.data().employeeID.toString()), where("status", "==", 85), orderBy("funnelName")), (snapshot) => {
            setWinningFunnels(snapshot.docs.map(doc => doc.data())); 
          })
          //Testing Funnels
          onSnapshot(query(collection(db, "funnels"), where("mediaBuyer", "==", docSnap.data().employeeID.toString()), where("status", "==", 68), orderBy("funnelName")), (snapshot) => {
            setTestingFunnels(snapshot.docs.map(doc => doc.data())); 
          })
          //Losing Funnels
          const losingFunnelsRef = query(collection(db, "funnels/"), where("mediaBuyer", "==", docSnap.data().employeeID.toString()), where("status", "==", 100), orderBy("funnelName"))
          const losingFunnelsSnap = await getDocs(losingFunnelsRef);
          losingFunnelsSnap.forEach((docs) => {
            losingFunnelsArr.push(docs.data()) 
          })
          setLosingFunnels(losingFunnelsArr);
          //Ready Funnels
          const readyFunnelsRef = query(collection(db, "funnels/"), where("mediaBuyer", "==", docSnap.data().employeeID.toString()), where("status", "==", 51), orderBy("funnelName"))
          const readyFunnelsSnap = await getDocs(readyFunnelsRef);
          readyFunnelsSnap.forEach((docs) => {
            readyFunnelsArr.push(docs.data()) 
          })
          setReadyFunnels(readyFunnelsArr);

          
        } else {
          //Winning Funnels
          onSnapshot(query(collection(db, "funnels"), where("status", "==", 85), orderBy("funnelName")), (snapshot) => {
            setWinningFunnels(snapshot.docs.map(doc => doc.data())); 
          })
          //Testing Funnels
          onSnapshot(query(collection(db, "funnels"), where("status", "==", 68), orderBy("funnelName")), (snapshot) => {
            setTestingFunnels(snapshot.docs.map(doc => doc.data())); 
          })
          //Losing Funnels
          const losingFunnelsRef = query(collection(db, "funnels/"), where("status", "==", 100), orderBy("funnelName"))
          const losingFunnelsSnap = await getDocs(losingFunnelsRef);
          losingFunnelsSnap.forEach((docs) => {
            losingFunnelsArr.push(docs.data()) 
          })
          setLosingFunnels(losingFunnelsArr);
          //Ready Funnels
          const readyFunnelsRef = query(collection(db, "funnels/"), where("status", "==", 51), orderBy("funnelName"))
          const readyFunnelsSnap = await getDocs(readyFunnelsRef);
          readyFunnelsSnap.forEach((docs) => {
            readyFunnelsArr.push(docs.data()) 
          })
          setReadyFunnels(readyFunnelsArr);
        }
      }
    })
  }
  useEffect(() => {
    getEmployeeID()
  }, [])

   

  function signOutUser() {
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }
 


  return (<div className='AccountingTabsMainContainer'>



    <div className='AccountingTabsContainer'> 
    <div className="accountingDrawerTitleContainer">
        <span className="accountingDrawerTitle">Sendwell Stats</span>
      </div>
    <div className='productPageNavigationTabsContainer'>
      <div className={menu == 'nav' ? 'activeProductPageNavigationTabs' : 'productPageNavigationTabs' } onClick={() => {setMenu('nav')}}>
        <span className='productPageNavigationTabsText'>Navigation</span>
      </div>
      <div className={menu == 'funnels' ? 'activeProductPageNavigationTabs' : 'productPageNavigationTabs' } onClick={() => {setMenu('funnels')}}>
        <span className='productPageNavigationTabsText'>Funnels</span>
      </div>
    </div>

    {menu == 'funnels' ? <>

<div className="accountingTabSectionTitle">
  <span>Winning</span>
</div>
{winningFunnels.map((funnel) =>  
<div key={funnel.funnelID} className="accountingDetailsTabContainer">
  <div className={selectedFunnel == funnel.funnelID ? "activeAccountingDetailsTab" : "accountingDetailsTab"} onClick={() => setSelectedFunnel(funnel.funnelID)} >
      <div className='tabLeftCol'>
          <span className='productName'>{funnel.funnelName}</span>
      </div>
      <div className='tabRightCol'>
          {funnel.sevenDayCpa > 0 ?
            (<span className="accountingTabsDetailsText" >{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(funnel.sevenDayCpa)} CPA</span>):
            funnel.sevenDayCpa == undefined || funnel.sevenDayCpa == -Infinity? <></>:
              (<span className="accountingTabsDetailsText" >{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(funnel.sevenDayCpa)} CPA</span>)
          }<br/>
          {funnel.sevenDayOrders == undefined || funnel.sevenDayOrders == 0 ? <></>:
            (<span className='accountingTabsDetailsText' >{new Intl.NumberFormat().format(funnel.sevenDayOrders)} Orders</span>)}
      </div>
  </div>
</div>
)}



<div className="accountingTabSectionTitle">
  <span>Testing</span>
</div>
{testingFunnels.map((funnel) =>  
<div key={funnel.funnelID} className="accountingDetailsTabContainer">
  <div className={selectedFunnel == funnel.funnelID ? "activeAccountingDetailsTab" : "accountingDetailsTab"} onClick={() => setSelectedFunnel(funnel.funnelID)} >
      <div className='tabLeftCol'>
          <span className='productName'>{funnel.funnelName}</span>
      </div>
      <div className='tabRightCol'>
          {funnel.sevenDayCpa > 0 ?
            (<span className="accountingTabsDetailsText" >{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(funnel.sevenDayCpa)} CPA</span>):
            funnel.sevenDayCpa == undefined || funnel.sevenDayCpa == -Infinity? <></>:
              (<span className="accountingTabsDetailsText" >{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(funnel.sevenDayCpa)} CPA</span>)
          }<br/>
          {funnel.sevenDayOrders == undefined || funnel.sevenDayOrders == 0 ? <></>:
            (<span className='accountingTabsDetailsText' >{new Intl.NumberFormat().format(funnel.sevenDayOrders)} Orders</span>)}
      </div>
  </div>
</div>
)}


<div className="accountingTabSectionTitle">
  <span>Losing</span>
</div>
{losingFunnels.map((funnel) =>  
<div key={funnel.funnelID} className="accountingDetailsTabContainer">
  <div className={selectedFunnel == funnel.funnelID ? "activeAccountingDetailsTab" : "accountingDetailsTab"} onClick={() => setSelectedFunnel(funnel.funnelID)} >
      <div className='tabLeftCol'>
          <span className='productName'>{funnel.funnelName}</span>
      </div>

  </div>
</div>
)}



<div className="accountingTabSectionTitle">
  <span>Ready</span>
</div>
{readyFunnels.map((funnel) =>  
<div key={funnel.funnelID} className="accountingDetailsTabContainer">
  <div className={selectedFunnel == funnel.funnelID ? "activeAccountingDetailsTab" : "accountingDetailsTab"} onClick={() => setSelectedFunnel(funnel.funnelID)} >
      <div className='tabLeftCol'>
          <span className='productName'>{funnel.funnelName}</span>
      </div>
  </div>
</div>
)}

</>:<>
<div className="accountingDrawer">


      <div>
        <div className="accountingDrawerSectionTitle">
          <span>General</span>
        </div>
        <div className="accountingDrawerSection">
          <a href="/Dashboard" className="accountingDrawerLinkTitle">Dashboard</a>
        </div>
        <div className="accountingDrawerSection">
          <a href="/ProductIdeas" className="accountingDrawerLinkTitle">Product Ideas</a>
        </div>

        <div className="accountingDrawerSectionTitle">
          <span>Finances</span>
        </div>
        <div className="activeDashboardDrawerSection">
          <a href="/ProductPage" className="activeDashboardDrawerLinkTitle">Accounting</a>
        </div>
        <div className="accountingDrawerSection">
          <a href="/AccountBalances" className="accountingDrawerLinkTitle">Account Balances</a>
        </div>
        <div className="accountingDrawerSection">
          <a href="/Analytics" className="accountingDrawerLinkTitle">Analytics</a>
        </div>
        <div className="accountingDrawerSection">
          <a href="/Commission" className="accountingDrawerLinkTitle">Commission</a>
        </div>

        <div className="accountingDrawerSectionTitle">
          <span>Management</span>
        </div>
        <div className="accountingDrawerSection">
          <a href="/UserSettings" className="accountingDrawerLinkTitle">User Settings</a>
        </div>
        <div className="accountingDrawerSection">
          <a href="/UserPermissions" className="accountingDrawerLinkTitle">User Permissions</a>
        </div>
        <div className="accountingDrawerSection">
          <a href="/BusinessSettings" className="accountingDrawerLinkTitle">Business Settings</a>
        </div>

        <div className="dashboardDrawerSectionTitle">
          <span></span>
        </div>
        <div className="dashboardDrawerSectionSignOut" onClick={() => signOutUser()}>
          <span className="dashboardDrawerLinkTitleSignOut">Sign Out</span>
        </div>
      </div>

    </div>
</>}

</div>



  </div>);
}

export default AccountingTabs;
