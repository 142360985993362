import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import './FunnelFees.css';
import InputAdornment from '@mui/material/InputAdornment';



function FunnelFees({addCashback, setAddCashback, addMerchantFees, setAddMerchantFees, existingCashbackOnAds, setExistingCashbackOnAds, existingMerchantFees, setExistingMerchantFees, addMerchantFeesPerOrder, setAddMerchantFeesPerOrder, existingMerchantFeesPerOrder, setExistingMerchantFeesPerOrder}) {

  const [cashbackValue, setCashbackValue] = React.useState(existingCashbackOnAds);
  const handleCashbackChange = (event) => {
    setCashbackValue(event.target.value);
    setAddCashback(event.target.value);
    console.log(event.target.value)
    };

  const [cashbackPresetValue, setCashbackPresetValue] = React.useState(cashbackValue);
  const handleCashbackPresetChange = (event) => {
    setCashbackPresetValue(event.target.value);
    setCashbackValue(event.target.value);
    setAddCashback(event.target.value);
    //console.log(event.target.value)
    };

  const [merchantFeesValue, setMerchantFeesValue] = React.useState(existingMerchantFees);
  const handleMerchantFeesChange = (event) => {
    setMerchantFeesValue(event.target.value);
    setAddMerchantFees(event.target.value);
    //console.log(event.target.value)
    };

  const [merchantFeesPerOrderValue, setMerchantFeesPerOrderValue] = React.useState(existingMerchantFeesPerOrder);
  const handleMerchantFeesPerOrderChange = (event) => {
    setMerchantFeesPresetValue(null);
    setMerchantFeesPerOrderValue(event.target.value); 
    setAddMerchantFeesPerOrder(event.target.value);
    };


  const [merchantFeesPresetValue, setMerchantFeesPresetValue] = React.useState(merchantFeesValue);
  const handleMerchantFeesPresetChange = (event) => {
    setMerchantFeesPresetValue(event.target.value);
    setMerchantFeesValue(event.target.value);
    setAddMerchantFees(event.target.value);
    //console.log(event.target.value)
    };


  useEffect(() => {
    setAddCashback(existingCashbackOnAds);
    setAddMerchantFees(existingMerchantFees);
    setAddMerchantFeesPerOrder(existingMerchantFeesPerOrder)
  }, [])


    return ( 
        <>
        <br/><span>Adjustments:</span><br/><br/>
        <table className='FunnelFeesTextboxTable'>
          <tbody>
           <tr>
            

            <td className='FunnelFeesTextboxColWidth'>
            <TextField label="Ad Discounts" value={cashbackValue} onChange={handleCashbackChange} type="number" InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}/>
            </td>
            <td className='FunnelFeesTextboxColWidth'>
              <TextField label="Revenue %" value={merchantFeesValue} type="number" onChange={handleMerchantFeesChange} InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}/>
            </td>
            <td className='FunnelFeesTextboxColWidth'>
              <TextField label="Revenue Fees / Order" value={merchantFeesPerOrderValue} type="number" onChange={handleMerchantFeesPerOrderChange} InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}/>
            </td>
           </tr>
   
    <tr>
      <td>
      <FormControl>
      <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={cashbackValue} onChange={handleCashbackPresetChange}>
      <Tooltip title="3% Fee" placement="right">
        <FormControlLabel value={-3} control={<Radio />} label="Scalinfinity" />
      </Tooltip>
      <Tooltip title=".5% Fee" placement="right">
        <FormControlLabel value={-.5} control={<Radio />} label="Httpool" />
      </Tooltip>
      <Tooltip title="1.5% Savings" placement="right">
        <FormControlLabel value={1.5} control={<Radio />} label="Credit Card" />
      </Tooltip>
      </RadioGroup>
    </FormControl>
      </td>
      <td>
      <FormControl>
      <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={merchantFeesValue} onChange={handleMerchantFeesPresetChange}>
      <Tooltip title="4% Fee" placement="right">
        <FormControlLabel value={-4} control={<Radio />} label="Standard Fees" />
      </Tooltip>
      </RadioGroup>
    </FormControl>
      </td>
    </tr>
  </tbody>
</table>

<i>Note: Negative adjustments are additional costs</i>


        </>
     )
}

export default FunnelFees;