import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { db } from '../FirebaseInit';
import { doc, getDoc, collection, onSnapshot } from "firebase/firestore";
import { MenuItem } from '@mui/material';

function FunnelTikTokBM({addTikTokBusinessManager, setAddTikTokBusinessManager, existingTikTokBM}) {


useEffect(() => {
  setAddTikTokBusinessManager(existingTikTokBM);
}, [])


    function AddBusinessManagerText () {
        if (addTikTokBusinessManager === "addNew"){
          return <TextField autoFocus margin="dense" id="name" label="Add New Business Manager" type="text" fullWidth variant="standard"/>
        }
      }

      //GETTING ALL BUSINESS MANAGERS
const [getTikTokBM, setGetTikTokBM] = useState([]);

useEffect(() => {
  //this code fires when the app loads
  onSnapshot(collection(db, "TikTokBMs"), (snapshot) => {
    setGetTikTokBM(snapshot.docs.map(doc => doc.data()));
  }
)}, [])



const [value, setValue] = React.useState(existingTikTokBM);

   


//TikTok page dropdown

const handleTikTokBMChange = (event) => {
setValue(event.target.value);
setAddTikTokBusinessManager(event.target.value);
};

    return ( 
      
      <>
      <FormControl sx={{ minWidth: "100%" }} size="small">
      <InputLabel id="demo-select-small">TikTok BMs</InputLabel>
      <Select labelId="demo-select-small" id="demo-select-small" value={value} label="TikTok BMs" onChange={handleTikTokBMChange} >
      {getTikTokBM.map((TikTokBM) =>
        <MenuItem key={TikTokBM.id} value={TikTokBM.id}>{TikTokBM.name}</MenuItem>
      )}
      </Select>
      </FormControl>
      </>
     )
}

export default FunnelTikTokBM;