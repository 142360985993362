import React, { useEffect, useState } from 'react';
import Navbar from './Pages/Navbar';
import Dashboard from './Pages/Dashboard';
import Login from './Pages/Login';
import { BrowserRouter, Routes, Route, useLocation, Navigate, redirect, useNavigate } from 'react-router-dom';
import {doc, onSnapshot, collection, setDoc, addDoc, updateDoc, getDoc, where, query, getDocs, Timestamp} from "firebase/firestore";
import { db } from './Pages/FirebaseInit';
import ProductPage from './Pages/ProductPage';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Business from './Pages/Business';
import Notes from './Pages/Notes';
import {FunnelContext, UserTypeContext} from './Context';
import AccountBalances from './Pages/AccountBalances';
import ProductIdeas from './Pages/ProductIdeas';
import Commission from './Pages/Commission';
import Analytics from './Pages/Analytics';
import AccountingDetails from './Pages/AccountingDetails';
import BusinessSettings from './Pages/BusinessSettings';
import UserPermissions from './Pages/UserPermissions';
import UserSettings from './Pages/UserSettings';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {blue, yellow, red} from '@mui/material/colors';
const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });



function App() {
  const theme = createTheme({
    palette: {
        primary: createColor('#00ab55'),
        secondary: createColor('#00ab55'),
        success: createColor('#00ab55')
    },
    typography: {
      "fontFamily": `'Varela Round', sans-serif`,
    }
});
 
  const [authenticated, setAuthenticated] = useState(true);
  const [selectedFunnel, setSelectedFunnel] = useState('a');
  const [userType, setUserType] = useState('')

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        setAuthenticated(true);
        const uid = user.uid;
        console.log("user is signed in " + uid);


        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);
        setUserType(docSnap.data().employeeRole)
        
        // ...
      } else {
        // User is signed out
        setAuthenticated(false);
        console.log("no users are signed in");
        // ...
      }
    });
  }, []) 


 
 
 
  
 


  


  return (
    <div>
      <ThemeProvider theme={theme}>
        <UserTypeContext.Provider value = {{userType, setUserType}} >
          <FunnelContext.Provider value = {{selectedFunnel, setSelectedFunnel}} >
            <BrowserRouter>
            
              <Routes>

                <Route path="/" element={authenticated ? <Navigate to="/Dashboard"/> : <Login />} />
                <Route path="/Login" element={authenticated ? <Navigate to="/Dashboard"/> : <Login />} />
                <Route path='/ProductPage' element={authenticated ? <ProductPage/> : <Navigate to="/Login"/>} />
                <Route path='/Business' element={authenticated ? <Business/> : <Navigate to="/Login"/>} />
                <Route path="/Dashboard" element={authenticated ? <Dashboard/> : <Navigate to="/Login"/>} />
                <Route path="/Notes" element={authenticated ? <Notes/> : <Navigate to="/Login"/>} />
                <Route path="/AccountBalances" element={authenticated ? <AccountBalances/> : <Navigate to="/Login"/>} />
                <Route path="/ProductIdeas" element={authenticated ? <ProductIdeas/> : <Navigate to="/Login"/>} />
                <Route path="/Commission" element={authenticated ? <Commission/> : <Navigate to="/Login"/>} />
                <Route path="/Analytics" element={authenticated ? <Analytics/> : <Navigate to="/Login"/>} />
                <Route path='/Accounting' element={authenticated ? <AccountingDetails/> : <Navigate to="/Login"/>} />
                <Route path='/BusinessSettings' element={authenticated ? <BusinessSettings/> : <Navigate to="/Login"/>} />
                <Route path='/UserPermissions' element={authenticated ? <UserPermissions/> : <Navigate to="/Login"/>} />
                <Route path='/UserSettings' element={authenticated ? <UserSettings/> : <Navigate to="/Login"/>} />

              </Routes>
          
            </BrowserRouter>
          </FunnelContext.Provider>
        </UserTypeContext.Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
