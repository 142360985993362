
import React, {useState, useEffect, useContext} from "react";
import ReactDOM from 'react-dom';
import { Outlet, Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import OrdersChart from './Charts/OrdersChart'
import ProfitChart from './Charts/ProfitChart';
import CpaChart from "./Charts/CpaChart";
import SubscribersChart from "./Charts/SubscribersChart";
import PopularProducts from './PopularProducts';
import Navbar from './Navbar';
import { db } from './FirebaseInit';
import {doc, onSnapshot, collection, setDoc, addDoc, updateDoc, getDoc, where, query, getDocs, Timestamp} from "firebase/firestore";
import FunnelStatistics from "./FunnelStatistics";
import BusinessStatistics from "./BusinessStatistics";
import {UserTypeContext} from "../Context";
import './Dashboard.css';
import TopFunnels from "./TopFunnels";
import DashboardMediaBuyers from "./DashboardMediaBuyers";
import DashboardBarGraph from "./DashboardBarGraph";
import TopFunnelsDuplicate from "./TopFunnelsDuplicate";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import NavbarSearch from "./NavbarSearch";
import SalesByDayOfWeek from "./DashboardSalesByDayOfWeek";
import MediaBuyersDoughnut from "./DashboardMediaBuyersDoughnut.js";
import SubscriptionRevenue from "./DashboardSubscriptionRevenue";
const axios = require("axios");
var moment = require('moment'); 




function Dashboard() {

const [subscribers, setSubscribers] = useState(0)
const [profit, setProfit] = useState(0)

const {userType} = useContext(UserTypeContext);

const [usersName, setUsersName] = useState('');

const today = () => {
  var m = moment();    
  var s = m.format();
  return s.split('T')[0];
};

const yesterday = () => {
  var m = moment().subtract(1, 'days');    
  var s = m.format();
  return s.split('T')[0];
};

const sevenDaysAgo = () => {
  var m = moment().subtract(8, 'days');    
  var s = m.format();
  return s.split('T')[0];
};

const thirtyDaysAgo = () => {
  var m = moment().subtract(30, 'days');    
  var s = m.format();
  return s.split('T')[0];
};

  //BEGINNING DATE PICKER
  const [beginningDate, setBeginningDate] = useState(yesterday);
  //ENDING DATE PICKER
  const [endingDate, setEndingDate] = useState(today);



//FOR NEW USER ASKING WHICH EMPLOYEE THEY ARE

var unactivatedEmployees = [];
const [uidState, setUidState] = useState('');
const [unactivatedEmployeesState, setUnactivatedEmployees] = useState([])
const [selectedEmployee, setSelectedEmployee] = useState('');

useEffect(() => {



const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (user) {
    setUidState(user.uid);
    findUser(user.uid);
  } 
});

async function findUser(uid) {
  const docRef = doc(db, "users", uid);
  const docSnap = await getDoc(docRef);

  if(docSnap.data() == undefined) {
    console.log("No user tree found in DB");
    getEmployees(uid);
    setOpen(true)
  } else {
    setOpen(false);
  }
  //console.log(docSnap.data())
  setUsersName(docSnap.data().employeeName)
}



async function getEmployees() {
  const q = query(collection(db, "employees"), where("status", "==", "notActivated"));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    unactivatedEmployees.push({employeeName: doc.data().employeeName, employeeRole: doc.data().employeeRole, employeeID: doc.data().id})
    setUnactivatedEmployees(unactivatedEmployees);
  });
}



}, [])

const [open, setOpen] = React.useState(false);


const handleEmployeeSelectionAndClose = () => {
  updateEmployeeAssignment();
  findUser(uidState);
}

async function findUser(uid) {
  const docRef = doc(db, "users", uid);
  const docSnap = await getDoc(docRef);

  if(docSnap.data() != undefined) {
    setOpen(false);
  }
  
}

async function updateEmployeeAssignment () {
  console.log(uidState)
  const employeeRef = doc(db, "employees", selectedEmployee.toString());
  await updateDoc(employeeRef, {userID: uidState.toString(), status: "activated"});

  const docRef = doc(db, "employees", selectedEmployee.toString());
  const docSnap = await getDoc(docRef);

  const userRef = doc(db, "users", uidState);
  await setDoc(userRef, {employeeID: selectedEmployee, status: "activated", employeeRole: docSnap.data().employeeRole, employeeName: docSnap.data().employeeName, createdAt: Timestamp.fromDate(new Date())});
}


const handleEmployeeSelect = (event) => {
  setSelectedEmployee((event.target.value));
};


function DisplayLoader() {
  if (userType == "Media Buyer" || userType == "Accounting" || userType == "Administrative") {
    return (<></>)
  } else if (userType == "Customer Service"){
    return (
      <>
        <br/><center>Your account type is {userType} so you are not permitted to access this page. Please contact an administrator if you need administrative priviledges.</center>
      </>
    );
  }
}


const [todayLoading, setTodayLoading] = useState(false);
async function updateCurrentDayStats() {
  setTodayLoading(true);
  const config = {
    method: 'get',
    url: 'https://us-central1-sendwell-profit-tracker.cloudfunctions.net/updateStatsImmediately',
  };

  const response = await axios(config);

  if (response.data == 'stats update complete') {
    //setLoading(false);
    setTimeout((window.location.reload(false)), 1000)
  }
}


function signOutUser() {
  const auth = getAuth();
  signOut(auth).then(() => {
    // Sign-out successful.
  }).catch((error) => {
    // An error happened.
  });
}


useEffect(() => {
  getMediaBuyers()
}, [userType])




const [mediaBuyersList, setMediaBuyersList] = useState([]);

async function getMediaBuyers() { 
  if (await userType == "Accounting" || await userType == "Administrative") {
  const mediaBuyers = []

  const querySnapshot = await getDocs(query(collection(db, "employees"), where("employeeRole", "==", "Media Buyer"), where("status", "!=", "removed")));
  querySnapshot.forEach((doc) => {
    mediaBuyers.push({employeeName: doc.data().employeeName, employeeRole: doc.data().employeeRole, employeeID: doc.data().id})
  });
  setMediaBuyersList(mediaBuyers) 

  }
}

const [mediaBuyer, setMediaBuyer] = useState('all')







const [datePreset, setDatePreset] = useState('today')

useEffect(() => {
  if (datePreset == 'today') {
    setBeginningDate(today())
    setEndingDate(today())
  } else if (datePreset == 'yesterday') {
    setBeginningDate(yesterday())
    setEndingDate(yesterday())
  } else if (datePreset == 'last7') {
    setBeginningDate(sevenDaysAgo())
    setEndingDate(yesterday())
  } else if (datePreset == 'last30') {
    setBeginningDate(thirtyDaysAgo())
    setEndingDate(yesterday())
  }
}, [datePreset]) 

useEffect(() => {
  if (beginningDate == today() && endingDate == today()) {
    setDatePreset('today')
  } else if (beginningDate == yesterday() && endingDate == yesterday() && datePreset) {
    setDatePreset('yesterday')
  } else if (beginningDate == sevenDaysAgo() && endingDate == yesterday() && datePreset) {
    setDatePreset('last7')
  } else if (beginningDate == thirtyDaysAgo() && endingDate == yesterday() && datePreset) {
    setDatePreset('last30')
  } else {
    setDatePreset('custom')
  }
}, [beginningDate + endingDate])

  return (

  <div className="dashboardMainContainer">

    <div className="dashboardDrawer">
      <div className="dashboardDrawerTitleContainer">
        <span className="dashboardDrawerTitle">Sendwell Stats</span>
      </div>

      <div className="dashboardDrawerUserSection">
        <div className="dashboardDrawerUserInformation">
          <span className="dashboardDrawerUserName">{usersName}</span>
          <span className="dashboardDrawerUserPriveledges">{userType}</span>
        </div>
      </div>

      <div>
        <div className="dashboardDrawerSectionTitle">
          <span>General</span>
        </div>
        <div className="activeDashboardDrawerSection">
          <span className="activeDashboardDrawerLinkTitle">Dashboard</span>
        </div>
        <div className="dashboardDrawerSection">
          <a href="/ProductIdeas" className="dashboardDrawerLinkTitle">Product Ideas</a>
        </div>

        <div className="dashboardDrawerSectionTitle">
          <span>Finances</span>
        </div>
        <div className="dashboardDrawerSection">
          <a href="/Accounting" className="dashboardDrawerLinkTitle">Accounting</a>
        </div>
        <div className="dashboardDrawerSection">
          <a href="/AccountBalances" className="dashboardDrawerLinkTitle">Account Balances</a>
        </div>
        <div className="dashboardDrawerSection">
          <a href="/Analytics" className="dashboardDrawerLinkTitle">Analytics</a>
        </div>
        <div className="dashboardDrawerSection">
          <a href="/Commission" className="dashboardDrawerLinkTitle">Commission</a>
        </div>

        <div className="dashboardDrawerSectionTitle">
          <span>Management</span>
        </div>
        <div className="dashboardDrawerSection">
          <a href="/UserSettings" className="dashboardDrawerLinkTitle">User Settings</a>
        </div>
        <div className="dashboardDrawerSection">
          <a href="/UserPermissions" className="dashboardDrawerLinkTitle">User Permissions</a>
        </div>
        <div className="dashboardDrawerSection">
          <a href="/BusinessSettings" className="dashboardDrawerLinkTitle">Business Settings</a>
        </div>

        <div className="dashboardDrawerSectionTitle">
          <span></span>
        </div>
        <div className="dashboardDrawerSectionSignOut" onClick={() => signOutUser()}>
          <span className="dashboardDrawerLinkTitleSignOut">Sign Out</span>
        </div>
      </div>

      

    </div>

    <div className="dashboardBackground ">
   
   
{//      <Navbar date={true} beginningDate={beginningDate} endingDate={endingDate} onBeginningDateChange={handleBeginningDateChange} onEndingDateChange={handleEndingDateChange} pageTitle="DASHBOARD" todayRefresh={true} />
}    

      <div className="dashboardNavbarContainer">
        <div className="dashboardFunnelSearch">
          <NavbarSearch/>
        </div>

        {userType == "Accounting" || userType == "Administrative" ? <>
          <div className="dashboardNavbarMediaBuyerDropdownContainer">
          <FormControl size="small">
            <InputLabel>Media Buyer</InputLabel>
            <Select value={mediaBuyer} label="Media Buyer" onChange={(event) => setMediaBuyer(event.target.value)}>
              <MenuItem value={'all'}>All Media Buyers</MenuItem>
              {mediaBuyersList.map((a) => {return (
                <MenuItem key={a.employeeID} value={a.employeeID}>{a.employeeName}</MenuItem>
              )})}
            </Select> 
          </FormControl>
          </div>
        </>:<></>}

        <div className="dashboardNavbarDatepresetDropdownContainer">
          <FormControl size="small" sx={{minWidth: '143px'}}>
            <InputLabel>Date Preset</InputLabel>
            <Select value={datePreset} label="Date Preset" onChange={(event) => setDatePreset(event.target.value)}>
              <MenuItem value='custom'>Custom</MenuItem>
              <MenuItem value='today'>Today</MenuItem>
              <MenuItem value='yesterday'>Yesterday</MenuItem>
              <MenuItem value='last7'>Last 7 Days</MenuItem>
              <MenuItem value='last30'>Last 30 Days</MenuItem>
            </Select> 
          </FormControl>
        </div>
        <div className="dashboardDateSelectors">
            <TextField label="Beginning Date" color="success" value={beginningDate} onChange={(event) => {setBeginningDate(event.target.value)}} type="date" InputLabelProps={{ shrink: true,}} size='small'/>
            <TextField label="Ending Date" color="success" value={endingDate} onChange={(event) => {setEndingDate(event.target.value)}} type="date" InputLabelProps={{ shrink: true,}} size='small'/>
        </div>
        <div className="dashboardRefreshButtonContainer">
          <span className="dashboardRefreshButton" onClick={() => {updateCurrentDayStats()}}>{todayLoading == true ? <>Updating</> : <>Refresh</>}</span>
        </div>
      </div>

      {userType == "Media Buyer" || userType == "Accounting" || userType == "Administrative" ? <>
        <div className="dashboardChartsRow1">
          <div className="dashboardChart1"><OrdersChart beginningDate={beginningDate} endingDate={endingDate} uidState={uidState} mediaBuyer={mediaBuyer}/></div>
          <div className="dashboardChart2"><ProfitChart beginningDate={beginningDate} endingDate={endingDate} uidState={uidState} mediaBuyer={mediaBuyer} profit={profit} setProfit={setProfit}/></div>
          <div className="dashboardChart3"><CpaChart beginningDate={beginningDate} endingDate={endingDate} uidState={uidState} mediaBuyer={mediaBuyer}/></div>
          <div className="dashboardChart4"><SubscribersChart beginningDate={beginningDate} endingDate={endingDate} uidState={uidState} mediaBuyer={mediaBuyer} subscribers={subscribers} setSubscribers={setSubscribers}/></div>
        </div>


        <div className="dashboardChartsRow2">
          <div className="dashboardChartsRow2a"><TopFunnels beginningDate={beginningDate} endingDate={endingDate} uidState={uidState} mediaBuyer={mediaBuyer}/></div>
          <div className="dashboardChartsRow2b"><DashboardMediaBuyers beginningDate={beginningDate} endingDate={endingDate} uidState={uidState}/></div>
        </div>
           
        <div className="dashboardChartsRow3">
          <div className="dashboardChartsRow3a"><SalesByDayOfWeek uidState={uidState}/></div>
          {/* <div className="dashboardChartsRow3b"><MediaBuyersDoughnut beginningDate={beginningDate} endingDate={endingDate} uidState={uidState}/></div>*/}
          {userType == "Administrative" || userType == "Accounting" ? 
          <div className="dashboardChartsRow3b"><SubscriptionRevenue beginningDate={beginningDate} endingDate={endingDate} uidState={uidState} profit={profit} subscribers={subscribers} /></div>
          :<></>}
          </div>  
          <div className="dashboardChartsRow3c"></div>         

          <br/><br/><br/><br/>

           

           </>: <><br/><center><CircularProgress /></center><br/><center><DisplayLoader /></center></>}


          <div>
           <Dialog open={open}>
             <DialogTitle>Who are you?</DialogTitle>
             <DialogContent>
               <DialogContentText>
                In order to be shown the correct information, please find yourself and click select. If you don't see yourself, please contact an administrator.
               </DialogContentText><br/>
                <FormControl fullWidth>



                  <InputLabel id="demo-simple-select-label">Employee Name (Role)</InputLabel>
                  <Select labelId="demo-simple-select-label" id="demo-simple-select" value={selectedEmployee} label="Employee Name (Role)" onChange={handleEmployeeSelect}>
                    {unactivatedEmployeesState.map((a) =>
                    <MenuItem value={a.employeeID}>{a.employeeName + " (" + a.employeeRole + ")"}</MenuItem>
                    )}
                  </Select>
                </FormControl>
             </DialogContent>
             <DialogActions>
               <Button onClick={handleEmployeeSelectionAndClose}>Select</Button>
             </DialogActions>
           </Dialog>
         </div>
         


        
                

</div>




  


    

     
    
 
</div>);
}





export default Dashboard;
