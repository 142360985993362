import React, {useState, useEffect, useContext} from "react";
import { Paper } from '@mui/material';
import './AccountingDetailsSummary.css';
import { Divider } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import {FunnelContext} from "../Context";
import {doc, onSnapshot, collection, setDoc, addDoc, updateDoc, getDoc, arrayUnion, query, where, orderBy, deleteDoc} from "firebase/firestore";
import { db } from './FirebaseInit';
import ProductPageOptions from "./ProductPageOptions";





function AccountingDetailsSummary(props) {
  const { selectedFunnel } = useContext(FunnelContext);

  const [funnelName, setFunnelName] = useState('');

  useEffect(() => {
    getFunnelName()

  }, [selectedFunnel])

  async function getFunnelName () {
   
    setFunnelName( (await getDoc(doc(db, "funnels", selectedFunnel))).data().funnelName) 
  }

  return (
    <>

<div className='productSummaryMainDiv'> 
      
      <span className='AccountingSummaryTitle'>{funnelName.toString()}</span>
    <div className="AccountingDetailsMainTableContainer">
    <table className="productSummaryMainTable">
        <tbody>
                <tr>
                    <td className="productSummaryTableHeadersContainer">
                      <div className="accountingSummaryTableSectionContainer accountingSummaryOrders">
                        <span className="accountingDetailsSummaryTableHeaders">Orders</span>
                        <span className="accountingDetailsSummaryValue">{new Intl.NumberFormat().format(props.ordersTotal)}</span>
                      </div>
                    </td>
                    <td className="productSummaryTableHeadersContainer">
                      <div className="accountingSummaryTableSectionContainer accountingSummaryRevenue">
                        <span className="accountingDetailsSummaryTableHeaders">Revenue</span>
                        <span className="accountingDetailsSummaryValue">{isNaN(props.revenueTotal) == true ? 0 : new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(props.revenueTotal)}</span>
                      </div>
                    </td>
                    <td className="productSummaryTableHeadersContainer">
                      <div className="accountingSummaryTableSectionContainer accountingSummaryAds">
                        <span className="accountingDetailsSummaryTableHeaders">Advertising</span>
                        <span className="accountingDetailsSummaryValue">{isNaN(props.adsSpendTotal) == true ? 0 : new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(props.adsSpendTotal)}</span>
                      </div>
                    </td>
                    <td className="productSummaryTableHeadersContainer">
                      <div className="accountingSummaryTableSectionContainer accountingSummaryCOGS">
                        <span className="accountingDetailsSummaryTableHeaders">Product Cost</span>
                        <span className="accountingDetailsSummaryValue">{isNaN(props.productCostTotal) == true ? 0 : new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(props.productCostTotal)}</span>
                      </div>
                    </td>
                    <td className="productSummaryTableHeadersContainer">
                      <div className="accountingSummaryTableSectionContainer accountingSummaryFees">
                        <span className="accountingDetailsSummaryTableHeaders">Fees/Credits</span>
                        <span className="accountingDetailsSummaryValue">{isNaN(props.feesTotal) == true ? 0 : new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(props.feesTotal)}</span>
                      </div>
                    </td>
                    <td className="productSummaryTableHeadersContainer">
                      <div className="accountingSummaryTableSectionContainer accountingSummaryCPC">
                        <span className="accountingDetailsSummaryTableHeaders">Cost Per Conv.</span>
                        <span className="accountingDetailsSummaryValue">{isNaN(props.CPCAverage) == true ? 0 : new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(props.CPCAverage)}</span>
                      </div>
                    </td>
                    <td className="productSummaryTableHeadersContainer">
                      <div className="accountingSummaryTableSectionContainer accountingSummaryProfit">
                        <span className="accountingDetailsSummaryTableHeaders">Profit/Loss</span>
                        <span className="accountingDetailsSummaryValue">{isNaN(props.totalTotal) == true ? 0 : new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(props.totalTotal)}</span>
                      </div>
                    </td>
                    <td className="productSummaryTableHeadersContainer">
                      <div className="accountingSummaryTableSectionContainer accountingSummarySubs">
                        <span className="accountingDetailsSummaryTableHeaders">Subscribers</span>
                        <span className="accountingDetailsSummaryValue">{isNaN(props.subscribersTotal) == true ? 0 : new Intl.NumberFormat().format(props.subscribersTotal)}</span>
                      </div>
                    </td>
                    <td className="productSummaryTableHeadersContainer">
                      <div className="accountingSummaryTableSectionContainer accountingSummaryCPA">
                        <span className="accountingDetailsSummaryTableHeaders">Cost/Acquisition</span>
                        {isNaN(props.CPATotal) == true ? 0 : <span className="accountingDetailsSummaryValue">{props.CPATotal >= 0 ?
                        (<span className="tableDataText" style={{color:"green"}}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(props.CPATotal)}</span>):
                        (<span className="tableDataText" style={{color:"red"}}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(props.CPATotal)}</span>)
                        }</span>}
                      </div>
                    </td>
                </tr>
            </tbody>


 

    </table>     
    </div>      


</div>

      
    </>
  );
}

export default AccountingDetailsSummary;