import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import {db} from '../FirebaseInit';
import {doc, collection, getDocs, getDoc} from "firebase/firestore";
import {addDoc, Timestamp, setDoc, deleteDoc } from "firebase/firestore"; 
import { getAuth, signOut } from "firebase/auth";

const axios = require('axios').default;


function BusinessAssignFBAdAccountsToMediaBuyers () {
    
const [adAccounts, setAdAccounts] = useState([]);

    //gets list of BMS from database
    async function getBMs() {

        const BMIDs = await getDocs(collection(db, "FaceBookBMs"));
        BMIDs.forEach((doc) => {
        //gets access key so system can get balances from all ad accounts on all BMS
        getAccessKey(doc.data().id);
        })
    }


    async function getAccessKey(BMID) {
        var adAccountObj = [{}];

        var AccessKeyData = await getDoc(doc(db, "FaceBookBMs/" + BMID));
        //accessKey is the system user key found in the database 
        var accessKey = AccessKeyData.data().FaceBookBMAccessKey; 
        var apiLink = "https://graph.facebook.com/v14.0/me/adaccounts?fields=name%2Caccount_id&access_token=" + accessKey;
        var response = await axios.get(apiLink);

        adAccountObj = response.data.data;
        //console.log(adAccountObj)

        for (let i = 0; i < adAccountObj.length; i++) {
            //console.log(adAccountObj[i].account_id)
            const docSnap = await getDoc(doc(db, "adAccounts/", adAccountObj[i].account_id));
            //if (docSnap.data().userID == 'DNwlApj55TZALl7sxLi6DmkLRAT2') {
                //adAccountObj[i].user = 'steve'
            //} else if (docSnap.data().userID == 'BfWbr9Vea0OuO2FerXlvaRiW5sI2') {
                //adAccountObj[i].user = 'mario'
            //}
            adAccountObj[i].user = docSnap.data().userID
            //console.log(docSnap.data().adAccountID)
            //console.log(docSnap.data().userID);
        }

        console.log(adAccountObj)

        setAdAccounts(adAccountObj)

    }
    
        //pulls the BM list from database on page load
    useEffect(() => {
        getBMs();
        getExistingSettings();
    }, [])


    async function assignMediaBuyerToAdAccount (adAccountID, userID) {
        console.log(adAccountID)
        console.log(userID)
        
        console.log(await setDoc(doc(db, "adAccounts/" + adAccountID), 
        {
            adAccountID: adAccountID,
            userID: userID,
            notes: ''
        }
        ));

    }


    async function getExistingSettings () {

 

    }

        
return (
<>
<Card>
        <CardContent>
            <table>
                <tbody>
                {adAccounts.map((x) => (
                <tr key={x.account_id}>
                        <td>
                            <span>{(x.name).substring(0,28)}</span>
                        </td>
                        <td>

                        <FormControl onChange={(event) => assignMediaBuyerToAdAccount(x.account_id, event.target.value)}>
                        <RadioGroup row defaultValue={x.user} >
                            <FormControlLabel value="DNwlApj55TZALl7sxLi6DmkLRAT2" control={<Radio />} label="Steve" />
                            <FormControlLabel value="BfWbr9Vea0OuO2FerXlvaRiW5sI2" control={<Radio />} label="Mario" />
                        </RadioGroup>
                        </FormControl>

                        </td>
                    </tr>
                ))}

                </tbody>
            </table>


        </CardContent>
    </Card>
</>
)
}

export default BusinessAssignFBAdAccountsToMediaBuyers;