
import React, {useState, useEffect, useContext} from "react";
import ReactDOM from 'react-dom';
import {doc, onSnapshot, collection, updateDoc, addDoc, query, orderBy, where, getDoc, getDocs, limit} from "firebase/firestore";
import {db} from './FirebaseInit';
import { Bar } from 'react-chartjs-2';
import './DashboardSalesByDayOfWeek.css'
import CircularProgress from '@mui/material/CircularProgress';
import {UserTypeContext} from "../Context";
const axios = require("axios");



function SalesByDayOfWeek(props) {
    const [testingFunnels, setTestingFunnels] = useState([]);
    const [winningFunnels, setWinningFunnels] = useState([]);

    const [selectedFunnel, setSelectedFunnel] = useState('');
    const [chartData, setChartData] = useState('orders');

    const {userType} = useContext(UserTypeContext);


    var salesDataArr = [];

    var daysArr = [];
    const [days, setDays] = useState([]);
    var ordersArr = [];
    const [orders, setOrders] = useState([]);
    var profitArr = [];
    const [profit, setProfit] = useState([]);
    var subscribersArr = [];
    const [subscribers, setSubscribers] = useState([]);
    var subscriptionRateArr = [];
    const [subscriptionRate, setSubscriptionRate] = useState([]);
    var cpaArr = [];
    const [cpa, setCPA] = useState([]);

    async function getData(funnelID) {
        var config = {};

        
        if (funnelID == '') {
            config = {
                method: 'get',
                url: 'https://us-central1-sendwell-profit-tracker.cloudfunctions.net/salesByDayOfWeek',
            };
        } else {
            config = {
                method: 'get',
                url: 'https://us-central1-sendwell-profit-tracker.cloudfunctions.net/salesByDayOfWeek?funnelID=' + funnelID,
            };
        }

        
          salesDataArr = await axios(config);
          salesDataArr = salesDataArr.data;
          
          for (let i = 0; i < salesDataArr.length; i++) {
            //console.log(i)
            daysArr.push(salesDataArr[i].day);
            ordersArr.push(salesDataArr[i].orders);
            profitArr.push(salesDataArr[i].profit);
            subscribersArr.push(salesDataArr[i].subscribers);
            subscriptionRateArr.push(parseFloat(salesDataArr[i].subscriptionRate));
            cpaArr.push(salesDataArr[i].cpa);
          }
          //setSalesData(salesDataArr.data);
          setDays(daysArr);
          setOrders(ordersArr);
          setProfit(profitArr);
          setSubscribers(subscribersArr);
          setSubscriptionRate(subscriptionRateArr);
          setCPA(cpaArr);

          //console.log(subscriptionRateArr)
          //console.log(ordersArr) 
    }


    async function getFunnels() {
   
        var testingFunnelsArr = [];
        var winningFunnelsArr = [];
        
        if (userType == "Administrative" || userType == "Accounting") {
          //Winning Funnels
          const winningFunnelsRef = query(collection(db, "funnels/"), where("status", "==", 85))
          const winningFunnelsSnap = await getDocs(winningFunnelsRef);
          winningFunnelsSnap.forEach((docs) => {
            winningFunnelsArr.push(docs.data()) 
            //console.log(docs.data())
          })
          setWinningFunnels(winningFunnelsArr);
          //Testing Funnels
          const testingFunnelsRef = query(collection(db, "funnels/"), where("status", "==", 68))
          const testingFunnelsSnap = await getDocs(testingFunnelsRef);
          testingFunnelsSnap.forEach((docs) => {
            testingFunnelsArr.push(docs.data()) 
          })
          setTestingFunnels(testingFunnelsArr);
          
        } else if (userType == "Media Buyer") {
            const docRef = doc(db, "users/" + props.uidState);
            const docSnap = await getDoc(docRef);

          //Winning Funnels
          const winningFunnelsRef = query(collection(db, "funnels/"), where("status", "==", 85), where("mediaBuyer", "==", docSnap.data().employeeID.toString()))
          const winningFunnelsSnap = await getDocs(winningFunnelsRef);
          winningFunnelsSnap.forEach((docs) => {
            winningFunnelsArr.push(docs.data()) 
            //console.log(docs.data())
          })
          setWinningFunnels(winningFunnelsArr);
          //Testing Funnels
          const testingFunnelsRef = query(collection(db, "funnels/"), where("status", "==", 68), where("mediaBuyer", "==", docSnap.data().employeeID.toString()))
          const testingFunnelsSnap = await getDocs(testingFunnelsRef);
          testingFunnelsSnap.forEach((docs) => {
            testingFunnelsArr.push(docs.data()) 
          })
          setTestingFunnels(testingFunnelsArr);
        }

    }


    useEffect(() => {
        getFunnels();
    }, [])

    useEffect(() => {
        setDays([])
        getData(selectedFunnel);
    }, [selectedFunnel])

    //console.log(salesData)
  

    function OrdersChartCode() {
        return (            
        <Bar data={{
            labels: days,
            datasets: [
            {
                data: orders,
                backgroundColor: 'rgb(0, 171, 85)',
                borderColor: 'rgb(0, 171, 85)',
            },
            ],
        }}


        options={{
            responsive: true,
            maintainAspectRatio: true,
            events: [],
            
            plugins: {
            legend: {
                display: false,
            },
            },

            scales: {
            x: {
                display: true,
                grid: {
                display: false
                },
                ticks: {
                autoSkip: true,
                maxTicksLimit: 8,
                maxRotation: 0,
                minRotation: 0,
                font: {
                    family: 'Varela Round'
                }
                },
                
            },
            y: {
                beginAtZero: false,
                display: true,
                grid: {
                display: false
                },
                ticks: {
                autoSkip: true,
                maxTicksLimit: 5,
                maxRotation: 0,
                minRotation: 0,
                font: {
                    family: 'Varela Round'
                }
                }
            },
            },

            elements: {
            point:{
                radius: 0
            }
        }}
    }
        />
        )
    }

    function ProfitChartCode() {
        return (            
        <Bar data={{
            labels: days,
            datasets: [
            {
                data: profit,
                backgroundColor: 'rgb(0, 171, 85)',
                borderColor: 'rgb(0, 171, 85)',
            },
            ],
        }}


        options={{
            responsive: true,
            maintainAspectRatio: true,
            events: [],
            
            plugins: {
            legend: {
                display: false,
            },
            },

            scales: {
            x: {
                display: true,
                grid: {
                display: false
                },
                ticks: {
                autoSkip: true,
                maxTicksLimit: 8,
                maxRotation: 0,
                minRotation: 0,
                font: {
                    family: 'Varela Round'
                }
                },
                
            },
            y: {
                beginAtZero: false,
                display: true,
                grid: {
                display: false
                },
                ticks: {
                autoSkip: true,
                maxTicksLimit: 5,
                maxRotation: 0,
                minRotation: 0,
                font: {
                    family: 'Varela Round'
                }
                }
            },
            },

            elements: {
            point:{
                radius: 0
            }
        }}}
        />)
    }

    function SubscribersChartCode() {
        return (            
        <Bar data={{
            labels: days,
            datasets: [
            {
                data: subscribers,
                backgroundColor: 'rgb(0, 171, 85)',
                borderColor: 'rgb(0, 171, 85)',
            },
            ],
        }}


        options={{
            responsive: true,
            maintainAspectRatio: true,
            events: [],
            
            plugins: {
            legend: {
                display: false,
            },
            },

            scales: {
            x: {
                display: true,
                grid: {
                display: false
                },
                ticks: {
                autoSkip: true,
                maxTicksLimit: 8,
                maxRotation: 0,
                minRotation: 0,
                font: {
                    family: 'Varela Round'
                }
                },
                
            },
            y: {
                beginAtZero: false,
                display: true,
                grid: {
                display: false
                },
                ticks: {
                autoSkip: true,
                maxTicksLimit: 5,
                maxRotation: 0,
                minRotation: 0,
                font: {
                    family: 'Varela Round'
                }
                }
            },
            },

            elements: {
            point:{
                radius: 0
            }
        }}}
        />)
    }

    function CPAChartCode() {
        return (        
        <Bar data={{
            labels: days,
            datasets: [
            {
                data: cpa,
                backgroundColor: 'rgb(0, 171, 85)',
                borderColor: 'rgb(0, 171, 85)',
            },
            ],
        }}


        options={{
            responsive: true,
            maintainAspectRatio: true,
            events: [],
            
            plugins: {
            legend: {
                display: false,
            },
            },

            scales: {
            x: {
                display: true,
                grid: {
                display: false
                },
                ticks: {
                autoSkip: true,
                maxTicksLimit: 8,
                maxRotation: 0,
                minRotation: 0,
                font: {
                    family: 'Varela Round'
                }
                },
                
            },
            y: {
                beginAtZero: false,
                display: true,
                grid: {
                display: false
                },
                ticks: {
                autoSkip: true,
                maxTicksLimit: 5,
                maxRotation: 0,
                minRotation: 0,
                font: {
                    family: 'Varela Round'
                }
                }
            },
            },

            elements: {
            point:{
                radius: 0
            }
        }}}
        />)
    }

    function SubscriptionRateChartCode() {
        return (            
        <Bar data={{
            labels: days,
            datasets: [
            {
                data: subscriptionRate,
                backgroundColor: 'rgb(0, 171, 85)',
                borderColor: 'rgb(0, 171, 85)',
            },
            ],
        }}


        options={{
            responsive: true,
            maintainAspectRatio: true,
            events: [],
            
            plugins: {
            legend: {
                display: false,
            },
            },

            scales: {
            x: {
                display: true,
                grid: {
                display: false
                },
                ticks: {
                autoSkip: true,
                maxTicksLimit: 8,
                maxRotation: 0,
                minRotation: 0,
                font: {
                    family: 'Varela Round'
                }
                },
                
            },
            y: {
                beginAtZero: false,
                display: true,
                grid: {
                display: false
                },
                ticks: {
                autoSkip: true,
                maxTicksLimit: 5,
                maxRotation: 0,
                minRotation: 0,
                font: {
                    family: 'Varela Round'
                }
                }
            },
            },

            elements: {
            point:{
                radius: 0
            }
        }}}
        />)
    }


  return (<>
    
    
    
    <div className="salesByDayOfWeekChartMainContainer">
      
            <div className="dashboardSalesByDayChartDataContainer">
                <div onClick={() => {setChartData('orders')}} className={chartData == 'orders' ? "activeDashboardSalesByDayFunnelNameContainer" : "dashboardSalesByDayFunnelNameContainer"}>
                    <span className="dashboardSalesByDayFunnelNameLink">Orders</span>
                </div>
                <div onClick={() => {setChartData('profit')}} className={chartData == 'profit' ? "activeDashboardSalesByDayFunnelNameContainer" : "dashboardSalesByDayFunnelNameContainer"}>
                    <span className="dashboardSalesByDayFunnelNameLink">Profit</span>
                </div>
                <div onClick={() => {setChartData('subscribers')}} className={chartData == 'subscribers' ? "activeDashboardSalesByDayFunnelNameContainer" : "dashboardSalesByDayFunnelNameContainer"}>
                    <span className="dashboardSalesByDayFunnelNameLink">Subscribers</span>
                </div>
                <div onClick={() => {setChartData('cpa')}} className={chartData == 'cpa' ? "activeDashboardSalesByDayFunnelNameContainer" : "dashboardSalesByDayFunnelNameContainer"}>
                    <span className="dashboardSalesByDayFunnelNameLink">CPA</span>
                </div>
                <div onClick={() => {setChartData('subscriptionRate')}} className={chartData == 'subscriptionRate' ? "activeDashboardSalesByDayFunnelNameContainer" : "dashboardSalesByDayFunnelNameContainer"}>
                    <span className="dashboardSalesByDayFunnelNameLink">Subscription Rate</span>
                </div>
            </div>
      


        


        <div className="salesByDayOfWeekChartContainer">

        {days.length != 0 ? <>
        {chartData == 'orders' ? <OrdersChartCode/> : chartData == 'profit' ? <ProfitChartCode/> : chartData == 'subscribers' ? <SubscribersChartCode/> : chartData == 'cpa' ? <CPAChartCode/> : <SubscriptionRateChartCode/>}
        </>:<>
            <div className="salesByDayOfWeekLoadingMainContainer">
                <div className="salesByDayOfWeekLoadingContainer">
                    <CircularProgress color="success"/><br/><br/>
                    <span className="salesByDayOfWeekLoadingText">Loading Daily Sales Distrubution</span>
                </div>
            </div>
        </>}

        <div className="dashboardSalesByDayFunnelNamesContainer">
            <div onClick={() => {setSelectedFunnel('')}} className={selectedFunnel == '' ? "activeDashboardSalesByDayFunnelNameContainer" : "dashboardSalesByDayFunnelNameContainer"}>
                <span className="dashboardSalesByDayFunnelNameLink">All Funnels</span>
            </div>
            {winningFunnels.map((a) => { return (
                <div key={a.funnelID} onClick={() => {setSelectedFunnel(a.funnelID)}} className={selectedFunnel == a.funnelID ? "activeDashboardSalesByDayFunnelNameContainer" : "dashboardSalesByDayFunnelNameContainer"}>
                    <span className="dashboardSalesByDayFunnelNameLink">{a.funnelName}</span>
                </div>
            )})}
            {testingFunnels.map((a) => { return (
                <div key={a.funnelID} onClick={() => {setSelectedFunnel(a.funnelID)}} className={selectedFunnel == a.funnelID ? "activeDashboardSalesByDayFunnelNameContainer" : "dashboardSalesByDayFunnelNameContainer"}>
                    <span className="dashboardSalesByDayFunnelNameLink">{a.funnelName}</span>
                </div>
            )})}
        </div>

        </div>
    </div>

  </>);
}





export default SalesByDayOfWeek;
