import React, { useState } from 'react';
import TextField from '@mui/material/TextField';



function FunnelDomain({setAddFunnelDomain, addFunnelDomain, existingDomainName}) {

    useState(() => {
        setAddFunnelDomain(existingDomainName)
    }, [])

    const handleChange = (event) => {
        setAddFunnelDomain(event.target.value)
    }

    return ( 
        <TextField autoFocus value={addFunnelDomain} margin="dense" id="name" label="Funnel Domain" type="text" fullWidth variant="standard" onChange={handleChange}/>
     );
}

export default FunnelDomain;