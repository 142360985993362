
import React, {useState, useEffect, useContext} from "react";
import ReactDOM from 'react-dom';
import {doc, onSnapshot, collection, updateDoc, addDoc, query, orderBy, where, getDoc, getDocs, limit} from "firebase/firestore";
import {db} from './FirebaseInit';
import { Bar } from 'react-chartjs-2';
import './DashboardSalesByDayOfWeek.css'
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './DashboardSubscriptionRevenue.css';
const axios = require("axios");



function SubscriptionRevenue(props) {
    var subscriptionData = [];
    const [subscriptionDataState, setSubscriptionDataState] = useState({
        totalActive: 0,
        totalCanceled: 0,
        totalSuspended: 0,
        avgDaysUntilDead: 0
    })


    async function getData() {
        var config = {
            method: 'get',
            url: 'https://myvipmanage.sendwell.com/api/v1/summary-daily-statistics/',
            headers: {
                Accept: 'application/vnd.api+json',
                Authorization: 'Bearer TUeeS4ezf0AfnxA7WzpIZiiKoa4eOQPHnutSfwZN'
            }
        };
        
        subscriptionData = await axios(config);
        subscriptionData = subscriptionData.data.data[0].attributes;
        setSubscriptionDataState(subscriptionData)


  
    }

    useEffect(() => {
        getData()
    }, [])
    

  return (<>

<div className="subRevenueContainer">
    <table>
        <tbody>
            <tr>
                <td>Monthly Subscription Revenue:</td>
                <td><div className="subRevenueAmountContainer"><span className="subRevenueAmount">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(subscriptionDataState.totalActive * 4.95)}</span></div></td>
            </tr>
            <tr>
                <td>Total Active:</td>
                <td><div className="subRevenueAmountContainer"><span className="subRevenueAmount">{new Intl.NumberFormat().format(subscriptionDataState.totalActive)}</span></div></td>
            </tr>
            <tr>
                <td>Average Time Until Dead:</td>
                <td><div className="subRevenueAmountContainer"><span className="subRevenueAmount">{new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(subscriptionDataState.avgDaysUntilDead) + " Days"}</span></div></td>
            </tr>
            <tr>
                <td>Value of a Subscription:</td>
                <td><div className="subRevenueAmountContainer"><span className="subRevenueAmount">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(((subscriptionDataState.avgDaysUntilDead / 30) - 1) * 4.95)}</span></div></td>
            </tr>
            <tr>
                <td>Profit In Date Selection:</td>
                <td><div className="subRevenueAmountContainer"><span className="subRevenueAmount">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(props.profit)}</span></div></td>
            </tr>
            <tr>
                <td>Subscribers In Date Selection:</td>
                <td><div className="subRevenueAmountContainer"><span className="subRevenueAmount">{new Intl.NumberFormat().format(props.subscribers)}</span></div></td>
            </tr>
            <tr>
                <td>Profit In Date Selection (With Subscribers)</td>
                <td><div className="subRevenueAmountContainer"><span className="subRevenueAmount">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format((props.subscribers * ((subscriptionDataState.avgDaysUntilDead / 30) - 1) * 4.95) + +props.profit)}</span></div></td>
            </tr>

        </tbody>
    </table>

</div>
    
    

  </>);
}





export default SubscriptionRevenue;
