import React, {useState, useEffect, useContext} from "react";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { getAuth, signOut } from "firebase/auth";
import AddIcon from '@mui/icons-material/Add';
import './ProductPagesTabs.css';
import {db} from './FirebaseInit';
import {doc, onSnapshot, collection} from "firebase/firestore";
import {addDoc, Timestamp, setDoc, updateDoc, getDoc, deleteDoc, query, getDocs } from "firebase/firestore"; 
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {FunnelContext} from "../Context";
import FunnelStatusSlider from "./AddFunnelComponents/FunnelStatusSlider";
import FunnelTrafficSource from "./AddFunnelComponents/FunnelTrafficSource";
import FunnelMediaBuyer from "./AddFunnelComponents/FunnelMediaBuyer";
import FunnelFaceBookBM from "./AddFunnelComponents/FunnelFaceBookBM";
import FunnelFaceBookAdAccount from "./AddFunnelComponents/FunnelFaceBookAdAccount";
import FunnelCampaignSelector from "./AddFunnelComponents/FunnelCampaignSelector";
import FunnelName from "./AddFunnelComponents/FunnelName";
import FunnelDomain from "./AddFunnelComponents/FunnelDomain";
import FunnelFees from "./AddFunnelComponents/FunnelFees";
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import SubsweetFunnel from './AddFunnelComponents/SubsweetFunnel';
import SubsweetSubID from './AddFunnelComponents/SubsweetSubID';
import FunnelTikTokBM from "./AddFunnelComponents/FunnelTikTokBM";
import FunnelTikTokAdAccount from "./AddFunnelComponents/FunnelTikTokAdAccount";
import FunnelTikTokCampaignSelector from "./AddFunnelComponents/FunnelTikTokCampaignSelector";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import './ProductPageOptions.css';



function signOutFnc () {
const auth = getAuth();
console.log(auth)
signOut(auth).then(() => {
  // Sign-out successful.
}).catch((error) => {
  // An error happened.
});
}


function ProductPageOptions (props) {

  const { selectedFunnel } = useContext(FunnelContext);

  const [deleteFunnelTextbox, setDeleteFunnelTextbox] = useState('');

  const [funnelNotesValue, setFunnelNotesValue] = useState('');
 

  //opening and closing more options dropdown
  const [optionsDropdownState, setOptionsDropdownState] = React.useState(null);
  const openOptions = Boolean(optionsDropdownState);
  const openOptionsDropdownClick = (event) => {
    setOptionsDropdownState(event.currentTarget);
  };
  const closeOptionsDropdownClick = () => {
    setOptionsDropdownState(null);
  };


  //opening and closing edit funnels pop up
   const [openEditFunnels, setOpenEditFunnels] = React.useState(false);
   const openEditFunnelsPopup = () => {
    setOpenEditFunnels(true);
     closeOptionsDropdownClick();
   };
   const closeEditFunnelsPopup = () => {
    setOpenEditFunnels(false);
   };


     //opening and closing view pop up
     const [openView, setOpenView] = React.useState(false);
     const openViewPopup = () => {
      setOpenView(true);
       closeOptionsDropdownClick();
     };
     const closeViewPopup = () => {
      setOpenView(false);
     };



  //opening and closing delete funnel popup
    const [openDeleteFunnel, setOpenDeleteFunnel] = React.useState(false);
    const openDeleteFunnelPopup = () => {
      setOpenDeleteFunnel(true);
      closeOptionsDropdownClick();
    };
    const closeDeleteFunnelPopup = () => {
      setOpenDeleteFunnel(false);
    };


  //opening and closing funnel notes popup
  async function saveFunnelNotes () {
    await updateDoc(doc(db, "funnels/" + selectedFunnel), {funnelNotes : funnelNotesValue});
    closeFunnelNotesPopup();
  }


  const [openFunnelNotes, setFunnelNotes] = React.useState(false);
  const openFunnelNotesPopup = () => {
    setFunnelNotes(true);
    closeOptionsDropdownClick();
  };
  const closeFunnelNotesPopup = () => {
    setFunnelNotes(false);
    
  };

  const [existingFunnelName, setExistingFunnelName] = useState('');
  const [existingDomainName, setExistingDomainName] = useState('');
  const [existingFunnelStatus, setExistingFunnelStatus] = useState('');
  const [existingTrafficSource, setExistingTrafficSource] = useState('');
  const [existingMediaBuyer, setExistingMediaBuyer] = useState('');
  const [existingFacebookBM, setExistingFacebookBM] = useState('');
  const [existingFacebookAdAccount, setExistingFacebookAdAccount] = useState('');
  const [existingFacebookCampaign, setExistingFacebookCampaign] = useState('');
  const [existingCashbackOnAds, setExistingCashbackOnAds] = useState('');
  const [existingMerchantFees, setExistingMerchantFees] = useState('');
  const [existingMerchantFeesPerOrder, setExistingMerchantFeesPerOrder] = useState('');
  const [existingSubsweetFunnel, setExistingSubsweetFunnel] = useState('');
  const [existingSubsweetSubID, setExistingSubsweetSubID] = useState('');
  const [existingTikTokBM, setExistingTikTokBM] = useState('');
  const [existingTikTokAdAccount, setExistingTikTokAdAccount] = useState('');
  const [existingTikTokCampaign, setExistingTikTokCampaign] = useState([]);



  const [addFunnelName, setAddFunnelName] = useState('');
  const [addFunnelDomain, setAddFunnelDomain] = useState('');
  const [addStatus, setAddStatus] = useState('');
  const [addTrafficSource, setAddTrafficSource] = useState('');
  const [addFaceBookBusinessManager, setAddFaceBookBusinessManager] = useState('');
  const [linkFacebookAdAccount, setLinkFacebookAdAccount] = useState('');
  const [linkFacebookCampaign, setLinkFacebookCampaign] = useState('');
  const [addMediaBuyer, setAddMediaBuyer] = useState('');
  const [addCashback, setAddCashback] = useState('');
  const [addMerchantFees, setAddMerchantFees] = useState('');
  const [addMerchantFeesPerOrder, setAddMerchantFeesPerOrder] = useState('');
  const [subsweetFunnel, setSubsweetFunnel] = useState('');
  const [subsweetSubID, setSubsweetSubID] = useState('');
  const [addTikTokBusinessManager, setAddTikTokBusinessManager] = useState('');
  const [linkTikTokAdAccount, setLinkTikTokAdAccount] = useState('');
  const [linkTikTokCampaign, setLinkTikTokCampaign] = useState([]);



  const [dateColumn, setDateColumn] = useState(true)
  const [orderColumn, setOrderColumn] = useState(true)
  const [revenueColumn, setRevenueColumn] = useState(true)
  const [adspendColumn, setAdspendColumn] = useState(true)
  const [COGSColumn, setCOGSColumn] = useState(true)
  const [feesColumn, setFeesColumn] = useState(true)
  const [CPCColumn, setCPCColumn] = useState(true)
  const [ROASColumn, setROASColumn] = useState(true)
  const [AOVColumn, setAOVColumn] = useState(true)
  const [impressionsColumn, setImpressionsColumn] = useState(true)
  const [clicksColumn, setClicksColumn] = useState(true)
  const [CTRColumn, setCTRColumn] = useState(true)
  const [CPMColumn, setCPMColumn] = useState(true)
  const [CVRColumn, setCVRColumn] = useState(true)
  const [profitColumn, setProfitColumn] = useState(true)
  const [subscribersColumn, setSubscribersColumn] = useState(true)
  const [CPAColumn, setCPAColumn] = useState(true)
  const [notesColumn, setNotesColumn] = useState(true)


  useEffect(() => {
    initializingColumns()
  }, [selectedFunnel])

  async function initializingColumns () {
    const docRef = doc(db, "users", getAuth().currentUser.uid, "viewSettings", "viewSettings");
    const docSnap = await getDoc(docRef);

    setOrderColumn(docSnap.data().dateColumn);
    setDateColumn(docSnap.data().orderColumn);
    setRevenueColumn(docSnap.data().revenueColumn);
    setAdspendColumn(docSnap.data().adspendColumn);
    setCOGSColumn(docSnap.data().COGSColumn);
    setFeesColumn(docSnap.data().feesColumn);
    setCPCColumn(docSnap.data().CPCColumn);
    setROASColumn(docSnap.data().ROASColumn);
    setAOVColumn(docSnap.data().AOVColumn);
    setImpressionsColumn(docSnap.data().impressionsColumn);
    setClicksColumn(docSnap.data().clicksColumn);
    setCTRColumn(docSnap.data().CTRColumn);
    setCPMColumn(docSnap.data().CPMColumn);
    setCVRColumn(docSnap.data().CVRColumn);
    setProfitColumn(docSnap.data().profitColumn);
    setSubscribersColumn(docSnap.data().subscribersColumn);
    setCPAColumn(docSnap.data().CPAColumn);
    setNotesColumn(docSnap.data().notesColumn)

  }


  async function writeViewSettingsToDB () {

    var viewData = {
      dateColumn: dateColumn,
      orderColumn: orderColumn,
      revenueColumn: revenueColumn,
      adspendColumn: adspendColumn,
      COGSColumn: COGSColumn,
      feesColumn: feesColumn,
      CPCColumn: CPCColumn,
      ROASColumn: ROASColumn,
      AOVColumn: AOVColumn,
      impressionsColumn: impressionsColumn,
      clicksColumn: clicksColumn,
      CTRColumn: CTRColumn,
      CPMColumn: CPMColumn,
      CVRColumn: CVRColumn,
      profitColumn: profitColumn,
      subscribersColumn: subscribersColumn,
      CPAColumn: CPAColumn,
      notesColumn: notesColumn
    }
   
    //console.log(getAuth().currentUser.uid)

    console.log(await setDoc(doc(db, "users/" + getAuth().currentUser.uid + "/viewSettings/viewSettings"), viewData));
    closeViewPopup();

  }
  



  async function getFunnels() {
    
    onSnapshot(doc(db, "funnels", selectedFunnel), (doc) => {

      setExistingFunnelName(doc.data().funnelName == undefined ? "" : doc.data().funnelName);
      setExistingDomainName(doc.data().domain);
      setExistingFunnelStatus(doc.data().status);
      setExistingTrafficSource(doc.data().trafficSource);
      setFunnelNotesValue(doc.data().funnelNotes);
      setExistingMediaBuyer(doc.data().mediaBuyer);
      setExistingFacebookBM(doc.data().businessManager);
      setExistingFacebookAdAccount(doc.data().adAccount);
      setExistingFacebookCampaign(doc.data().linkedFacebookCampaign);
      setExistingCashbackOnAds(doc.data().cashbackOnAds);
      setExistingMerchantFees(doc.data().merchantFees);
      setExistingMerchantFeesPerOrder(doc.data().merchantFeesPerOrder)
      setExistingSubsweetFunnel(doc.data().subsweetFunnel);
      setExistingSubsweetSubID(doc.data().subsweetSubID);
      setExistingTikTokBM(doc.data().addTikTokBusinessManager);
      setExistingTikTokAdAccount(doc.data().linkTikTokAdAccount);
      setExistingTikTokCampaign(doc.data().linkTikTokCampaign);

    })
  }

useEffect(() => {
  getFunnels()
}, [selectedFunnel])

  



  async function updateFunnelData () {
    const funnelData = {
        funnelName: addFunnelName,
        domain: addFunnelDomain,
        status: addStatus,
        trafficSource: addTrafficSource,
        adAccount: linkFacebookAdAccount,
        businessManager: addFaceBookBusinessManager,
        linkedFacebookCampaign: linkFacebookCampaign,
        mediaBuyer: addMediaBuyer.toString(),
        lastSaved: Timestamp.fromDate(new Date()),
        cashbackOnAds: parseFloat(addCashback),
        merchantFees: parseFloat(addMerchantFees),
        merchantFeesPerOrder: parseFloat(addMerchantFeesPerOrder),
        subsweetFunnel: subsweetFunnel,
        subsweetSubID: subsweetSubID, 
        addTikTokBusinessManager: addTikTokBusinessManager,
        linkTikTokAdAccount: linkTikTokAdAccount, 
        linkTikTokCampaign: linkTikTokCampaign
    };

const docRef = await updateDoc(doc(db, "funnels/" + selectedFunnel), funnelData);
closeEditFunnelsPopup();
}


//DELETING FUNNEL ALERT
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const [open, setOpen] = React.useState(false);



const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  setOpen(false);
}

async function deleteFunnel() {
if (deleteFunnelTextbox == existingFunnelName) {
  await deleteDoc(doc(db, "funnels", selectedFunnel));
  setOpenDeleteFunnel(false);
} else {
  setOpen(true)
}
  
}




    return(
<>


        <div style={{position: 'fixed', top: 0, right: 5}}>
          <div className="productPageOptionsButton" onClick={openOptionsDropdownClick}>
             <span className="productPageOptionsButtonText">Funnel Options</span>
          </div>

      <Menu id="basic-menu" anchorEl={optionsDropdownState} open={openOptions} onClose={closeOptionsDropdownClick} MenuListProps={{'aria-labelledby': 'basic-button',}}>
        <MenuItem onClick={openViewPopup} >View</MenuItem>
        <MenuItem onClick={openEditFunnelsPopup} >Funnel Settings</MenuItem>
        {props.showManualInputTable == false ? <MenuItem onClick={() => props.setShowManualInputTable(true)}>Manual Entry</MenuItem> : <MenuItem onClick={() => props.setShowManualInputTable(false)}>Hide Manual Entry</MenuItem>}
        <MenuItem onClick={openFunnelNotesPopup} >Funnel Notes</MenuItem>
        <MenuItem onClick={openDeleteFunnelPopup}>Delete Funnel</MenuItem>
        <MenuItem onClick={signOutFnc}>Logout</MenuItem>
      </Menu></div>
      


      <Dialog open={openView} onClose={closeViewPopup}>
        <DialogTitle>Edit Columns</DialogTitle>
        <DialogContent>
          <DialogContentText>

            <table>
              <tbody>
                <tr>
                  <td><FormControlLabel control={<Switch checked={dateColumn} onChange={(event) => setDateColumn(event.target.checked)}/>} label="Date" disabled/></td>
                  <td><FormControlLabel control={<Switch checked={orderColumn} onChange={(event) => setOrderColumn(event.target.checked)}/>} label="Orders" /></td>
                </tr>
                <tr>
                  <td><FormControlLabel control={<Switch checked={revenueColumn} onChange={(event) => setRevenueColumn(event.target.checked)}/>} label="Revenue" /></td>
                  <td><FormControlLabel control={<Switch checked={adspendColumn} onChange={(event) => setAdspendColumn(event.target.checked)}/>} label="Advertising" /></td>
                </tr>                
                <tr>
                  <td><FormControlLabel control={<Switch checked={COGSColumn} onChange={(event) => setCOGSColumn(event.target.checked)}/>} label="Cost of Good Sold" /></td>
                  <td><FormControlLabel control={<Switch checked={feesColumn} onChange={(event) => setFeesColumn(event.target.checked)}/>} label="Fees/Credits" /></td>
                </tr>                
                <tr>
                  <td><FormControlLabel control={<Switch checked={CPCColumn} onChange={(event) => setCPCColumn(event.target.checked)}/>} label="Cost Per Conversion" /></td>
                  <td><FormControlLabel control={<Switch checked={ROASColumn} onChange={(event) => setROASColumn(event.target.checked)}/>} label="Return on Ad Spend" /></td>
                </tr>                
                <tr>
                  <td><FormControlLabel control={<Switch checked={AOVColumn} onChange={(event) => setAOVColumn(event.target.checked)}/>} label="Average Order Value" /></td>
                  <td><FormControlLabel control={<Switch checked={impressionsColumn} onChange={(event) => setImpressionsColumn(event.target.checked)}/>} label="Campaign Impressions" /></td>
                </tr>
                <tr>
                  <td><FormControlLabel control={<Switch checked={clicksColumn} onChange={(event) => setClicksColumn(event.target.checked)}/>} label="Campaign Link Clicks" /></td>
                  <td><FormControlLabel control={<Switch checked={CTRColumn} onChange={(event) => setCTRColumn(event.target.checked)}/>} label="Click Through Rate" /></td>
                </tr>                
                <tr>
                  <td><FormControlLabel control={<Switch checked={CPMColumn} onChange={(event) => setCPMColumn(event.target.checked)}/>} label="Cost Per Mille" /></td>
                  <td><FormControlLabel control={<Switch checked={CVRColumn} onChange={(event) => setCVRColumn(event.target.checked)}/>} label="Estimated Conversion Rate" /></td>
                </tr>                
                <tr>
                  <td><FormControlLabel control={<Switch checked={profitColumn} onChange={(event) => setProfitColumn(event.target.checked)}/>} label="Profit" /></td>
                  <td><FormControlLabel control={<Switch checked={subscribersColumn} onChange={(event) => setSubscribersColumn(event.target.checked)}/>} label="Subscribers" /></td>
                </tr>                
                <tr>
                  <td><FormControlLabel control={<Switch checked={CPAColumn} onChange={(event) => setCPAColumn(event.target.checked)}/>} label="Cost Per Acquisition" /></td>
                  <td><FormControlLabel control={<Switch checked={notesColumn} onChange={(event) => setNotesColumn(event.target.checked)}/>} label="Notes" /></td>
                </tr>
              </tbody>
            </table>





          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeViewPopup}>Cancel</Button>
          <Button onClick={writeViewSettingsToDB} variant="contained">Save</Button>
        </DialogActions>
      </Dialog>





    <div>
     <Dialog open={openEditFunnels} onClose={closeOptionsDropdownClick}>
       <DialogTitle>Funnel Settings</DialogTitle>
       <DialogContent>
         <DialogContentText>
           To adjust the funnel settings please set the corresponding field and click Save Funnel. 
         </DialogContentText>

         <FunnelName setAddFunnelName={setAddFunnelName} addFunnelName={addFunnelName} existingFunnelName={existingFunnelName}/>
        
         <FunnelDomain setAddFunnelDomain={setAddFunnelDomain} addFunnelDomain={addFunnelDomain} existingDomainName={existingDomainName}/>
         <br/><br/>
         <FunnelStatusSlider setAddStatus={setAddStatus} addStatus={addStatus} existingFunnelStatus={existingFunnelStatus}/>

         <FunnelTrafficSource addTrafficSource={addTrafficSource} setAddTrafficSource={setAddTrafficSource} existingTrafficSource={existingTrafficSource}/><br/>
        
         <FunnelMediaBuyer addMediaBuyer={addMediaBuyer} setAddMediaBuyer={setAddMediaBuyer} existingMediaBuyer={existingMediaBuyer}/><br/><br/>

         <SubsweetFunnel subsweetFunnel={subsweetFunnel} setSubsweetFunnel={setSubsweetFunnel} existingSubsweetFunnel={existingSubsweetFunnel}/><br/><br/>
        
         <SubsweetSubID setSubsweetSubID={setSubsweetSubID} subsweetSubID={subsweetSubID} existingSubsweetSubID={existingSubsweetSubID}/><br/><br/>

        {addTrafficSource == 1655921921514 ? <>
         <FunnelFaceBookBM addFaceBookBusinessManager={addFaceBookBusinessManager} setAddFaceBookBusinessManager={setAddFaceBookBusinessManager} existingFacebookBM={existingFacebookBM}/><br/><br/>

         <FunnelFaceBookAdAccount linkFacebookAdAccount={linkFacebookAdAccount} setLinkFacebookAdAccount={setLinkFacebookAdAccount} addFaceBookBusinessManager={addFaceBookBusinessManager} existingFacebookAdAccount={existingFacebookAdAccount}/><br/><br/>

         <FunnelCampaignSelector linkFacebookCampaign={linkFacebookCampaign} setLinkFacebookCampaign={setLinkFacebookCampaign} addFaceBookBusinessManager={addFaceBookBusinessManager} linkFacebookAdAccount={linkFacebookAdAccount} existingFacebookCampaign={existingFacebookCampaign}/>
         </>
         : addTrafficSource == 1658346558337 ? <>
         <FunnelTikTokBM  addTikTokBusinessManager={addTikTokBusinessManager} setAddTikTokBusinessManager={setAddTikTokBusinessManager} existingTikTokBM={existingTikTokBM}/><br/><br/>
         
         <FunnelTikTokAdAccount linkTikTokAdAccount={linkTikTokAdAccount} setLinkTikTokAdAccount={setLinkTikTokAdAccount} addTikTokBusinessManager={addTikTokBusinessManager} existingTikTokAdAccount={existingTikTokAdAccount}/><br/><br/>

         <FunnelTikTokCampaignSelector linkTikTokCampaign={linkTikTokCampaign} setLinkTikTokCampaign={setLinkTikTokCampaign} addTikTokBusinessManager={addTikTokBusinessManager} linkTikTokAdAccount={linkTikTokAdAccount} existingTikTokCampaign={existingTikTokCampaign}/>

         </>
         :<></>
        }

         <FunnelFees addCashback={addCashback} setAddCashback={setAddCashback} addMerchantFees={addMerchantFees} setAddMerchantFees={setAddMerchantFees} existingCashbackOnAds={existingCashbackOnAds} setExistingCashbackOnAds={setExistingCashbackOnAds} existingMerchantFees={existingMerchantFees} setExistingMerchantFees={setExistingMerchantFees} addMerchantFeesPerOrder={addMerchantFeesPerOrder} setAddMerchantFeesPerOrder={setAddMerchantFeesPerOrder} existingMerchantFeesPerOrder={existingMerchantFeesPerOrder} setExistingMerchantFeesPerOrder={setExistingMerchantFeesPerOrder}/><br/>





      <br/>
      FunnelID:   <b>{selectedFunnel}</b>










       </DialogContent>
       <DialogActions>
         <Button onClick={closeEditFunnelsPopup}>Cancel</Button>
         <Button onClick={updateFunnelData}>Save Funnel</Button>
       </DialogActions>
     </Dialog>





     <Dialog open={openDeleteFunnel} onClose={closeDeleteFunnelPopup}>
        <DialogTitle>Delete Funnel</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you wish to delete <strong>{existingFunnelName}</strong>? <br/><br/>
            Please retype the Funnel Name to delete:
          </DialogContentText>
          <TextField autoFocus placeholder={existingFunnelName} margin="dense" id="name"type="text" fullWidth variant="standard" onChange={event => setDeleteFunnelTextbox(event.target.value)}/>

          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
           <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
             Please type the Funnel Name to delete!
            </Alert>
          </Snackbar>


        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteFunnelPopup}>Cancel</Button>
          <Button onClick={deleteFunnel} variant="contained" color="error">Delete</Button>
        </DialogActions>
      </Dialog>









      <Dialog open={openFunnelNotes} onClose={closeFunnelNotesPopup}>
        <DialogTitle>Funnel Notes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Here you can find all of the notes that you have saved for this funnel.
          </DialogContentText>
          <TextField multiline autoFocus margin="dense" id="name" label="Funnel Notes" type="text" fullWidth variant="standard" value={funnelNotesValue} onChange={event => setFunnelNotesValue(event.target.value)}/>


        </DialogContent>
        <DialogActions>
          <Button onClick={closeFunnelNotesPopup}>Cancel</Button>
          <Button onClick={saveFunnelNotes}>Save Notes</Button>
        </DialogActions>
      </Dialog>
   </div>


   </>
    )
}

export default ProductPageOptions;