import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';

function FunnelName({setAddFunnelName, addFunnelName, existingFunnelName}) {

    useState(() => {
        setAddFunnelName(existingFunnelName)
    }, [])

    const handleChange = (event) => {
        setAddFunnelName(event.target.value)
    }



    return ( 
        <TextField autoFocus value={addFunnelName} margin="dense" id="name" label="Funnel Name" type="text" fullWidth variant="standard" onChange={handleChange}/>
     );
}

export default FunnelName;