import React, { useEffect, useState, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';
import ProductPageOptions from './ProductPageOptions';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SavingsIcon from '@mui/icons-material/Savings';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import LogoutIcon from '@mui/icons-material/Logout';
import { getAuth, signOut } from "firebase/auth";
import {UserTypeContext} from "../Context";
import NavbarSearch from './NavbarSearch';



const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft() {
  const {userType} = useContext(UserTypeContext);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  function signOutFnc() {
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
    }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar style={{backgroundColor: "white"}} position="fixed" open={open}>
        <Toolbar>
          <IconButton style={{color: "#1976d2"}} color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" sx={{ mr: 2, ...(open && { display: 'none' }) }}>
            <MenuIcon />
          </IconButton>
          <Typography style={{color: "#1976d2"}} variant="h6" noWrap component="div">ACCOUNTING</Typography>
          <ProductPageOptions/>
 
        </Toolbar>
      </AppBar>


      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >


        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>

        {userType == "Media Buyer" || userType == "Accounting" || userType == "Administrative" ? <>

        <Link to='/Dashboard'>
          <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><DashboardIcon/></ListItemIcon>
                <ListItemText primary="Dashboard"/>
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to='/ProductPage'>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><ManageHistoryIcon/></ListItemIcon>
                <ListItemText primary="Accounting Details"/>
              </ListItemButton>
            </ListItem>
            </Link>
            <Link to='/Analytics'>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><TrendingUpIcon/></ListItemIcon>
                <ListItemText primary="Analytics"/>
              </ListItemButton>
            </ListItem>
            </Link>
            <Link to='/ProductIdeas'>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><LightbulbIcon/></ListItemIcon>
                <ListItemText primary="Product Ideas" />
              </ListItemButton>
            </ListItem>
            </Link>
            <Link to='/AccountBalances'>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><AttachMoneyIcon/></ListItemIcon>
                <ListItemText primary="Account Balances" />
              </ListItemButton>
            </ListItem>
            </Link>
            <Link to='/Commission'>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><SavingsIcon/></ListItemIcon>
                <ListItemText primary="Commission" />
              </ListItemButton>
            </ListItem>
            </Link>
            <Link to='/Business'>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><SettingsIcon/></ListItemIcon>
                <ListItemText primary="Business Settings" />
              </ListItemButton>
            </ListItem>
            </Link>


            </> : <>
            <Link to='/ProductIdeas'>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><LightbulbIcon/></ListItemIcon>
                <ListItemText primary="Product Ideas" />
              </ListItemButton>
            </ListItem>
            </Link>
            </>}

            <Divider/>

            <div onClick={signOutFnc}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><LogoutIcon/></ListItemIcon>
                <ListItemText primary="Sign Out" />
              </ListItemButton>
            </ListItem>
            </div>
        </List>




      </Drawer>


      <Main open={open}>
        <DrawerHeader />
      </Main>
    </Box>
  );
}
