import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { doc, onSnapshot, collection, query, where } from "firebase/firestore";
import {db} from './../FirebaseInit';


function FunnelMediaBuyer({addMediaBuyer, setAddMediaBuyer, existingMediaBuyer}) {

  useEffect(() => {
    setAddMediaBuyer(existingMediaBuyer);
  }, [])

  //facebook pixel dropdown
  const [value, setValue] = React.useState(existingMediaBuyer);
  const handleMediaBuyerChange = (event) => {
    setValue(event.target.value);
    setAddMediaBuyer(event.target.value);
    };


//GETTING ALL EMPLOYEES
const [getEmployees, setGetEmployees] = useState([]);

useEffect(() => {
        //this code fires when the app loads
        onSnapshot(query(collection(db, "employees"), where("employeeRole", "==", "Media Buyer")), (snapshot) => {
          setGetEmployees(snapshot.docs.map(doc => doc.data()));
        }
        )}, [])
        

//console.log(getEmployees[0].id)


    return ( 
        <>
      <FormControl sx={{ minWidth: "100%" }} size="small">
      <InputLabel id="demo-select-small">Media Buyer</InputLabel>
      <Select labelId="demo-select-small" id="demo-select-small" value={value} label="Media Buyer" onChange={handleMediaBuyerChange}>
        {getEmployees.map((x) => 
        <MenuItem key={x.id} value={x.id}>{x.employeeName}</MenuItem>   
        )}
      </Select>
    </FormControl>
        </>
     )
}

export default FunnelMediaBuyer;