import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';



function SubsweetSubID({setSubsweetSubID, subsweetSubID, existingSubsweetSubID}) {

    useState(() => {
        setSubsweetSubID(existingSubsweetSubID)
    }, [])

    const handleChange = (event) => {
        setSubsweetSubID(event.target.value)
    }

    return ( 
        <>    
            <Tooltip placement="right" title="Optional: Empty field returns all sales from funnel">
            <TextField value={subsweetSubID} margin="dense" size='small' id="name" label="Subsweet Sub ID" type="text" fullWidth variant="outlined" onChange={handleChange}/>
            </Tooltip>
        </>
     );
}

export default SubsweetSubID;