import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import {db} from '../FirebaseInit';
import {doc, onSnapshot, collection} from "firebase/firestore";
import {addDoc, Timestamp, setDoc, deleteDoc } from "firebase/firestore"; 
import './BusinessTrafficSources.css';


function BusinessTrafficSources () {

    //traffic source name
    const [addTrafficSource, setAddTrafficSource] = useState('');

    async function submitNewTrafficSource () {
        const id = Date.now();

        const newTrafficSource = {
            trafficSource: addTrafficSource,
            createdAt: Timestamp.fromDate(new Date()),
            id: id,
        };
        setOpenNewTrafficSourceDialog(false)
        console.log(newTrafficSource)
        
        // Add a new document with a generated id.
        const docRef = await setDoc(doc(db, "trafficSources/" + id), newTrafficSource);
    };

    

    //NEW Traffic Source Dialog
    const [openNewTrafficSourceDialog, setOpenNewTrafficSourceDialog] = React.useState(false);

    const handleClickOpenNewTrafficSource = () => {
        setOpenNewTrafficSourceDialog(true);
    };
  
    const handleCloseNewTrafficSource = (event, reason) => {
      if (reason !== 'backdropClick') {
        setOpenNewTrafficSourceDialog(false);
      }
    };


    //DELETE Employee
    const [openDeleteTrafficSource, setOpenDeleteTrafficSource] = useState(false);
    const [deleteTrafficSourceID, setDeleteTrafficSourceID] = useState('');
  
    const handleClickOpenDeleteTrafficSource = () => {
      setOpenDeleteTrafficSource(true);
    };
  
    const handleCloseDeleteTrafficSource = (event, reason) => {
      if (reason !== 'backdropClick') {
        setOpenDeleteTrafficSource(false);
      }
    };

    async function deleteTrafficSource () {
        setOpenDeleteTrafficSource(false);
        await deleteDoc(doc(db, "trafficSources/" + deleteTrafficSourceID));
    }

    
//GETTING ALL TRAFFIC SOURCES
const [getTrafficSource, setGetTrafficSource] = useState([]);

useEffect(() => {
    //this code fires when the app loads
    onSnapshot(collection(db, "trafficSources"), (snapshot) => {
    setGetTrafficSource(snapshot.docs.map(doc => doc.data()));
    }
)}, [])
        
        
return (
<>
  <div className="businessSettingsTrafficSources">

    <table width="100%"><tbody><tr>
        <td><strong>Traffic Sources</strong></td>
        <td><p align="right"><Button  onClick={handleClickOpenNewTrafficSource} variant="outlined">New Traffic Source</Button></p></td>
    </tr></tbody></table>


    <table><tbody>
    {getTrafficSource.map((TrafficSource) =>
        <tr key={TrafficSource.id}><td>{TrafficSource.trafficSource}</td></tr>
    )}
    </tbody></table><br/>


      <Dialog disableEscapeKeyDown open={openNewTrafficSourceDialog} onClose={handleCloseNewTrafficSource}>
        <DialogTitle>New Traffic Source</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <TextField onChange={event => setAddTrafficSource(event.target.value)} id="outlined-basic" label="Traffic Source" variant="outlined" />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewTrafficSource}>Discard</Button>
          <Button onClick={submitNewTrafficSource}>Save Traffic Source</Button>
        </DialogActions>
      </Dialog>


      <Button onClick={handleClickOpenDeleteTrafficSource} variant="outlined" color="error">Remove Traffic Source</Button>


      <Dialog disableEscapeKeyDown open={openDeleteTrafficSource} onClose={handleClickOpenDeleteTrafficSource}>
        <DialogTitle>Remove Traffic Source</DialogTitle>
        <DialogContent>
            <FormControl onChange={event => setDeleteTrafficSourceID(event.target.value)}>
                <FormLabel id="demo-radio-buttons-group-label">Traffic Sources</FormLabel>
                <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                {getTrafficSource.map((TrafficSource) =>
                    <FormControlLabel key={TrafficSource.id} value={TrafficSource.id} control={<Radio />} label={TrafficSource.trafficSource} />
                )}
                </RadioGroup>
            </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteTrafficSource}>Discard</Button>
          <Button onClick={deleteTrafficSource}>Remove Traffic Source</Button>
        </DialogActions>
      </Dialog>

</div>
</>
)
}

export default BusinessTrafficSources;