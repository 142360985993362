import React, {useState, useEffect, useContext} from 'react';
import {db} from './FirebaseInit';
import {doc, onSnapshot, collection, getDocs, query, where, getDoc} from "firebase/firestore";
import './DashboardMediaBuyers.css';
import {FunnelContext} from '../Context';
import { Line, Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import {UserTypeContext} from "../Context";
import CircularProgress from '@mui/material/CircularProgress';
var moment = require('moment'); 



function DashboardMediaBuyers(props) {
  const {userType} = useContext(UserTypeContext);

  const today = () => {
    var m = moment();    
    var s = m.format();
    return s.split('T')[0];
  };

  const yesterday = () => {
    var m = moment().subtract(1, 'days');    
    var s = m.format();
    return s.split('T')[0];
  };
  
  
  const {setSelectedFunnel} = useContext(FunnelContext);
  const {selectedFunnel} = useContext(FunnelContext);

  const [allData, setAllData] = useState([]);
  var allDataArr = [];

  const [orders, setOrders] = useState([]);
  const [total, setTotal] = useState();
  const [subscribers, setSubscribers] = useState();
  const [cpa, setCpa] = useState();

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  useEffect(() => {
      setAllData([]);
      getMediaBuyers();
  }, [props])

  async function getMediaBuyers () {
    if (userType == "Media Buyer") {
      const docRef = doc(db, "users/" + props.uidState);
      const docSnap = await getDoc(docRef);
      MediaBuyerData(docSnap.data().employeeID)
    } else {
    const employeesRef = query(collection(db, "employees"), where("employeeRole", "==", "Media Buyer"))
    const mediaBuyersIDs = await getDocs(employeesRef);
    mediaBuyersIDs.forEach((doc) => {
      MediaBuyerData(doc.id)
    })//END OF FOR EACH 
  }
  }// END OF ASYNC GET MEDIA BUYERS FUNCTION

  async function MediaBuyerData(mediaBuyerId) {
    var orderTotalByMediaBuyer = [];
    var profitTotalByMediaBuyer = [];
    var subscriberTotalByMediaBuyer = [];
    var cpaTotalByMediaBuyer = [];
    var mediaBuyersDataRef;
    if (props.beginningDate == props.endingDate && props.beginningDate == today()) {
      mediaBuyersDataRef = query(collection(db, "mediaBuyerDailyData/mediaBuyers/" + mediaBuyerId ), where("date", ">=", yesterday()), where("date", "<=", props.endingDate));
    } else {
      mediaBuyersDataRef = query(collection(db, "mediaBuyerDailyData/mediaBuyers/" + mediaBuyerId ), where("date", ">=", props.beginningDate), where("date", "<=", props.endingDate));
    }
    const mediaBuyersData = await getDocs(mediaBuyersDataRef);
    const firestoreOrderDataArray = [];
    const firestoreTotalDataArray = [];
    const firestoreCPADataArray = [];
    const firestoreSubscribersDataArray = []; 
    const firestoreDateArray = [];
    var mediaBuyerId = "";  
    var mediaBuyerName = "";     

    mediaBuyersData.forEach((docs) => {
      firestoreOrderDataArray.push(docs.data().orders);
      firestoreTotalDataArray.push(docs.data().total);
      firestoreCPADataArray.push(docs.data().cpa);
      firestoreSubscribersDataArray.push(docs.data().subscribers);
      firestoreDateArray.push(months[new Date(docs.id).getMonth()] + " " + (new Date(docs.id).getDate() + 1));
      mediaBuyerId = docs.data().mediaBuyer;
    })
    onSnapshot(doc(db, "employees", mediaBuyerId), (doc) => {
      mediaBuyerName = doc.data().employeeName;
      orderTotalByMediaBuyer.push(firestoreOrderDataArray.reduce((a, b) => a + b, 0))
      profitTotalByMediaBuyer.push(firestoreTotalDataArray.reduce((a, b) => a + b, 0).toFixed(2))
      subscriberTotalByMediaBuyer.push(firestoreSubscribersDataArray.reduce((a, b) => a + b, 0))
      cpaTotalByMediaBuyer.push(((firestoreTotalDataArray.reduce((a, b) => a + b, 0)) / (firestoreOrderDataArray.reduce((a, b) => a + b, 0))).toFixed(2))

      var cpaMessage = '';
      var orders = firestoreOrderDataArray.reduce((a, b) => a + b, 0);
      var profit =  firestoreTotalDataArray.reduce((a, b) => a + b, 0).toFixed(2);
      var cpa = ((firestoreTotalDataArray.reduce((a, b) => a + b, 0)) / (firestoreOrderDataArray.reduce((a, b) => a + b, 0))).toFixed(2);

      if (props.beginningDate == props.endingDate && props.beginningDate == today()) { 
        orders = firestoreOrderDataArray[firestoreOrderDataArray.length - 1];
        profit = firestoreTotalDataArray[firestoreTotalDataArray.length - 1];
        cpa = profit / orders;

        if (orders == undefined) {
          orders = 0;
        }
        if (profit == undefined) {
          profit = 0;
        }        
        if (profit == 0 && orders == 0) {
          cpa = 0;
        }

        console.log(firestoreCPADataArray.length)
        

          if (firestoreCPADataArray[0] < firestoreCPADataArray[1]) {
            //[yesterday: 5, today: 8]
            cpaMessage = ('CPAs are ' + (firestoreCPADataArray[0] / firestoreCPADataArray[1] * 100).toFixed(0) + '% lower than yesterday')
          } else {
            //[yesterday: 8, today: 5]
              if ((Math.abs(((firestoreCPADataArray[1]) / firestoreCPADataArray[0] * 100).toFixed(0)) != NaN) && firestoreCPADataArray.length != 1) {
              cpaMessage = ('CPAs are ' + Math.abs(((firestoreCPADataArray[1]) / firestoreCPADataArray[0] * 100).toFixed(0)) + '% higher than yesterday')
            }
          }

      }

        

      allDataArr.push({
        orders: orders,
        profit: profit,
        subscribers: firestoreSubscribersDataArray.reduce((a, b) => a + b, 0),
        cpa: cpa,
        mediaBuyerName: mediaBuyerName, 
        data: firestoreOrderDataArray,
        cpaArr: firestoreCPADataArray,
        dates: firestoreDateArray,
        cpaMessage: cpaMessage,
      })

      setAllData(allDataArr)

      setOrders(orderTotalByMediaBuyer); 
      setTotal(profitTotalByMediaBuyer);
      setSubscribers(subscriberTotalByMediaBuyer); 
      setCpa(cpaTotalByMediaBuyer);

  });

  } //END OF ASYNC MEDIA BUYER DATA FUNCTION

  //console.log(allData)


  return (<div className='dashboardMediaBuyersMainConatiner'>

    {allData.length != 0 ? <>

    {allData.map((a, index) => {
      return (<div key={a.mediaBuyerName}>
    
    { index != 0 ? 
    <div className='dashboardMediaBuyersTableSpacer'></div> : <></>}


    <div className='dashboardMediaBuyersTable'>
      <span className='mediaBuyersName'>{a.mediaBuyerName}</span>

      {props.beginningDate == props.endingDate ? 
      <span className='mediaBuyersCPAMessage'>{a.cpaMessage}</span>
      :
      <>
        <br/>
      </>}

      <table className='tableWidth'>
        <tbody key={a.mediaBuyerName}>
          <tr>
            <td colSpan={3}>

              <div className='mediaBuyersChartsContainer'>

                <Bar className='mediaBuyersCharts'
                  data={{
        labels: a.dates,
        datasets: [
          {
            data: a.data,
            backgroundColor: 'rgb(0, 171, 85)',
            borderColor: 'rgb(0, 171, 85)',
          },
        ],
                  }}
                  options={{
                    //bezierCurve : true,

        responsive: true,
        maintainAspectRatio: false,
        //events: [],
        plugins: {
          legend: {
              display: false,
          },
          tooltip: {
            enabled: true,
            //backgroundColor: 'rgb(0, 171, 85)',
            //bodyFont: 'Varela Round',
          }
        },

        scales: {
          x: {
            display: true,
            grid: {
              display: false
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 8,
              maxRotation: 0,
              minRotation: 0,
              font: {
                family: 'Varela Round',
              },
            },
            
          },
          y: {
            beginAtZero: true,
            display: true,
            grid: {
              display: false
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 5,
              maxRotation: 0,
              minRotation: 0,
              font: {
                family: 'Varela Round'
              }
            }
          },
        },

        elements: {
          point:{
              radius: 2
          },
      }

                  }}
                />


              </div>
            </td>
          </tr>
        </tbody>

      </table>
                      
    
      <table className='tableWidth'>
        <tbody>
          <tr>
              <td className='mediaBuyersOrderCount'>{new Intl.NumberFormat().format(a.orders)} Orders</td>
              <td className='mediaBuyersCPA'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.cpa)} CPA</td>
              <td className='mediaBuyersProfit'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.profit)}</td>
            </tr>
        </tbody>
      </table>

     
  
  </div>
  </div>)}
  )}

  </>:<>
  <div className='dashboardMediaBuyersLoadingTable'><span className="dashboardMediaBuyersLoadingText"><CircularProgress color="success" /><br/><br/> Loading Media Buyers</span></div>
  
  </>}

  </div>)
}
export default DashboardMediaBuyers;