import React, {useState, useEffect, useContext} from 'react';
import {collection, getDocs, query, where, doc, getDoc} from "firebase/firestore";
import {db} from '../FirebaseInit';
import { Line, Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import './OrdersChart.css';
import shoppingCart from '../../images/shopping-cart.png';
import {UserTypeContext} from "../../Context";
import Skeleton from '@mui/material/Skeleton';


function OrdersChart(props) {
  const {userType} = useContext(UserTypeContext);



  const today = new Date(Date.now() - 0 * 24 * 60 * 60 * 1000);
  var currentYear = today.getFullYear()
  var currentMonth = ("0" + (today.getMonth() + 1)).slice(-2);
  var currentDay = ("0" + (today.getDate())).slice(-2);
  const [todaysFortmattedDate, setTodaysFortmattedDate] = useState(currentYear + "-" + currentMonth + "-" + currentDay);


  const yesterdayDate = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000);
  var yesterdayYear = yesterdayDate.getFullYear()
  var yesterdayMonth = ("0" + (yesterdayDate.getMonth() + 1)).slice(-2);
  var yesterdayDay = ("0" + (yesterdayDate.getDate())).slice(-2);
  const [yesterdaysFormattedDate, setYesterdaysFormattedDate] = useState(yesterdayYear + "-" + yesterdayMonth + "-" + yesterdayDay);


  const firestoreOrderDataArray = [];
  const firestoreDateDataArray = [];
  const [orders, setOrders] = useState();
  const [dates, setDates] = useState();

  const [totalOrders, setTotalOrders] = useState('Loading');

  useEffect(() => {
    if (userType != "Customer Service" && userType != "Administrative" && userType != "Accounting") {
      getMediaBuyerDailyTotals();
    } else if ((userType == "Administrative" || userType == "Accounting") && props.mediaBuyer == 'all'){
      getDailyTotals(); 
    } else if ((userType == "Administrative" || userType == "Accounting") && props.mediaBuyer != 'all'){
      adminGetMediaBuyerDailyTotals(); 
    } else {

    }
  }, [props])

  async function adminGetMediaBuyerDailyTotals () {
    const docRef = doc(db, "users/" + props.uidState);
    const docSnap = await getDoc(docRef);

    const DailyTotalRef = query(collection(db, "mediaBuyerDailyData/mediaBuyers/" + props.mediaBuyer), where("date", ">=", props.beginningDate), where("date", "<=", props.endingDate))
    const DailyTotals = await getDocs(DailyTotalRef);
    DailyTotals.forEach((docs) => {
      firestoreOrderDataArray.push(docs.data().orders);
      firestoreDateDataArray.push(docs.id);
    })//END OF FOR EACH 
    setOrders(firestoreOrderDataArray); 
    setDates(firestoreDateDataArray);
    setTotalOrders(firestoreOrderDataArray.reduce((a, b) => a + b, 0))
  }// END OF ASYNC GET DAILY TOTALS FUNCTION

  async function getDailyTotals () {
    const DailyTotalRef = query(collection(db, "totalDailyData/"), where("date", ">=", props.beginningDate), where("date", "<=", props.endingDate))
    const DailyTotals = await getDocs(DailyTotalRef);
    DailyTotals.forEach((docs) => {
      firestoreOrderDataArray.push(docs.data().orders);
      firestoreDateDataArray.push(docs.id);
    })//END OF FOR EACH 
    setOrders(firestoreOrderDataArray); 
    setDates(firestoreDateDataArray);
    setTotalOrders(firestoreOrderDataArray.reduce((a, b) => a + b, 0))
  }// END OF ASYNC GET DAILY TOTALS FUNCTION

  async function getMediaBuyerDailyTotals () {
    const docRef = doc(db, "users/" + props.uidState);
    const docSnap = await getDoc(docRef);

    const DailyTotalRef = query(collection(db, "mediaBuyerDailyData/mediaBuyers/" + docSnap.data().employeeID), where("date", ">=", props.beginningDate), where("date", "<=", props.endingDate))
    const DailyTotals = await getDocs(DailyTotalRef);
    DailyTotals.forEach((docs) => {
      firestoreOrderDataArray.push(docs.data().orders);
      firestoreDateDataArray.push(docs.id);
    })//END OF FOR EACH 
    setOrders(firestoreOrderDataArray); 
    setDates(firestoreDateDataArray);
    setTotalOrders(firestoreOrderDataArray.reduce((a, b) => a + b, 0))
  }// END OF ASYNC GET DAILY TOTALS FUNCTION



  var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
  var month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  var now = new Date(props.beginningDate);
  console.log( )

  return (<>

  {totalOrders != 'Loading' ?    

    <div className="ordersCard"> <br/>

    <table>
      <tbody>
        <tr>
          <td>
            <span className='ordersCardTitle'>ORDERS</span><br/>
            <span className='ordersCardQuantity'>{new Intl.NumberFormat().format(totalOrders)}</span>
          </td>
          <td>
            <div className='chartContainer'>
              <Line width={"150px"} height={"70px"}
                data={{
      labels: dates,
      datasets: [
        {
          data: orders,
          backgroundColor: '#FFFFFF',
          borderColor: '#FFFFFF',
        },
      ],
                }}
                options={{
      events: [],
      
      plugins: {
        legend: {
            display: false
        },
      },

      scales: {
        x: {
          display: false,
          grid: {
            display: false
          }
        },
        y: {
          beginAtZero: false,
          display: false,
          grid: {
            display: false
          }
        },
      },

      elements: {
        point:{
            radius: 0
        }
    }

                }}
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>


    {props.beginningDate == todaysFortmattedDate && props.endingDate == todaysFortmattedDate ? <><span className='ordersCardDateRange'>Today</span></> : <>
              {props.beginningDate == yesterdaysFormattedDate && props.endingDate == yesterdaysFormattedDate ? <><span className='ordersCardDateRange'>Yesterday</span></> : <>
                <span className='ordersCardDateRange'>{month[(props.beginningDate).slice(5,7) - 1] + " " + ((props.beginningDate).slice(8,10) < 10 ? (props.beginningDate).slice(9,10) : (props.beginningDate).slice(8,10))} - {month[(props.beginningDate).slice(5,7) - 1] + " " + ((props.endingDate).slice(8,10) < 10 ? (props.endingDate).slice(9,10) : (props.endingDate).slice(8,10))}</span><br/>
              </>}
            </>}


  </div>

  :<></>}

  </>)
}
export default OrdersChart;
