import React, {useState, useEffect, useContext} from 'react';
import {collection, getDocs, query, where, doc, getDoc} from "firebase/firestore";
import {db} from '../FirebaseInit';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import Chart from 'chart.js/auto';
import './CpaChart.css';
import shoppingCart from '../../images/shopping-cart.png';
import { Link } from 'react-router-dom';
import {UserTypeContext} from "../../Context";


function CpaChart(props) {
  const {userType} = useContext(UserTypeContext);
   
  const firestoreOrderDataArray = [];
  const firestoreProfitDataArray = [];
  const firestoreCpaDataArray = [];
  const firestoreDateDataArray = [];
  const [cpa, setCpa] = useState();
  const [dates, setDates] = useState();

  var totalOrders = 0;
  var totalProfit = 0;
  const [totalCpa, setTotalCpa] = useState();

  useEffect(() => {
    if (userType == "Media Buyer") {
      getMediaBuyerDailyTotals();
    } else if ((userType == "Administrative" || userType == "Accounting") && props.mediaBuyer == 'all'){
      getDailyTotals(); 
    } else if ((userType == "Administrative" || userType == "Accounting") && props.mediaBuyer != 'all'){
      adminGetMediaBuyerDailyTotals(); 
    }
  }, [props])

  async function adminGetMediaBuyerDailyTotals () {
    const docRef = doc(db, "users/" + props.uidState);
    const docSnap = await getDoc(docRef);

    setCpa();
    const DailyTotalRef = query(collection(db, "mediaBuyerDailyData/mediaBuyers/" + props.mediaBuyer), where("date", ">=", props.beginningDate), where("date", "<=", props.endingDate))
    const DailyTotals = await getDocs(DailyTotalRef);
    DailyTotals.forEach((docs) => {
      firestoreOrderDataArray.push(docs.data().orders);
      firestoreProfitDataArray.push(docs.data().total);
      firestoreCpaDataArray.push(docs.data().cpa);
      firestoreDateDataArray.push(docs.id);
    })//END OF FOR EACH 
    setCpa(firestoreCpaDataArray)
    setDates(firestoreDateDataArray);
    totalOrders = firestoreOrderDataArray.reduce((a, b) => a + b, 0).toFixed(2)
    totalProfit = firestoreProfitDataArray.reduce((a, b) => a + b, 0).toFixed(2)
    setTotalCpa((totalProfit / totalOrders).toFixed(2));
  }// END OF ASYNC GET DAILY TOTALS FUNCTION


  async function getDailyTotals () {
    setCpa();
    const DailyTotalRef = query(collection(db, "totalDailyData/"), where("date", ">=", props.beginningDate), where("date", "<=", props.endingDate))
    const DailyTotals = await getDocs(DailyTotalRef);
    DailyTotals.forEach((docs) => {
      firestoreOrderDataArray.push(docs.data().orders);
      firestoreProfitDataArray.push(docs.data().total);
      firestoreCpaDataArray.push(docs.data().cpa);
      firestoreDateDataArray.push(docs.id);
    })//END OF FOR EACH 
    setCpa(firestoreCpaDataArray)
    setDates(firestoreDateDataArray);
    totalOrders = firestoreOrderDataArray.reduce((a, b) => a + b, 0).toFixed(2)
    totalProfit = firestoreProfitDataArray.reduce((a, b) => a + b, 0).toFixed(2)
    setTotalCpa((totalProfit / totalOrders).toFixed(2));
  }// END OF ASYNC GET DAILY TOTALS FUNCTION


  async function getMediaBuyerDailyTotals () {
    const docRef = doc(db, "users/" + props.uidState);
    const docSnap = await getDoc(docRef);

    setCpa();
    const DailyTotalRef = query(collection(db, "mediaBuyerDailyData/mediaBuyers/" + docSnap.data().employeeID), where("date", ">=", props.beginningDate), where("date", "<=", props.endingDate))
    const DailyTotals = await getDocs(DailyTotalRef);
    DailyTotals.forEach((docs) => {
      firestoreOrderDataArray.push(docs.data().orders);
      firestoreProfitDataArray.push(docs.data().total);
      firestoreCpaDataArray.push(docs.data().cpa);
      firestoreDateDataArray.push(docs.id);
    })//END OF FOR EACH 
    setCpa(firestoreCpaDataArray)
    setDates(firestoreDateDataArray);
    totalOrders = firestoreOrderDataArray.reduce((a, b) => a + b, 0).toFixed(2)
    totalProfit = firestoreProfitDataArray.reduce((a, b) => a + b, 0).toFixed(2)
    setTotalCpa((totalProfit / totalOrders).toFixed(2));
  }// END OF ASYNC GET DAILY TOTALS FUNCTION


  return (
    <div className="cpaCard"> <br/>

<table>
      <tbody>
        <tr>
          <td>
          <span className='cpaCardTitle'>AVG CPA</span><br/>
          <span className='cpaCardQuantity'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(totalCpa)}</span>
          </td>
          <td>
            <div className='chartContainer'>
            <Line width={"150px"} height={"70px"}
              data={{
    labels: dates,
    datasets: [
      {
        data: cpa,
        backgroundColor: '#ffffff',
        borderColor: '#ffffff',
      },
    ],
              }}
              options={{
    events: [],
    
    plugins: {
      legend: {
          display: false
      },
    },

    scales: {
      x: {
        display: false,
        grid: {
          display: false
        }
      },
      y: {
        beginAtZero: false,
        display: false,
        grid: {
          display: false
        }
      },
    },

    elements: {
      point:{
          radius: 0
      }
  }

              }}
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <br/>

  </div>
  )
}
export default CpaChart;
