import React, {useState, useEffect, useContext} from "react";
import NavbarProductPage from './NavbarProductPage';
import Navbar from './Navbar';
import Grid from '@mui/material/Grid';
import BusinessEmployees from "./BusinessComponents/BusinessEmployees";
import BusinessTrafficSources from "./BusinessComponents/BusinessTrafficSources";
import BusinessFaceBookBMs from "./BusinessComponents/BusinessFacebookBM";
import BusinessFBAdAccounts from "./BusinessComponents/BusinessFBAdAccounts";
import BusinessFBProfiles from "./BusinessComponents/BusinessFBProfiles";
import {UserTypeContext} from "../Context";
import BusinessAssignFBAdAccountsToMediaBuyers from "./BusinessComponents/BusinessAssignFBAdAccountsToMediaBuyers";








function Business() {
  const {userType} = useContext(UserTypeContext);






  return (
      <div>

        <Navbar pageTitle="BUSINESS SETTINGS" date={false} todayRefresh={true} yesterdayRefresh={true}/>
   
     <br/>

{userType == "Media Buyer" || userType == "Accounting" || userType == "Administrative" ? 
<Grid container spacing={2}>
  <Grid item xs={2}></Grid>
  <Grid item xs={4}>
    <BusinessEmployees/>
  </Grid>
  <Grid item xs={4}>
    <BusinessTrafficSources/>
  </Grid>
  <Grid item xs={2}></Grid>
  <Grid item xs={2}></Grid>
  <Grid item xs={4}>
    <BusinessFaceBookBMs/>
  </Grid>
  <Grid item xs={4}>
    <BusinessAssignFBAdAccountsToMediaBuyers/>
  </Grid>
  <Grid item xs={2}></Grid>
  <Grid item xs={2}></Grid>

</Grid>
   : <><br/><center>Your account type is {userType} so you are not permitted to access this page. Please contact an administrator if you need administrative priviledges.</center></>}


    </div>
  );
}

export default Business;
