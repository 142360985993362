
import React, {useState, useEffect, useContext} from "react";
import {UserTypeContext} from "../Context";
import { db } from './FirebaseInit';
import {doc, onSnapshot, collection, setDoc, addDoc, updateDoc, getDoc, where, query, getDocs, Timestamp} from "firebase/firestore";
import Avatar from '@mui/material/Avatar';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import 'chart.js/auto';
import './Commission.css'
import CircularProgress from '@mui/material/CircularProgress';




function Commission() {
  const {userType} = useContext(UserTypeContext);
  const [uidState, setUidState] = useState('');
  const [employeeID, setEmployeeID] = useState('');


var commissionBasedEmployees = [];

var allDataArray = {users: []};
const [allData, setAllData] = useState({users: []});

var beginningDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1);
var endingDate = new Date(new Date().getFullYear(), new Date().getMonth());

//console.log(beginningDate + " => " + endingDate)

const [dropdownDates, setDropdownDates] = useState([]);

var dropdownDatesArray = [];

const [dateDropdownSelection, setDateDropdownSelection] = useState({
  beginningDate: beginningDate.getFullYear() + '-' + (beginningDate.getMonth() + 1).toString().padStart(2, '0') + '-' + (beginningDate.getDate().toString()).padStart(2, '0'),
  endingDate: endingDate.getFullYear() + '-' + (endingDate.getMonth() + 1).toString().padStart(2, '0') + '-' + (endingDate.getDate().toString()).padStart(2, '0'),
})

//Chart 1
const [ordersState, setOrdersState] = useState([]);
const [employeesState, setEmployeesState] = useState([]);
const [avatarColorState, setAvatarColorState] = useState([]);

//Chart 2 
const [profitToCommissionDistribution, setProfitToCommissionDistribution] = useState(['Sendwell', 'Media Buyers'])
var totalCommissionPaid = 0;
var totalMonthsProfit = 0;
const [profitToCommissionValues, setProfitToCommissionValues] = useState([0, 0])

//console.log(ordersState)
//console.log(employeesState)

function dateManipulations () {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  for (let i = 0; i < 24; i++) {
    var d = new Date(new Date().getFullYear(), new Date().getMonth() - i);
    var e = new Date(new Date().getFullYear(), new Date().getMonth() - i + 1);

    var dates = {
      beginningDate: d.getFullYear() + '-' + (d.getMonth() + 1).toString().padStart(2, '0') + '-' + (d.getDate().toString()).padStart(2, '0'),
      endingDate: e.getFullYear() + '-' + (e.getMonth() + 1).toString().padStart(2, '0') + '-' + (e.getDate().toString()).padStart(2, '0'),
      displayDate: months[new Date(d).getMonth()] + ' ' + new Date(d).getFullYear()
    }

    dropdownDatesArray.push(dates)
    //console.log(dates)
  }
  setDateDropdownSelection(dropdownDatesArray[1])
  setDropdownDates(dropdownDatesArray)
}










async function getCommissionData (employeeIDParam) {
  var ordersArray = []
  var employeeArray = []
  var avatarColorArray = []

  var commissionBasedEmployeesQuery = '';
  var commissionBasedEmployeesSnapshot = '';

  if (userType == "Administrative" || userType == "Accounting") {
    commissionBasedEmployeesQuery = query(collection(db, "employees"), where("onCommission", "==", true));
    commissionBasedEmployeesSnapshot = await getDocs(commissionBasedEmployeesQuery);
    commissionBasedEmployeesSnapshot.forEach((doc) => {
      console.log("hi")
      commissionBasedEmployees.push({
        employeeID: doc.id, 
        employeeName: doc.data().employeeName, 
        commissionOnProfit: doc.data().commissionOnProfit, 
        commissionOnOrders: doc.data().commissionOnOrders, 
        commissionShareLosses: doc.data().commissionShareLosses, 
        avatarColor: '#' + doc.data().avatarColor})
    });
  } else if (userType == "Media Buyer") {
    commissionBasedEmployeesQuery = query(collection(db, "employees"), where("id", "==", +employeeIDParam));
    commissionBasedEmployeesSnapshot = await getDocs(commissionBasedEmployeesQuery);
    commissionBasedEmployeesSnapshot.forEach((doc) => {
      console.log("hi")

      commissionBasedEmployees.push({
        employeeID: doc.id, 
        employeeName: doc.data().employeeName, 
        commissionOnProfit: doc.data().commissionOnProfit, 
        commissionOnOrders: doc.data().commissionOnOrders, 
        commissionShareLosses: doc.data().commissionShareLosses, 
        avatarColor: '#' + doc.data().avatarColor})
    });
  }






  
  for (let i = 0; i < commissionBasedEmployees.length; i++) {

    var tempAdSpend = 0;
    var tempOrders = 0;
    var tempRevenue = 0;
    var tempSubscribers = 0;
    var tempProfit = 0;

    const docRef = query(collection(db, "mediaBuyerDailyData", 'mediaBuyers', commissionBasedEmployees[i].employeeID), where("date", ">=", dateDropdownSelection.beginningDate), where("date", "<", dateDropdownSelection.endingDate));
    const docSnap = await getDocs(docRef);
    docSnap.forEach((doc) => {
      tempAdSpend = tempAdSpend + doc.data().adspend;
      tempOrders = tempOrders + doc.data().orders;
      tempRevenue = tempRevenue + doc.data().revenue;
      tempSubscribers = tempSubscribers + doc.data().subscribers;
      tempProfit = tempProfit + doc.data().total;

      //console.log(doc.data())
    });

    var commission = 0;
    if (commissionBasedEmployees[i].commissionShareLosses == false){
      if (tempProfit > 0) {
        commission = (commissionBasedEmployees[i].commissionOnProfit * tempProfit) + (commissionBasedEmployees[i].commissionOnOrders * tempOrders)
      } else {
        commission = (commissionBasedEmployees[i].commissionOnOrders * tempOrders)
      }
    } else {
      commission = (commissionBasedEmployees[i].commissionOnProfit * tempProfit) + (commissionBasedEmployees[i].commissionOnOrders * tempOrders)
    }
    
    totalCommissionPaid = totalCommissionPaid + commission;
    totalMonthsProfit = totalMonthsProfit + tempProfit;


    allDataArray.users[i] = {
      employeeID: commissionBasedEmployees[i].employeeID,
      employeeName: commissionBasedEmployees[i].employeeName,
      avatarColor: commissionBasedEmployees[i].avatarColor,
      adSpend: tempAdSpend,
      orders: tempOrders,
      revenue: tempRevenue,
      subscribers: tempSubscribers,
      profit: tempProfit,
      cpa: tempProfit / tempOrders,
      commission: commission,
    }

    ordersArray.push(tempOrders);
    employeeArray.push(commissionBasedEmployees[i].employeeName);
    avatarColorArray.push(commissionBasedEmployees[i].avatarColor);
  }

  setAllData(allDataArray);
  setOrdersState(ordersArray);
  setEmployeesState(employeeArray);
  setAvatarColorState(avatarColorArray)

  setProfitToCommissionValues([totalMonthsProfit - totalCommissionPaid, totalCommissionPaid])

}


useEffect(() => {
  dateManipulations();
}, [])

useEffect(() => {
  setAllData({users: []});
  const auth = getAuth();
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      setUidState(user.uid); 
      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);  
      setEmployeeID(docSnap.data().employeeID) 
      getCommissionData(docSnap.data().employeeID);
    }
  });

}, [dateDropdownSelection]) 

useEffect(() => {
  const auth = getAuth();
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);  
      getCommissionData(docSnap.data().employeeID);
    }
  });

}, [userType]) 


function signOutUser() {
  const auth = getAuth();
  signOut(auth).then(() => {
    // Sign-out successful.
  }).catch((error) => {
    // An error happened.
  });
}


  return (
    <div className="commissionBackground">

<div className="dashboardDrawer">
      <div className="dashboardDrawerTitleContainer">
        <span className="dashboardDrawerTitle">Sendwell Stats</span>
      </div>

      <div>
        <div className="dashboardDrawerSectionTitle">
          <span>General</span>
        </div>
        <div className="dashboardDrawerSection">
          <a href="/Dashboard" className="dashboardDrawerLinkTitle">Dashboard</a>
        </div>
        <div className="dashboardDrawerSection">
          <a href="/ProductIdeas" className="dashboardDrawerLinkTitle">Product Ideas</a>
        </div>

        <div className="dashboardDrawerSectionTitle">
          <span>Finances</span>
        </div>
        <div className="dashboardDrawerSection">
          <a href="/Accounting" className="dashboardDrawerLinkTitle">Accounting</a>
        </div>
        <div className="dashboardDrawerSection">
          <a href="/AccountBalances" className="dashboardDrawerLinkTitle">Account Balances</a>
        </div>
        <div className="dashboardDrawerSection">
          <a href="/Analytics" className="dashboardDrawerLinkTitle">Analytics</a>
        </div>
        <div className="dashboardDrawerSection activeDashboardDrawerSection">
          <a href="/Commission" className="activeDashboardDrawerLinkTitle">Commission</a>
        </div>

        <div className="dashboardDrawerSectionTitle">
          <span>Management</span>
        </div>
        <div className="dashboardDrawerSection">
          <a href="/UserSettings" className="dashboardDrawerLinkTitle">User Settings</a>
        </div>
        <div className="dashboardDrawerSection">
          <a href="/UserPermissions" className="dashboardDrawerLinkTitle">User Permissions</a>
        </div>
        <div className="dashboardDrawerSection">
          <a href="/BusinessSettings" className="dashboardDrawerLinkTitle">Business Settings</a>
        </div>

        <div className="dashboardDrawerSectionTitle">
          <span></span>
        </div>
        <div className="dashboardDrawerSectionSignOut" onClick={() => signOutUser()}>
          <span className="dashboardDrawerLinkTitleSignOut">Sign Out</span>
        </div>
      </div>

    </div>
    
      <div className="commissionContainer">
        <div className="commissionDateSelectionContainer">
          {/*<Select value={dateDropdownSelection} onChange={(event) => {setDateDropdownSelection(event.target.value)}}>*/}
            {dropdownDates.map((a) => {
              return (
                <div key={a.displayDate} className={dateDropdownSelection == a ? "activeCommissionDateSelection" : "commissionDateSelection"} onClick={() => {setDateDropdownSelection(a)}}>
                  <span>{a.displayDate}</span>
                </div>
              )
            })}
          {/*} </Select>*/}
        </div>

        <span className="commissionDateSelectionTitle">{dateDropdownSelection.displayDate}</span>

        {allData.users.length != 0 ? <>

        <table className="commissionTable">
          <thead>
            <tr>
              <td></td>
              <td className="commissionTableHeaders">Employee</td>
              <td className="commissionTableHeaders">Orders</td>
              <td className="commissionTableHeaders">Revenue</td>
              <td className="commissionTableHeaders">Advertising</td>
              <td className="commissionTableHeaders">Profit</td>
              <td className="commissionTableHeaders">Subscribers</td>
              <td className="commissionTableHeaders">CPA</td>
              <td className="commissionTableHeaders">Commission</td>
            </tr>
          </thead>
          <tbody>
            {allData.users.map((a) => {
              return (
                <tr key={a.employeeID}>
                  <td><Avatar sx={{ bgcolor: a.avatarColor }}>{a.employeeName[0]}</Avatar></td>
                  <td>{a.employeeName}</td>
                  <td>{new Intl.NumberFormat().format(a.orders)}</td>
                  <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.revenue)}</td>
                  <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.adSpend)}</td>
                  <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.profit)}</td>
                  <td>{new Intl.NumberFormat().format(a.subscribers)}</td>
                  <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.cpa)}</td>
                  <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.commission)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>

        {userType == "Administrative" || userType == "Accounting" ?
        <table className="commissionChartsTable">
          <tbody>
            <tr>
              <td>
                <div className="commissionOrdersChart">
                  <Doughnut
                    data={{
                    labels: employeesState,
                    datasets: [
                      {
                        data: ordersState,
                        backgroundColor: avatarColorState,
                      },
                    ],
                              }}
                              options={{
                    responsive: true,
                    maintainAspectRatio: true,
              
          
                  

              

                    }}
                  />
                </div>
              </td>
              <td className="commissionChartsTableSpacer"></td>
              <td>
                <div className="commissionProfitBreakdownChart">
                  <Doughnut
                      data={{
                      labels: profitToCommissionDistribution,
                      datasets: [
                        {
                          data: profitToCommissionValues,
                          backgroundColor: ['rgb(255, 205, 86)', 'rgb(255, 99, 132)'],
                        },
                      ],
                                }}
                                options={{
                      responsive: true,
                      maintainAspectRatio: true,
                
            
                    

                

                      }}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        :<></>}
        




        </>:<>
          <div className="commissionLoadingConatiner">
            <CircularProgress color="success"/><br/><br/>
            <span>Loading Commission Data</span>
          </div>
        </>}

      </div>
    </div>

     
    
 
  );
}





export default Commission;
