import React, {useState, useEffect} from "react";
import NavbarProductPage from './NavbarProductPage';
import { functions } from "./FirebaseInit";
import { getFunctions, httpsCallable } from "firebase/functions";
import FunnelStatistics from "./FunnelStatistics";





export default function Notes() {

const [campaignList, setCampaignList] = useState([]);
const campaignListArr = [];

  fetch("https://graph.facebook.com/v14.0/act_1028927301365929?fields=campaigns%7Bid%2Cname%7D&access_token=EAAGMKq32sUoBAHEvPuDb20JAvzD1ZBr8wc12JOMsQUwyZBPkZCabYRsfvHf9iR8BBUgEOIIuY7ZCU93EIIB1ZALZA2AzSvf8ZCp15h2gOe033HMt4c421jfZAtN8qEkgf583AupsiapqC7u2b6XGj6W9rZANUPJBhzHHMQZCGhHRDn4VWx84kD0FSd")
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      let campaigns = data;
     
      console.log(data)

      for(let i = 0; i < campaigns.campaigns.data.length; i++) {
        console.log(campaigns.campaigns.data[i].id + " => " + campaigns.campaigns.data[i].name)
        campaignListArr.push(campaigns.campaigns.data[i].name)
      }

   
    })



  return (
      <div>

        <NavbarProductPage/>
        
       {campaignList.map((a) =>
       <>
       <span>{a}</span><br/>
       </>
       )}

 
    </div>
  );
}
