import React, {useState, useEffect, useContext} from "react";
import { db } from './FirebaseInit';
import {doc, onSnapshot, collection, setDoc, addDoc, updateDoc, getDoc, getDocs, arrayUnion, query, where, orderBy, deleteDoc, limit} from "firebase/firestore";
import './AccountingDetailsTable.css'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Divider } from "@mui/material";
import ProductSummary from './ProductSummary';
import {FunnelContext} from "../Context";
import Tooltip from '@mui/material/Tooltip';
import trashIcon from '../images/close.png';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { getAuth, signOut } from "firebase/auth";
import AccountingDetailsSummary from "./AccountingDetailsSummary";
import CircularProgress from '@mui/material/CircularProgress';






function AccountingDetailsTable(props) {
    const [daysOfWeek, setDaysOfWeek] = useState(['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'])

    var yesterdaysDate = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000);
    var yesterdayYear = yesterdaysDate.getFullYear();
    var yesterdayMonth = ("0" + (yesterdaysDate.getMonth() + 1)).slice(-2);
    var yesterdayDayNumber = ("0" + (yesterdaysDate.getDate())).slice(-2);
    var yesterdaysFormattedDate = yesterdayYear + "-" + yesterdayMonth + "-" + yesterdayDayNumber;

    const { selectedFunnel } = useContext(FunnelContext);

    const [showAll, setShowAll] = useState(false)

    //states for writing to database
    const [addDailyDate, setAddDailyDate] = useState(yesterdaysFormattedDate);
    const [addDailyOrders, setAddDailyOrders] = useState(0);
    const [addDailyRevenue, setAddDailyRevenue] = useState(0);
    const [addDailyAdSpend, setAddDailyAdSpend] = useState(0);
    const [addDailyProductCost, setAddDailyProductCost] = useState(0);
    const [addDailySubscribers, setAddDailySubscribers] = useState(0);
    const [addDailyNotes, setAddDailyNotes] = useState('');

    //state for reading all data from the database
    const [getDailyInfo, setGetDailyInfo] = useState([]);

    //Getting Funnel Name
    const [getFunnelName, setGetFunnelName] = useState('');
    const [getMediaBuyer, setGetMediaBuyer] = useState();
    const [getCashback, setGetCashback] = useState();
    const [getMerchantFees, setGetMerchantFees] = useState();
    const [getMerchantPerOrderFees, setGetMerchantPerOrderFees] = useState();

    //column settings
    const [columnSettings, setColumnSettings] = useState({});

    //setting to show "No Data" text if a funnel doesnt have any financial data yet
    const [data, setData] = useState(true)

    //variable to control the refresh box if data hasnt been updated in more than 5 min
    const [refreshBox, setRefreshBox] = useState(false);
    const [updateTrigger, setUpdateTrigger] = useState(0)

    //uploading new date to database
    var year = 0;
    var month = 0;
    var day = 0;

    //reformatting date input 
    useEffect( () => {
        year = addDailyDate.slice(0,4);
        month = addDailyDate.slice(5,7);
        day = addDailyDate.slice(8,10);

    }, [addDailyDate])

    //getting data from the text fields to upload to the database
    const dailyData = {
        funnelID: selectedFunnel,
        funnelName: getFunnelName,
        date: addDailyDate,
        orders: parseInt(addDailyOrders),
        revenue: parseFloat(addDailyRevenue),
        adSpend: parseFloat(addDailyAdSpend),
        productCost: parseFloat(addDailyProductCost), 
        subscribers: parseInt(addDailySubscribers),
        fees: ((parseFloat(addDailyRevenue) * (getMerchantFees / 100)) + (parseInt(addDailyOrders) * getMerchantPerOrderFees) + (parseFloat(addDailyAdSpend) * (getCashback / 100))),
        costPerConversion: (parseFloat(addDailyAdSpend) / parseInt(addDailyOrders)), 
        roas: (parseFloat(addDailyRevenue) / parseFloat(addDailyAdSpend)),
        total: (parseFloat(addDailyRevenue) - ((parseFloat(addDailyAdSpend) + parseFloat(addDailyProductCost)) - ((parseFloat(addDailyRevenue) * (getMerchantFees / 100)) + (parseInt(addDailyOrders) * getMerchantPerOrderFees) + (parseFloat(addDailyAdSpend) * (getCashback / 100))))),
        cpa: ((parseFloat(addDailyRevenue) - ((parseFloat(addDailyAdSpend) + parseFloat(addDailyProductCost)) - ((parseFloat(addDailyRevenue) * (getMerchantFees / 100)) + (parseInt(addDailyOrders) * getMerchantPerOrderFees) + (parseFloat(addDailyAdSpend) * (getCashback / 100))))) / parseInt(addDailyOrders)),
        mediaBuyer: getMediaBuyer,
        notes: addDailyNotes
    }

    //on page load 
    useEffect( () => {
        getViewColumnSettings();

    }, [])


    //get view column settings 
    async function getViewColumnSettings() {
        onSnapshot(doc(db, "users", getAuth().currentUser.uid, "viewSettings", "viewSettings"), (doc) => {
            setColumnSettings(doc.data());
        });
    }
    
    //submitting the daily financial data to database 
    async function submitDailyOrders () {
        if (addDailyOrders !== "" && addDailyRevenue !== "" && addDailyAdSpend !== "" && addDailySubscribers !== "") {
            await updateDoc(doc(db, "funnels", selectedFunnel, "funnelData", addDailyDate), dailyData);
        }
        
    }


    //console.log(selectedFunnel + " " + new Date().getUTCHours() + " : " + new Date().getUTCMinutes() + " : " + new Date().getUTCSeconds() )

    //getting all daily financial data from database 
    useEffect(() => {
        setGetDailyInfo([]);
        setData(true)
        //this code fires when the app loads
        if (showAll == false) {
            const queryRef = query(
                collection(db, "funnels", selectedFunnel, "funnelData"),
                orderBy("date", "desc"),
                limit(100)
              );
              
              getDocs(queryRef)
                .then((querySnapshot) => {
                  const data = querySnapshot.docs.map((doc) => doc.data());
                  if (data.length !== 0) {
                    setGetDailyInfo(data);
                    setData(true);
                    //console.log('running');
                  } else {
                    setGetDailyInfo([]);
                    setData(false);
                  }
                })
                .catch((error) => {
                  console.error("Error getting documents:", error);
                  // Handle the error appropriately, e.g., setGetDailyInfo([]) and setData(false)
                });
        } else {
            const queryRef = query(
                collection(db, "funnels", selectedFunnel, "funnelData"),
                orderBy("date", "desc")
              );
              
              getDocs(queryRef)
                .then((querySnapshot) => {
                  const data = querySnapshot.docs.map((doc) => doc.data());
                  if (data.length !== 0) {
                    setGetDailyInfo(data);
                    setData(true);
                    //console.log('running');
                  } else {
                    setGetDailyInfo([]);
                    setData(false);
                  }
                })
                .catch((error) => {
                  console.error("Error getting documents:", error);
                  // Handle the error appropriately, e.g., setGetDailyInfo([]) and setData(false)
                });
        }

        onSnapshot(doc(db, "funnels", selectedFunnel), (doc) => {
            setGetFunnelName(doc.data().funnelName);
            setGetMediaBuyer(doc.data().mediaBuyer);
            setGetCashback(doc.data().cashbackOnAds);
            setGetMerchantFees(doc.data().merchantFees);
            setGetMerchantPerOrderFees(doc.data().merchantFeesPerOrder)
        });
   
        setAddDailyOrders(0)
        setAddDailyRevenue(0);
        setAddDailyAdSpend(0);
        setAddDailyProductCost(0);
        setAddDailySubscribers(0);

        onSnapshot(doc(db, "funnels", selectedFunnel , "funnelData", addDailyDate), (doc) => {
            if(doc.data().orders == undefined) {setAddDailyOrders(0);} else {setAddDailyOrders(doc.data().orders);}  
            if(doc.data().revenue == undefined) {setAddDailyRevenue(0);} else {setAddDailyRevenue(doc.data().revenue);}  
            if(doc.data().adSpend == undefined) {setAddDailyAdSpend(0);} else {setAddDailyAdSpend(doc.data().adSpend);}  
            if(doc.data().productCost == undefined) {setAddDailyProductCost(0);} else {setAddDailyProductCost(doc.data().productCost);}  
            if(doc.data().subscribers == undefined) {setAddDailySubscribers(0);} else {setAddDailySubscribers(doc.data().subscribers);}  

          })


            // Set a timer that expires after 5 minutes (300,000 milliseconds)
            const timer = setTimeout(() => {
                setRefreshBox(true);
            }, 300000);
        
            setRefreshBox(false)

            // Cleanup function to clear the timer if the component unmounts or re-renders
            return () => clearTimeout(timer);

        }, [selectedFunnel, showAll, updateTrigger])

    //console.log(selectedFunnel)

    //getting funnel totals
    var iterations = 0;
    var ordersTotal = 0;
    var revenueTotal = 0;
    var adsSpendTotal = 0;
    var productCostTotal = 0;
    var feesTotal = 0;
    var CPCTotal = 0;
    var ROASTotal = 0;
    var totalTotal = 0;
    var subscribersTotal = 0;
    var CPATotal= 0;
    
   
    getDailyInfo.map((dailyInfo) => {
    iterations = iterations + 1;
    ordersTotal = ordersTotal + dailyInfo.orders
    revenueTotal = revenueTotal + dailyInfo.revenue
    adsSpendTotal = adsSpendTotal + dailyInfo.adSpend
    productCostTotal = productCostTotal + dailyInfo.productCost
    feesTotal = feesTotal + dailyInfo.fees
    CPCTotal = CPCTotal + dailyInfo.costPerConversion
    ROASTotal = ROASTotal + dailyInfo.roas
    totalTotal = totalTotal + dailyInfo.total
    subscribersTotal = subscribersTotal + dailyInfo.subscribers
    CPATotal = CPATotal + dailyInfo.cpa
    })



        function changeDate(value) {
            setAddDailyDate(value)

            setAddDailyOrders(0)
            setAddDailyRevenue(0);
            setAddDailyAdSpend(0);
            setAddDailyProductCost(0);
            setAddDailySubscribers(0);
    
    
            onSnapshot(doc(db, "funnels", selectedFunnel , "funnelData", value), (doc) => {
                if(doc.data().orders == undefined) {setAddDailyOrders(0);} else {setAddDailyOrders(doc.data().orders);}  
                if(doc.data().revenue == undefined) {setAddDailyRevenue(0);} else {setAddDailyRevenue((doc.data().revenue).toFixed(2));}  
                if(doc.data().adSpend == undefined) {setAddDailyAdSpend(0);} else {setAddDailyAdSpend((doc.data().adSpend).toFixed(2));}  
                if(doc.data().productCost == undefined) {setAddDailyProductCost(0);} else {setAddDailyProductCost((doc.data().productCost).toFixed(2));}  
                if(doc.data().subscribers == undefined) {setAddDailySubscribers(0);} else {setAddDailySubscribers(doc.data().subscribers);}  
              })
        }  
        //console.log(addDailyOrders)

        const handleOrdersTextBoxChange = event => {setAddDailyOrders(event.target.value)}
        const handleRevenueTextBoxChange = event => {setAddDailyRevenue(event.target.value)}
        const handleAdSpendTextBoxChange = event => {setAddDailyAdSpend(event.target.value)}
        const handleProductCostTextBoxChange = event => {setAddDailyProductCost(event.target.value)}
        const handleSubscribersTextBoxChange = event => {setAddDailySubscribers(event.target.value)}
        const handleNotesTextBoxChange = event => {setAddDailyNotes(event.target.value)}


        async function deleteData() {
            await deleteDoc(doc(db, "funnels", selectedFunnel, "funnelData", addDailyDate));
        }

        

   return(
    <div className="accountingTableMainDiv">

{props.showManualInputTable == true ? <>
        <table className="tableJSInputFieldsTable">
            <tbody>
                <tr>
                    <td className="tableJSInputFields"><TextField size="small" className="tableJSInputFields" id="date" value={addDailyDate} label="Date" onChange={event => changeDate(event.target.value)} type="date" InputLabelProps={{ shrink: true,}}/></td>
                    <td className="tableJSInputFields"><TextField size="small" className="tableJSInputFields"type="number" value={addDailyOrders} onChange={handleOrdersTextBoxChange} id="outlined-basic" label="Orders" variant="outlined" /></td>
                    <td className="tableJSInputFields"><TextField size="small" className="tableJSInputFields"type="number" value={addDailyRevenue} onChange={handleRevenueTextBoxChange} id="outlined-basic" label="Revenue" variant="outlined" InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}} /></td>
                    <td className="tableJSInputFields"><TextField size="small" className="tableJSInputFields"type="number" value={addDailyAdSpend} onChange={handleAdSpendTextBoxChange} id="outlined-basic" label="Facebook Spend" variant="outlined" InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}/></td>
                    <td className="tableJSInputFields"><TextField size="small" className="tableJSInputFields"type="number" value={addDailyProductCost} onChange={handleProductCostTextBoxChange} id="outlined-basic" label="Product Cost" variant="outlined" InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}/></td>
                    <td className="tableJSInputFields"><TextField size="small" className="tableJSInputFields"type="number" value={addDailySubscribers} onChange={handleSubscribersTextBoxChange} id="outlined-basic" label="Subscribers" variant="outlined" /></td>
                    <td className="tableJSInputFields"><TextField size="small" className="tableJSInputFields"type="text" value={addDailyNotes} onChange={handleNotesTextBoxChange} id="outlined-basic" label="Notes" variant="outlined" /></td>
                    <td className="tableJSInputFields"><Button size="medium" className="tableJSInputFields"variant="contained" color="primary" onClick={submitDailyOrders}>Add New Data</Button></td> 
                    <td className="tableJSInputFields"><Button size="medium" className="tableJSInputFields"variant="contained" color="error" onClick={deleteData}>Delete Data</Button></td> 
                </tr>
            </tbody>
        </table>
        <br/>
        </>:<></>}

      {data == true ? <>
      
        {getDailyInfo.length != 0 ? <>

       <AccountingDetailsSummary className="productSummaryContainer" funnelName={getFunnelName} ordersTotal={ordersTotal} revenueTotal={revenueTotal.toFixed(2)} adsSpendTotal={adsSpendTotal.toFixed(2)} productCostTotal={productCostTotal.toFixed(2)} feesTotal={feesTotal.toFixed(2)} CPCAverage={(adsSpendTotal / ordersTotal).toFixed(2)} ROASTotal={(revenueTotal / adsSpendTotal).toFixed(2)} totalTotal={totalTotal.toFixed(2)} subscribersTotal={subscribersTotal} CPATotal={(totalTotal / ordersTotal).toFixed(2)} feesLabel={getMerchantFees + "% of Revenue plus " + getCashback + "% of Ad Spend"}/><br />

            

       <div className="tableJSMainTableContainer">
        <table className="tableJSMainTable">
        <thead>
                <tr className="tableJSHeaderRow">
                    <th><span className="AccountingDetailsTableHeader">Date</span></th>
                    {columnSettings.orderColumn == true ? <th><span className="AccountingDetailsTableHeader">Orders</span></th>:<></>}
                    {columnSettings.revenueColumn == true ? <th><span className="AccountingDetailsTableHeader">Revenue</span></th>:<></>}
                    {columnSettings.adspendColumn == true ? <th><span className="AccountingDetailsTableHeader">Ads</span></th>:<></>}
                    {columnSettings.COGSColumn == true ? <th><span className="AccountingDetailsTableHeader">COGS</span></th>:<></>}
                    {columnSettings.feesColumn == true ? <th><Tooltip title={getMerchantFees + "% of Revenue plus " + getCashback + "% of Ad Spend"} placement="right"><span className="AccountingDetailsTableHeader">Fees</span></Tooltip></th>:<></>}
                    {columnSettings.CPCColumn == true ? <th className="tableJSHideColsOnMobile"><span className="AccountingDetailsTableHeader">CPC</span></th>:<></>}
                    {columnSettings.ROASColumn == true ? <th className="tableJSHideColsOnMobile"><span className="AccountingDetailsTableHeader">ROAS</span></th>:<></>}
                    {columnSettings.AOVColumn == true ? <th className="tableJSHideColsOnMobile"><span className="AccountingDetailsTableHeader">AOV</span></th>:<></>}

                    {columnSettings.impressionsColumn == true ? <th className="tableJSHideColsOnMobile"><span className="AccountingDetailsTableHeader">Imp.</span></th>:<></>}
                    {columnSettings.clicksColumn == true ? <th className="tableJSHideColsOnMobile"><span className="AccountingDetailsTableHeader">Clicks</span></th> : <></>}
                    {columnSettings.CTRColumn == true ? <th className="tableJSHideColsOnMobile"><Tooltip title={"Click Through Rate: Ad Clicks / Ad Views"} placement="right"><span className="AccountingDetailsTableHeader">CTR</span></Tooltip></th>:<></>}
                    {columnSettings.CPMColumn == true ? <th className="tableJSHideColsOnMobile"><Tooltip title={"Cost Per 1,000 Impressions"} placement="right"><span className="AccountingDetailsTableHeader">CPM</span></Tooltip></th>:<></>}
                    {columnSettings.CVRColumn == true ? <th className="tableJSHideColsOnMobile"><Tooltip title={"Estimated Metric: Orders / Ad Clicks"} placement="right"><span className="AccountingDetailsTableHeader">CVR*</span></Tooltip></th>:<></>}


                    {columnSettings.profitColumn == true ? <th><span className="AccountingDetailsTableHeader">Profit</span></th>:<></>}
                    {columnSettings.subscribersColumn == true ? <th><span className="AccountingDetailsTableHeader">Subs</span></th>:<></>}
                    {columnSettings.CPAColumn == true ? <th><span className="AccountingDetailsTableHeader">CPA</span></th>:<></>}
                    {columnSettings.notesColumn == true ? <th><span className="AccountingDetailsTableHeader">Notes</span></th>:<></>}
                </tr>
            </thead>

            <tbody>
            {getDailyInfo.map((dailyInfo) =>
            
                <tr className="AccountingDetailsRow" key={dailyInfo.date}>


                 

                        {dailyInfo.date.toString().slice(5,7) === '01' ?
                        <td className="AccountingDetailsColumn" key={dailyInfo.date + "date" + 1}><span className="tableDataText">{daysOfWeek[new Date(dailyInfo.date).getUTCDay()] + " Jan " + dailyInfo.date.toString().slice(8,10)}</span></td>:<></>}
                        {dailyInfo.date.toString().slice(5,7) === '02' ?
                        <td className="AccountingDetailsColumn" key={dailyInfo.date + "date" + 2}><span className="tableDataText">{daysOfWeek[new Date(dailyInfo.date).getUTCDay()] + " Feb " + dailyInfo.date.toString().slice(8,10)}</span></td>:<></>}
                        {dailyInfo.date.toString().slice(5,7) === '03' ?
                        <td className="AccountingDetailsColumn" key={dailyInfo.date + "date" + 3}><span className="tableDataText">{daysOfWeek[new Date(dailyInfo.date).getUTCDay()] + " Mar " + dailyInfo.date.toString().slice(8,10)}</span></td>:<></>}
                        {dailyInfo.date.toString().slice(5,7) === '04' ?
                        <td className="AccountingDetailsColumn" key={dailyInfo.date + "date" + 4}><span className="tableDataText">{daysOfWeek[new Date(dailyInfo.date).getUTCDay()] + " Apr " + dailyInfo.date.toString().slice(8,10)}</span></td>:<></>}
                        {dailyInfo.date.toString().slice(5,7) === '05' ?
                        <td className="AccountingDetailsColumn" key={dailyInfo.date + "date" + 5}><span className="tableDataText">{daysOfWeek[new Date(dailyInfo.date).getUTCDay()] + " May " + dailyInfo.date.toString().slice(8,10)}</span></td>:<></>}
                        {dailyInfo.date.toString().slice(5,7) === '06' ?
                        <td className="AccountingDetailsColumn" key={dailyInfo.date + "date" + 6}><span className="tableDataText">{daysOfWeek[new Date(dailyInfo.date).getUTCDay()] + " Jun " + dailyInfo.date.toString().slice(8,10)}</span></td>:<></>}
                        {dailyInfo.date.toString().slice(5,7) === '07' ?
                        <td className="AccountingDetailsColumn" key={dailyInfo.date + "date" + 7}><span className="tableDataText">{daysOfWeek[new Date(dailyInfo.date).getUTCDay()] + " Jul " + dailyInfo.date.toString().slice(8,10)}</span></td>:<></>}
                        {dailyInfo.date.toString().slice(5,7) === '08' ?
                        <td className="AccountingDetailsColumn" key={dailyInfo.date + "date" + 8}><span className="tableDataText">{daysOfWeek[new Date(dailyInfo.date).getUTCDay()] + " Aug " + dailyInfo.date.toString().slice(8,10)}</span></td>:<></>}
                        {dailyInfo.date.toString().slice(5,7) === '09' ?
                        <td className="AccountingDetailsColumn" key={dailyInfo.date + "date" + 9}><span className="tableDataText">{daysOfWeek[new Date(dailyInfo.date).getUTCDay()] + " Sep " + dailyInfo.date.toString().slice(8,10)}</span></td>:<></>}
                        {dailyInfo.date.toString().slice(5,7) === '10' ?
                        <td className="AccountingDetailsColumn" key={dailyInfo.date + "date" + 10}><span className="tableDataText">{daysOfWeek[new Date(dailyInfo.date).getUTCDay()] + " Oct " + dailyInfo.date.toString().slice(8,10)}</span></td>:<></>}
                        {dailyInfo.date.toString().slice(5,7) === '11' ?
                        <td className="AccountingDetailsColumn" key={dailyInfo.date + "date" + 11}><span className="tableDataText">{daysOfWeek[new Date(dailyInfo.date).getUTCDay()] + " Nov " + dailyInfo.date.toString().slice(8,10)}</span></td>:<></>}
                        {dailyInfo.date.toString().slice(5,7) === '12' ?
                        <td className="AccountingDetailsColumn" key={dailyInfo.date + "date" + 12}><span className="tableDataText">{daysOfWeek[new Date(dailyInfo.date).getUTCDay()] + " Dec " + dailyInfo.date.toString().slice(8,10)}</span></td>:<></>}


                        {columnSettings.orderColumn == true ? <td className="AccountingDetailsColumn" key={dailyInfo.date + "orders"}><span className="tableDataText ">{dailyInfo.orders == undefined ? 0 : new Intl.NumberFormat().format(dailyInfo.orders)}</span></td>:<></>}
                        {columnSettings.revenueColumn == true ? <td className="AccountingDetailsColumn" key={dailyInfo.date + "revenue"}><span className="tableDataText ">{dailyInfo.revenue == undefined ? 0 : new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(dailyInfo.revenue)}</span></td>:<></>}
                        {columnSettings.adspendColumn == true ? <td className="AccountingDetailsColumn" key={dailyInfo.date + "adspend"}><span className="tableDataText ">{dailyInfo.adSpend == undefined ? 0 : new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(dailyInfo.adSpend)}</span></td>:<></>}
                        {columnSettings.COGSColumn == true ? <td className="AccountingDetailsColumn" key={dailyInfo.date + "productCost"}><span className="tableDataText ">{dailyInfo.productCost == undefined ? 0 : new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(dailyInfo.productCost)}</span></td>:<></>}
                        {columnSettings.feesColumn == true ? <td className="AccountingDetailsColumn" key={dailyInfo.date + "fees"}><span className="tableDataText ">{dailyInfo.fees == undefined ? 0 : new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(dailyInfo.fees)}</span></td>:<></>}
                        {columnSettings.CPCColumn == true ? <td className="tableJSHideColsOnMobile AccountingDetailsColumn" key={dailyInfo.date + "costPerConversion"}><span className="tableDataText">{dailyInfo.costPerConversion == undefined ? 0 : new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(dailyInfo.costPerConversion)}</span></td>:<></>}
                        {columnSettings.ROASColumn == true ? <td className="tableJSHideColsOnMobile AccountingDetailsColumn" key={dailyInfo.date + "roas"}><span className="tableDataText">{dailyInfo.roas == undefined ? 0 : new Intl.NumberFormat().format(dailyInfo.roas.toFixed(2))}</span></td>:<></>}
                        {columnSettings.AOVColumn == true ? <td className="tableJSHideColsOnMobile AccountingDetailsColumn" key={dailyInfo.date + "aov"}><span className="tableDataText">{dailyInfo.revenue / dailyInfo.orders == undefined ? 0 : new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(dailyInfo.revenue / dailyInfo.orders)}</span></td>:<></>}
                        
                        {columnSettings.impressionsColumn == true ? <td className="tableJSHideColsOnMobile AccountingDetailsColumn" key={dailyInfo.date + "impressions"}><span className="tableDataText">{dailyInfo.impressions == undefined ? <></> : new Intl.NumberFormat().format(dailyInfo.impressions)}</span></td>:<></>}
                        {columnSettings.clicksColumn == true ? <td className="tableJSHideColsOnMobile AccountingDetailsColumn" key={dailyInfo.date + "clicks"}><span className="tableDataText">{dailyInfo.clicks == undefined ? <></> : new Intl.NumberFormat().format(dailyInfo.clicks)}</span></td>:<></>}
                        {columnSettings.CTRColumn == true ? <td className="tableJSHideColsOnMobile AccountingDetailsColumn" key={dailyInfo.date + "ctr"}><span className="tableDataText">{dailyInfo.ctr == undefined ? <></> : new Intl.NumberFormat().format((dailyInfo.ctr * 100).toFixed(2))+ "%"}</span></td>:<></>}
                        {columnSettings.CPMColumn == true ? <td className="tableJSHideColsOnMobile AccountingDetailsColumn" key={dailyInfo.date + "cpm"}><span className="tableDataText">{(dailyInfo.impressions == undefined || dailyInfo.adSpend == undefined) ? <></> : new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(dailyInfo.adSpend / dailyInfo.impressions * 1000)}</span></td>:<></>}
                        {columnSettings.CVRColumn == true ? <td className="tableJSHideColsOnMobile AccountingDetailsColumn" key={dailyInfo.date + "cvr"}><span className="tableDataText">{(dailyInfo.orders === 0 || dailyInfo.clicks === undefined) ? <></> : new Intl.NumberFormat('en-US', {style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1}).format((dailyInfo.orders / dailyInfo.clicks))}</span></td>:<></>}


                        {columnSettings.profitColumn == true ? <td className="AccountingDetailsColumn" key={dailyInfo.date + "total"}><span className="tableDataText">{dailyInfo.total == undefined ? 0 : new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(dailyInfo.total)}</span></td>:<></>}
                        {columnSettings.subscribersColumn == true ? <td className="AccountingDetailsColumn" key={dailyInfo.date + "subscribers"}><span className="tableDataText">{dailyInfo.subscribers == undefined ? 0 : new Intl.NumberFormat().format(dailyInfo.subscribers)}</span></td>:<></>}
                        {columnSettings.CPAColumn == true ? <td className="AccountingDetailsColumn" key={dailyInfo.date + "cpa"}>{dailyInfo.cpa == undefined ? 0 : <span className="tableDataText">{dailyInfo.cpa.toFixed(2) >= 0 ?
                            (<span className="tableDataText" style={{color:"green"}}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(dailyInfo.cpa)}</span>):
                            (<span className="tableDataText" style={{color:"red"}}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(dailyInfo.cpa)}</span>)
                          }</span>
                          }</td>:<></>}
                        {columnSettings.notesColumn == true ? 
                            <td className="AccountingDetailsColumn" key={dailyInfo.date + "notes"}>
                                <span className="tableDataText">{dailyInfo.notes == undefined ? '' : dailyInfo.notes}</span>
                            </td>
                        :<></>}
                        

                      
                         
                </tr>
                
            )}

            </tbody>
        </table>
       </div>

       {getDailyInfo.length == 100 ? <Button onClick={() => setShowAll(true)}>Show All</Button> : <Button onClick={() => setShowAll(false)}>Show 100</Button>}

       </>:<>
          <div className="AccountingTableLoadingContainer">
            <span className="AccountingTableLoadingText"><CircularProgress color='success' /> <br/><br/> Loading Funnel Data </span>
          </div>
       </>}

       </>:<>
          <div className="AccountingTableLoadingContainer">
            <span className="AccountingTableLoadingText"> No Funnel Data Yet... </span>
          </div>
       </>}

        {refreshBox ?
        <div className="AccountingTableRefreshButtonContainer">
          <span className="AccountingTableRefreshButtonText">Data Updated</span> 
          <div className="AccountingTableRefreshButton" onClick={() => setUpdateTrigger(updateTrigger + 1)}>Refresh</div>
        </div>
        :<></>}

       </div>
   )
}

  export default AccountingDetailsTable;