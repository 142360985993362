import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import {db} from '../FirebaseInit';
import {doc, onSnapshot, collection} from "firebase/firestore";
import {addDoc, Timestamp, setDoc, deleteDoc } from "firebase/firestore"; 


function BusinessEmployees () {

    const [addEmployeeName, setAddEmployeeName] = useState('');
    const [addEmployeeRole, setAddEmployeeRole] = useState('');

    async function submitNewEmployee () {
        const id = Date.now();

        const newEmployeeData = {
            employeeName: addEmployeeName,
            employeeRole: addEmployeeRole,
            createdAt: Timestamp.fromDate(new Date()),
            id: id,
            status: "notActivated"
        };
        setOpenNewEmployeeDialog(false)
  

        

        // Add a new document with a generated id.
        const docRef = await setDoc(doc(db, "employees/" + id), newEmployeeData);
        setName('');
    };

    

    //NEW Employee Dialog
    const [openNewEmployeeDialog, setOpenNewEmployeeDialog] = React.useState(false);

    const handleClickOpenNewEmployee = () => {
        setOpenNewEmployeeDialog(true);
    };
  
    const handleCloseNewEmployee = (event, reason) => {
      if (reason !== 'backdropClick') {
        setOpenNewEmployeeDialog(false);
        setName('');
      }
    };


    //NEW Employee Dropdown
    const [name, setName] = React.useState('');
  
    const handleChangeNewEmployeeRole = (event) => {
      setName(event.target.value);
      setAddEmployeeRole(event.target.value);
    };
  



    //DELETE Employee
    const [openDeleteEmployee, setOpenDeleteEmployee] = useState(false);
    const [deleteEmployeeID, setDeleteEmployeeID] = useState('');
 
  
    const handleClickOpenDeleteEmployee = () => {
      setOpenDeleteEmployee(true);
    };
  
    const handleCloseDeleteEmployee = (event, reason) => {
      if (reason !== 'backdropClick') {
        setOpenDeleteEmployee(false);
      }
    };

    async function deleteEmployee () {
        setOpenDeleteEmployee(false);

        await deleteDoc(doc(db, "employees/" + deleteEmployeeID));
    }



    
//GETTING ALL EMPLOYEES
const [getEmployees, setGetEmployees] = useState([]);

useEffect(() => {
        //this code fires when the app loads
        onSnapshot(collection(db, "employees"), (snapshot) => {
          setGetEmployees(snapshot.docs.map(doc => doc.data()));
        }
        )}, [])
        
    
return (
<>
<Card>
        <CardContent>
            <table width="100%">
                <tbody><tr>
                    <td><strong>Employees</strong></td>
                    <td><p align="right"><Button  onClick={handleClickOpenNewEmployee} variant="outlined">New Employee</Button></p></td>
                </tr></tbody>
            </table>


            <table>
                <tbody>
                {getEmployees.map((employee) =>
                    <tr key={employee.id}><td>{employee.employeeName} ({employee.employeeRole})</td></tr>
                )}
                </tbody>
            </table>
            <br/>




      <Dialog disableEscapeKeyDown open={openNewEmployeeDialog} onClose={handleCloseNewEmployee}>
        <DialogTitle>New Employee</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
            <TextField onChange={event => setAddEmployeeName(event.target.value)} id="outlined-basic" label="Employee Name" variant="outlined" />

            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-dialog-select-label">Role</InputLabel>
              <Select labelId="demo-dialog-select-label" id="demo-dialog-select" value={name} onChange={handleChangeNewEmployeeRole} input={<OutlinedInput label="Role" />}>
                <MenuItem value={"Customer Service"}>Customer Service</MenuItem>
                <MenuItem value={"Funnel Creator"}>Funnel Creation</MenuItem>
                <MenuItem value={"Media Buyer"}>Media Buying</MenuItem>
                <MenuItem value={"Accounting"}>Accounting</MenuItem>
                <MenuItem value={"Administrative"}>Administrative</MenuItem>
                <MenuItem value={"Other"}>Other</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewEmployee}>Discard</Button>
          <Button onClick={submitNewEmployee}>Save Employee</Button>
        </DialogActions>
      </Dialog>


      <Button onClick={handleClickOpenDeleteEmployee} variant="outlined" color="error">Remove Employee</Button>


      <Dialog disableEscapeKeyDown open={openDeleteEmployee} onClose={handleCloseDeleteEmployee}>
        <DialogTitle>Remove Employee</DialogTitle>
        <DialogContent>

            <FormControl onChange={event => setDeleteEmployeeID(event.target.value)}>
                <FormLabel id="demo-radio-buttons-group-label">Employees</FormLabel>
                <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                {getEmployees.map((employee) =>
                    <FormControlLabel key={employee.id} value={employee.id} control={<Radio />} label={employee.employeeName} />
                )}
                </RadioGroup>
            </FormControl>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteEmployee}>Discard</Button>
          <Button onClick={deleteEmployee}>Remove Employee</Button>
        </DialogActions>
      </Dialog>

        </CardContent>
    </Card>
</>
)
}

export default BusinessEmployees;