import React, {useState, useEffect, useContext} from "react";
import { Paper } from '@mui/material';
import './ProductSummary.css';
import { Divider } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import {FunnelContext} from "../Context";
import {doc, onSnapshot, collection, setDoc, addDoc, updateDoc, getDoc, arrayUnion, query, where, orderBy, deleteDoc} from "firebase/firestore";
import { db } from './FirebaseInit';





function ProductSummary(props) {
  const { selectedFunnel } = useContext(FunnelContext);

  const [funnelName, setFunnelName] = useState('');

  useEffect(() => {
    getFunnelName()

  }, [selectedFunnel])

  async function getFunnelName () {
   
    setFunnelName( (await getDoc(doc(db, "funnels", selectedFunnel))).data().funnelName) 
  }

  return (
    <>

<Paper className='productSummaryMainDiv' elevation={3}> 
      
      <span className='productSummaryTitle'>{funnelName.toString().toUpperCase()}</span>
    <div className="productSummaryMainTableContainer">
    <table className="productSummaryMainTable">
        <thead>
                <tr>
                    <th className="productSummaryTableHeadersContainer"><span className="productSummaryTableHeaders">Orders</span></th>
                    <th className="productSummaryTableHeadersContainer"><span className="productSummaryTableHeaders">Revenue</span></th>
                    <th className="productSummaryTableHeadersContainer"><span className="productSummaryTableHeaders">Advertising</span></th>
                    <th className="productSummaryTableHeadersContainer"><span className="productSummaryTableHeaders">Product Cost</span></th>
                    <th className="productSummaryTableHeadersContainer"><Tooltip title={props.feesLabel} placement="right"><span className="productSummaryTableHeaders">Fees/Credits</span></Tooltip></th>
                    <th className="productSummaryTableHeadersContainer"><span className="productSummaryTableHeaders">Cost Per Conv.</span></th>
                    <th className="productSummaryTableHeadersContainer"><span className="productSummaryTableHeaders">ROAS</span></th>
                    <th className="productSummaryTableHeadersContainer"><span className="productSummaryTableHeaders">Profit/Loss</span></th>
                    <th className="productSummaryTableHeadersContainer"><span className="productSummaryTableHeaders">Subscribers</span></th>
                    <th className="productSummaryTableHeadersContainer"><span className="productSummaryTableHeaders">Cost/Acquisition</span></th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td><span className="tableBody">{new Intl.NumberFormat().format(props.ordersTotal)}</span></td>
                    <td><span className="tableBody">{isNaN(props.revenueTotal) == true ? 0 : new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(props.revenueTotal)}</span></td>
                    <td><span className="tableBody">{isNaN(props.adsSpendTotal) == true ? 0 : new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(props.adsSpendTotal)}</span></td>
                    <td><span className="tableBody">{isNaN(props.productCostTotal) == true ? 0 : new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(props.productCostTotal)}</span></td>
                    <td><span className="tableBody">{isNaN(props.feesTotal) == true ? 0 : new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(props.feesTotal)}</span></td>
                    <td><span className="tableBody">{isNaN(props.CPCAverage) == true ? 0 : new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(props.CPCAverage)}</span></td>
                    <td><span className="tableBody">{isNaN(props.ROASTotal) == true ? 0 : new Intl.NumberFormat().format(props.ROASTotal)}</span></td>
                    <td><span className="tableBody">{isNaN(props.totalTotal) == true ? 0 : new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(props.totalTotal)}</span></td>
                    <td><span className="tableBody">{isNaN(props.subscribersTotal) == true ? 0 : new Intl.NumberFormat().format(props.subscribersTotal)}</span></td>
                    <td>{isNaN(props.CPATotal) == true ? 0 : <span className="tableBody">{props.CPATotal >= 0 ?
                      (<span className="tableDataText" style={{color:"green"}}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(props.CPATotal)}</span>):
                      (<span className="tableDataText" style={{color:"red"}}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(props.CPATotal)}</span>)
                      }</span>}</td>
                </tr>
            </tbody>
    </table>     
    </div>      


</Paper>

      
    </>
  );
}

export default ProductSummary;