import React, {useState, useEffect} from 'react';
import {collection, getDocs, orderBy, query, where} from "firebase/firestore";
import {db} from '../FirebaseInit';
import { Line, Bar } from 'react-chartjs-2';
import CircularProgress from '@mui/material/CircularProgress';
import 'chart.js/auto';
import './OrdersChartAnalytics.css';
import { Paper } from '@mui/material';


function OrdersChartAnalytics({ordersData}) {
  const [sortedOrdersObjState, setSortedOrdersObjState] = useState({})
  const [totalOrders, setTotalOrders] = useState([]);

  var dateArray = [];
  var dataArray = [];
  
  for (let i = 0; i < ordersData.data.length; i++) {
    dateArray.push(ordersData.data[i].date);
    dataArray.push(ordersData.data[i].data);

  }


  useEffect(() => {

  }, [])
  
 

  return (
  <>

  <div className='ordersChartAnalyticsDiv'>
    {ordersData.data.length != 0 ? <>
      <div className='ordersChartAnalyticsOrderTotalContainer'>
        <span className='ordersChartAnalyticsOrderTotal'>{new Intl.NumberFormat().format(ordersData.total) + ' Orders'}</span>
      </div>
      <div className='ordersChartAnalyticsChart'>
      <Bar
                    data={{
          labels: dateArray,
          datasets: [
            {
              data: dataArray,
              backgroundColor: 'rgb(0, 171, 85)',
              borderColor: 'rgb(0, 171, 85)',
            },
          ],
                    }}
                    options={{
          responsive: true,
          maintainAspectRatio: true,
          events: [], 
          
          plugins: {
            legend: {
                display: false,
            },
          },

          scales: {
            x: {
              display: true,
              grid: {
                display: false
              },
              ticks: {
                autoSkip: true,
                maxTicksLimit: 8,
                maxRotation: 0,
                minRotation: 0,
                font: {
                  family: 'Varela Round'
                }
              },
              
            },
            y: {
              beginAtZero: true,
              display: true,
              grid: {
                display: false
              },
              ticks: {
                autoSkip: true,
                maxTicksLimit: 5,
                maxRotation: 0,
                minRotation: 0,
                font: {
                  family: 'Varela Round'
                }
              }
            },
          },

          elements: {
            point:{
                radius: 0
            }
        }

                    }}
                  />
      </div>
      </>:<>
      <div className='ordersChartAnalyticsLoadingContainer'>
        <CircularProgress color='success'/><br/><br/>
        <span className='ordersChartAnalyticsLoadingText'>Loading Orders Data</span>
      </div>
    </>}
  </div>


  

  </>
  )
}
export default OrdersChartAnalytics;
