
import React, {useState, useEffect, useContext} from "react";
import Paper from '@mui/material/Paper';
import Navbar from './Navbar';
import { db } from './FirebaseInit';
import {doc, collection, getDocs, getDoc} from "firebase/firestore";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import './Dashboard.css';
import LinearProgress from '@mui/material/LinearProgress';
import { Box } from "@mui/material";
import './AccountBalances.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {UserTypeContext} from "../Context";
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
const axios = require('axios').default;


  
function AccountBalances() {
  const [tiktokAccounts, setTikTokAccounts] = useState([]);
  const [facebookAccounts, setFacebookAccounts] = useState([]);

  const [onlyActiveAccounts, setOnlyActiveAccounts] = useState(true);

  const [dayOfWeek, setDayOfWeek] = useState(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'])
  const [months, setMonths] = useState(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'])

  var facebookCredit = 0;
  var facebookYesterdaySpend = 0
  var facebookL7DSpend = 0
  const [facebookCreditState, setFacebookCreditState] = useState(0);
  const [facebookYesterdaySpendState, setFacebookYesterdaySpendState] = useState(0);
  const [facebookL7DSpendState, setFacebookL7DSpendState] = useState(0);

  var tiktokCredit = 0;
  var tiktokYesterdaySpend = 0
  var tiktokL7DSpend = 0
  const [tiktokCreditState, setTiktokCreditState] = useState(0);
  const [tiktokYesterdaySpendState, setTiktokYesterdaySpendState] = useState(0);
  const [tiktokL7DSpendState, setTiktokL7DSpendState] = useState(0);

  const [tiktokNoActiveAccounts, setTiktokNoActiveAccounts] = useState(true)

  var totalCredit = 0;
  const [credit, setCredit] = useState(0);

  //FACEBOOK
  async function getFacebookAccounts () {
    const config = {
      method: 'get',
      url: 'https://us-central1-sendwell-profit-tracker.cloudfunctions.net/getFacebookAccountBalances',
      
    };
  
    var response = await axios(config);
    response = response.data;
    console.log(response)
    for (let i = 0; i < response.length; i++) {
      totalCredit = totalCredit + response[i].adAccountRemainingCredit;
      facebookCredit = facebookCredit + response[i].adAccountRemainingCredit;
      facebookYesterdaySpend = facebookYesterdaySpend + response[i].yesterdaySpend;
      facebookL7DSpend = facebookL7DSpend + response[i].last7DaysSpend;
    }
    setFacebookCreditState(facebookCredit);
    setCredit(totalCredit);
    setFacebookYesterdaySpendState(facebookYesterdaySpend);
    setFacebookL7DSpendState(facebookL7DSpend);
    setFacebookAccounts(response);
  }

  //TIKTOK
  async function getTikTokAccounts () {
    const config = {
      method: 'get',
      url: 'https://us-central1-sendwell-profit-tracker.cloudfunctions.net/getTikTokAccountBalances',
      
    };
  
    var response = await axios(config);
    response = response.data;
    console.log(response)
    for (let i = 0; i < response.length; i++) {
      totalCredit = totalCredit + response[i].balance;
      tiktokCredit = tiktokCredit + response[i].balance;
      tiktokYesterdaySpend = tiktokYesterdaySpend + response[i].yesterdaySpend;
      tiktokL7DSpend = tiktokL7DSpend + response[i].last7DaysSpend;
      if (response[i].yesterdaySpend > 0) {
        setTiktokNoActiveAccounts(false)
      }
    }
    setTiktokCreditState(tiktokCredit);
    setCredit(totalCredit);
    setTiktokYesterdaySpendState(tiktokYesterdaySpend);
    setTiktokL7DSpendState(tiktokL7DSpend);
    setTikTokAccounts(response);
  }

  useEffect(() => {
    getTikTokAccounts();
    getFacebookAccounts();
  }, [])

  console.log(onlyActiveAccounts)
  console.log(tiktokNoActiveAccounts)

  function signOutUser() {
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }

  return (

  <div className="accountBalancesMainContainer">

    <div className="dashboardDrawer">
    <div className="dashboardDrawerTitleContainer">
      <span className="dashboardDrawerTitle">Sendwell Stats</span>
    </div>

    <div>
      <div className="dashboardDrawerSectionTitle">
        <span>General</span>
      </div>
      <div className="dashboardDrawerSection">
      <a href="/Dashboard" className="dashboardDrawerLinkTitle">Dashboard</a>
      </div>
      <div className="dashboardDrawerSection">
        <a href="/ProductIdeas" className="dashboardDrawerLinkTitle">Product Ideas</a>
      </div>

      <div className="dashboardDrawerSectionTitle">
        <span>Finances</span>
      </div>
      <div className="dashboardDrawerSection">
        <a href="/Accounting" className="dashboardDrawerLinkTitle">Accounting</a>
      </div>
      <div className="dashboardDrawerSection activeDashboardDrawerSection">
        <a href="/AccountBalances" className="activeDashboardDrawerLinkTitle">Account Balances</a>
      </div>
      <div className="dashboardDrawerSection">
        <a href="/Analytics" className="dashboardDrawerLinkTitle">Analytics</a>
      </div>
      <div className="dashboardDrawerSection">
        <a href="/Commission" className="dashboardDrawerLinkTitle">Commission</a>
      </div>

      <div className="dashboardDrawerSectionTitle">
        <span>Management</span>
      </div>
      <div className="dashboardDrawerSection">
        <a href="/UserSettings" className="dashboardDrawerLinkTitle">User Settings</a>
      </div>
      <div className="dashboardDrawerSection">
        <a href="/UserPermissions" className="dashboardDrawerLinkTitle">User Permissions</a>
      </div>
      <div className="dashboardDrawerSection">
        <a href="/BusinessSettings" className="dashboardDrawerLinkTitle">Business Settings</a>
      </div>
    </div>
    <div className="dashboardDrawerSectionTitle">
      <span></span>
    </div>
    <div className="dashboardDrawerSectionSignOut" onClick={() => signOutUser()}>
      <span className="dashboardDrawerLinkTitleSignOut">Sign Out</span>
    </div>

  </div>
    
  <div className="accountBalancesBackground">
      
    <div className="accountBalancesFilter">
      <FormControlLabel control={<Checkbox checked={onlyActiveAccounts} onChange={(event) => {setOnlyActiveAccounts(event.target.checked)}} />} label="Only Show Active Accounts" />
    </div>


  {facebookAccounts.length != 0 ?
  <>
  <div className="facebookAccountsContainer"> 
    <div className="accountBalancesCardTitle"><img src="https://logos-world.net/wp-content/uploads/2020/04/Facebook-Logo.png" width={'150px'}/></div>
    <table className="accountBalancesTableWidth">
      <thead>
        <tr>
          <td></td>
          <td className="accountBalancesRowHeight"><b>Account Name</b></td>
          <td><b>Balance</b></td>
          <td><b>Yesterday Spend</b></td>
          <td><b>Last 7 Day Spend</b></td>
          <td><b>Funnel Name</b></td>
          <td><b>Out Of Funds</b></td>
        </tr>
      </thead>
      <tbody>
        {facebookAccounts.map((a) => { 
          return (<>
            {onlyActiveAccounts == true && a.yesterdaySpend != 0 ?
            <>
              <tr key={a.adAccountID}>
                {a.mediaBuyerAvatarColor != '' ?
                  <td><Tooltip title={a.mediaBuyerName}><Avatar sx={{ bgcolor: '#' + a.mediaBuyerAvatarColor, width: 40, height: 40  }}>{a.mediaBuyerName[0]}</Avatar></Tooltip></td>
                : <td></td>
                }
                <td className="accountBalancesRowHeight">{(a.adAccountName).substr(17, 11)}</td>
                <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.adAccountRemainingCredit)}</td>
                <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.yesterdaySpend)}</td>
                <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.last7DaysSpend)}</td>
                <td>{a.funnelName}</td>
                {a.runOutOfFundsDays == 0 || a.runOutOfFundsDays === null || a.adAccountRemainingCredit == 0 ? 
                    <td></td> 
                  : <>
                    {new Date(a.runOutOfFunds).getHours() < 12 ?
                      <td><Tooltip title={new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(a.runOutOfFundsDays) + ' Days Remaining'}><span>{dayOfWeek[new Date(a.runOutOfFunds).getDay()] + ' ' + months[new Date(a.runOutOfFunds).getMonth()] + ' ' + new Date(a.runOutOfFunds).getDate() + ' ' + (new Date(a.runOutOfFunds).getHours() + 1) + ':' + (new Date(a.runOutOfFunds).getMinutes()).toString().padStart(2, '0') + ' AM'}</span></Tooltip></td>
                    :
                      <td><Tooltip title={new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(a.runOutOfFundsDays) + ' Days Remaining'}><span>{dayOfWeek[new Date(a.runOutOfFunds).getDay()] + ' ' + months[new Date(a.runOutOfFunds).getMonth()] + ' ' + new Date(a.runOutOfFunds).getDate() + ' ' + (new Date(a.runOutOfFunds).getHours() - 11) + ':' + (new Date(a.runOutOfFunds).getMinutes()).toString().padStart(2, '0') + ' PM'}</span></Tooltip></td>
                    }
                  </>}
              </tr>
            </>:<>
              {onlyActiveAccounts == false ?
              <>
                <tr key={a.adAccountID}>
                  {a.mediaBuyerAvatarColor != '' ?
                    <td><Avatar sx={{ bgcolor: '#' + a.mediaBuyerAvatarColor, width: 40, height: 40  }}>{a.mediaBuyerName[0]}</Avatar></td>
                  : <td></td>
                  }
                  <td className="accountBalancesRowHeight">{(a.adAccountName).substr(17, 11)}</td>
                  <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.adAccountRemainingCredit)}</td>
                  <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.yesterdaySpend)}</td>
                  <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.last7DaysSpend)}</td>
                  <td>{a.funnelName}</td>
                  {a.runOutOfFundsDays == 0 || a.runOutOfFundsDays === null || a.adAccountRemainingCredit == 0 ? 
                    <td></td> 
                  : <>
                    {new Date(a.runOutOfFunds).getHours() < 12 ?
                      <td><Tooltip title={new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(a.runOutOfFundsDays) + ' Days Remaining'}><span>{dayOfWeek[new Date(a.runOutOfFunds).getDay()] + ' ' + months[new Date(a.runOutOfFunds).getMonth()] + ' ' + new Date(a.runOutOfFunds).getDate() + ' ' + (new Date(a.runOutOfFunds).getHours() + 1) + ':' + (new Date(a.runOutOfFunds).getMinutes()).toString().padStart(2, '0') + ' AM'}</span></Tooltip></td>
                    :
                      <td><Tooltip title={new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(a.runOutOfFundsDays) + ' Days Remaining'}><span>{dayOfWeek[new Date(a.runOutOfFunds).getDay()] + ' ' + months[new Date(a.runOutOfFunds).getMonth()] + ' ' + new Date(a.runOutOfFunds).getDate() + ' ' + (new Date(a.runOutOfFunds).getHours() - 11) + ':' + (new Date(a.runOutOfFunds).getMinutes()).toString().padStart(2, '0') + ' PM'}</span></Tooltip></td>
                    }                  
                  </>
                  }
                </tr>
              </>:<></>}
            </>}
          </>)}
        )}
        <tr>
          <td></td>
          <td></td>
          <td className="accountBalancesRowHeight"><b>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(facebookCreditState)}</b></td>
          <td><b>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(facebookYesterdaySpendState)}</b></td>
          <td><b>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(facebookL7DSpendState)}</b></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>

  </>:<>

  <div className="facebookAccountsContainer"> 
    <div className="accountBalancesLoadingContainer">
      <CircularProgress color="success"/> <br/><br/>
      <span className="facebookAccountsLoadingText">Loading Facebook Ad Accounts</span>
    </div>
  </div>
  </>}





  {tiktokAccounts.length != 0 ?
  <>
  <div className="tiktokAccountsContainer"> 
    <div className="accountBalancesCardTitle"><img src="https://upload.wikimedia.org/wikipedia/en/thumb/a/a9/TikTok_logo.svg/1000px-TikTok_logo.svg.png?20200415104610" width={'150px'}/></div>
    <br/>
    <table className="accountBalancesTableWidth">
      <thead>
        <tr>
          <td></td>
          <td className="accountBalancesRowHeight"><b>Account Name</b></td>
          <td><b>Balance</b></td>
          <td><b>Yesterday Spend</b></td>
          <td><b>Last 7 Day Spend</b></td>
          <td><b>Funnel Name</b></td>
          <td><b>Out Of Funds</b></td>
        </tr>
      </thead>
      <tbody>
        {tiktokAccounts.map((a) => { 
          return (<>
          {console.log(a.yesterdaySpend)}
            {onlyActiveAccounts == true && a.yesterdaySpend !== 0 ?
            <>
              <tr key={a.adAccountID}>
                {a.mediaBuyerAvatarColor != '' ?
                  <td><Tooltip title={a.mediaBuyerName}><Avatar sx={{ bgcolor: '#' + a.mediaBuyerAvatarColor, width: 40, height: 40  }}>{a.mediaBuyerName[0]}</Avatar></Tooltip></td>
                : <td></td>
                }
                <td className="accountBalancesRowHeight">{(a.name).substr(0, 14)}</td>
                <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.balance)}</td>
                <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.yesterdaySpend)}</td>
                <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.last7DaysSpend)}</td>
                <td>{a.funnelName}</td>
                {new Date(a.runOutOfFunds).getHours() < 12 ?
                  <td><Tooltip title={new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(a.runOutOfFundsDays) + ' Days Remaining'}><span>{dayOfWeek[new Date(a.runOutOfFunds).getDay()] + ' ' + months[new Date(a.runOutOfFunds).getMonth()] + ' ' + new Date(a.runOutOfFunds).getDate() + ' ' + (new Date(a.runOutOfFunds).getHours() + 1) + ':' + (new Date(a.runOutOfFunds).getMinutes()).toString().padStart(2, '0') + ' AM'}</span></Tooltip></td>
                :
                  <td><Tooltip title={new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(a.runOutOfFundsDays) + ' Days Remaining'}><span>{dayOfWeek[new Date(a.runOutOfFunds).getDay()] + ' ' + months[new Date(a.runOutOfFunds).getMonth()] + ' ' + new Date(a.runOutOfFunds).getDate() + ' ' + (new Date(a.runOutOfFunds).getHours() - 11) + ':' + (new Date(a.runOutOfFunds).getMinutes()).toString().padStart(2, '0') + ' PM'}</span></Tooltip></td>
                }
              </tr>
            </>:<>
              {onlyActiveAccounts == false ?
              <>
                <tr key={a.adAccountID}>
                  {a.mediaBuyerAvatarColor != '' ?
                    <td><Avatar sx={{ bgcolor: '#' + a.mediaBuyerAvatarColor, width: 40, height: 40  }}>{a.mediaBuyerName[0]}</Avatar></td>
                  : <td></td>
                  }
                  <td className="accountBalancesRowHeight">{(a.name)}</td>
                  <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.balance)}</td>
                  <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.yesterdaySpend)}</td>
                  <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(a.last7DaysSpend)}</td>
                  <td>{a.funnelName}</td>
                  {a.runOutOfFundsDays == 0 || a.runOutOfFundsDays === null? 
                    <td></td> 
                  : <>
                    {new Date(a.runOutOfFunds).getHours() < 12 ?
                      <td><Tooltip title={new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(a.runOutOfFundsDays) + ' Days Remaining'}><span>{dayOfWeek[new Date(a.runOutOfFunds).getDay()] + ' ' + months[new Date(a.runOutOfFunds).getMonth()] + ' ' + new Date(a.runOutOfFunds).getDate() + ' ' + (new Date(a.runOutOfFunds).getHours() + 1) + ':' + (new Date(a.runOutOfFunds).getMinutes()).toString().padStart(2, '0') + ' AM'}</span></Tooltip></td>
                    :
                      <td><Tooltip title={new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(a.runOutOfFundsDays) + ' Days Remaining'}><span>{dayOfWeek[new Date(a.runOutOfFunds).getDay()] + ' ' + months[new Date(a.runOutOfFunds).getMonth()] + ' ' + new Date(a.runOutOfFunds).getDate() + ' ' + (new Date(a.runOutOfFunds).getHours() - 11) + ':' + (new Date(a.runOutOfFunds).getMinutes()).toString().padStart(2, '0') + ' PM'}</span></Tooltip></td>
                    }                  
                  </>
                  }
                </tr>
              </>:<></>}
            </>}
          </>)}
        )}
        <tr>
          <td></td>
          <td></td>
          <td className="accountBalancesRowHeight"><b>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(tiktokCreditState)}</b></td>
          <td><b>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(tiktokYesterdaySpendState)}</b></td>
          <td><b>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(tiktokL7DSpendState)}</b></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
    
  </div>

  </>:<>

  <div className="facebookAccountsContainer"> 
    <div className="accountBalancesLoadingContainer">
      <CircularProgress color="success"/> <br/><br/>
      <span className="facebookAccountsLoadingText">Loading TikTok Ad Accounts</span>
    </div>
  </div>
  </>}



  
  </div>


</div>
  );
}



export default AccountBalances;
