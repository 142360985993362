// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { GoogleAuthProvider } from "firebase/auth";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCW3dxWRRDiFRsZ6VWzfiDiqS7DM8x02nU",
  authDomain: "sendwell-profit-tracker.firebaseapp.com",
  databaseURL: "https://sendwell-profit-tracker-default-rtdb.firebaseio.com",
  projectId: "sendwell-profit-tracker",
  storageBucket: "sendwell-profit-tracker.appspot.com",
  messagingSenderId: "1061608641339",
  appId: "1:1061608641339:web:a2805de13cdc3a56659d1e",
  measurementId: "G-J6W3T8HSMZ"
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore();

const provider = new GoogleAuthProvider();



export {db};